import React from 'react';
import { connect } from 'react-redux';

import { actions } from '../../../../actions';
import { api } from '../../../../services';
import StatsTree from './StatsTree';

const StatsLabelTree = (props) => {
    const { labelTree, labelTreeFetch, latestDate } = props;

    return <StatsTree labelTree={labelTree} labelTreeFetch={labelTreeFetch} latestDate={latestDate} />;
};

const mapStateToProps = (state) => {
    return {
        labelTree: state.data[api.endpoints.labelTree]?.data?.data || null,
        latestDate: state.data[api.endpoints.labelTree]?.data?.latestDate || null,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        labelTreeFetch: (type) => {
            return dispatch(
                actions.api.data.fetch.request({
                    endpoint: api.endpoints.labelTree,
                }),
            );
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(StatsLabelTree);
