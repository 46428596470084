export const validFilters = ['segments', 'annotations', 'frames'];
export const validTypes = ['segments', 'labels'];

export const sortOrders = ['value', 'alphabetic'];

export const validateFilter = (order) => (validFilters.includes(order) ? order : 'annotations');

export const validateSortOrder = (order) => (sortOrders.includes(order) ? order : 'count');
const validateType = (order) => (validTypes.includes(order) ? order : 'segments');

export const MISSING = 'N/A (missing)';
export const validateAttribute = (attribute, elements) => {
    if (!elements || elements.length === 0) {
        return validateType(attribute);
    }

    if (elements.includes(attribute)) {
        return attribute;
    }

    return getDefaultAttr(elements);
};

export const validateBooleanAttribute = (attribute, elements) => {
    if (!elements || elements.length === 0) {
        return validateType(attribute);
    }

    if (attribute === 'true' || attribute === 'false') {
        attribute = attribute === 'true';
    }

    if (elements.includes(attribute)) {
        return attribute;
    }

    return getDefaultAttr(elements);
};
export const getDefaultAttr = (elements) => {
    if (elements.includes('dataset')) return 'dataset';
    if (elements.includes('quality')) return 'quality';
    if (elements.includes(true)) return true;
    if (!elements || elements.length === 0) return '';
    return elements[0];
};

function getNextType(t) {
    return t === validTypes[0] ? validTypes[1] : validTypes[0];
}

export function getNextValue(t, values) {
    if (!values || values.length < 2) {
        return getNextType(t);
    }

    return values[0] === t ? values[1] : values[0];
}

function isCheckedType(t) {
    return t === validTypes[0];
}

export function isCheckedValuesType(t, values) {
    if (!values) {
        return isCheckedType(t);
    }
    return values.length >= 2 && t === values[0];
}

export function capitalizeFirstLetter(string) {
    if (string === null || string === undefined) {
        return '';
    }
    return string.charAt(0).toUpperCase() + string.slice(1);
}
