import SearchIcon from '@mui/icons-material/Search';
import { CircularProgress, InputAdornment, TextField, Toolbar, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import clsx from 'clsx';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { toolbarStyles } from './style';

const SearchToolbar = ({ setSearch, isLoading, title, subtitle, margin }) => {
    const theme = useTheme();
    const classes = toolbarStyles(theme);

    const [searchParams, setSearchParams] = useSearchParams();
    const [searchText, setSearchText] = React.useState(searchParams.get('search') || '');

    useEffect(() => {
        if (!setSearch) {
            return;
        }
        setSearch(searchText);
    }, [searchText, setSearch]);

    useEffect(() => {
        if (!setSearch) {
            return;
        }
        const newParams = new URLSearchParams(searchParams);
        newParams.set('search', searchText);
        setSearchParams(newParams, { replace: true });
    }, [searchText, searchParams, setSearchParams, setSearch]);

    const handleSearchChange = (event) => {
        setSearchText(event.target.value);
    };

    const styles = {
        ...classes,
        root: {
            ...classes.root,
            display: 'flex',
            flexDirection: 'column',
            paddingBottom: theme.spacing(4),
        },
        noMargin: {
            paddingBottom: 0,
        },
        title: {
            ...classes.title,
            fontWeight: 'bold',
            paddingTop: theme.spacing(3),
            paddingBottom: theme.spacing(2),
        },
        searchInput: {
            marginLeft: 'auto',
            fontSize: '16px',
        },
        searchInputTopLeft: {
            position: 'absolute',
            top: 0,
            left: 0,
            marginLeft: theme.spacing(2),
            marginTop: theme.spacing(2),
        },
        progress: {
            position: 'absolute',
            top: 0,
            right: 0,
            marginRight: theme.spacing(2),
            marginTop: theme.spacing(2),
        },
        subTitle: {
            textAlign: 'center',
            color: theme.palette.text.secondary,
            fontStyle: 'italic',
            fontSize: '1rem',
        },
    };

    return (
        <Toolbar
            sx={{
                ...styles.root,
                ...(margin === 'no-m' ? styles.noMargin : {}),
            }}
        >
            {isLoading && <CircularProgress sx={styles.progress} size={20} />}
            <Typography sx={styles.title} variant='h5'>
                {title}
            </Typography>
            {subtitle && <Typography sx={styles.subTitle}>{subtitle}</Typography>}
            {setSearch && (
                <TextField
                    className={clsx(styles.searchInput)}
                    onChange={handleSearchChange}
                    placeholder='Search'
                    variant='outlined'
                    size='small'
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position='end'>
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                    value={searchText}
                />
            )}
        </Toolbar>
    );
};

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {};
};
SearchToolbar.propTypes = {};

export default connect(mapStateToProps, mapDispatchToProps)(SearchToolbar);
