import ImageIcon from '@mui/icons-material/Image';
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';
import TimelineIcon from '@mui/icons-material/Timeline';
import VideocamIcon from '@mui/icons-material/Videocam';
import React from 'react';

export const AVAILABLE_FILTER_MAP = {
    video: <VideocamIcon />,
    track: <StackedLineChartIcon />,
    frame: <ImageIcon />,
    segment: <TimelineIcon />,
};

export const SEGMENT_FILTERING = ['video', 'segment'];
export const LABEL_FILTERING = ['video', 'track', 'frame'];

export const AVAILABLE_CHART_TYPES = ['area', 'line'];

export const AVAILABLE_FILTERS = Object.keys(AVAILABLE_FILTER_MAP);

export const URL_PARAM_NAMES = {
    selectedLabel: 'labelAttribute',
    selectedName: 'nameAttribute',
    filter: 'filterAttribute',
    selectedTimeRange: 'timeAttribute',
    chartType: 'chartType',
    isStacked: 'isStacked',
    selectedKeys: 'selectedKeys',
    autoSelect: 'autoSelect',
    otherRow: 'otherRow',
    YAuto: 'YAuto',
};

export const AUTO_SELECT_LIMIT = 5;
export const OTHER_ROW = 'other_selected_key_73145732191';
export const OTHER_ROW_LABEL = 'Other';

export const DISPLAY_TIMEOUT_MS = 50;

export const getFormattedName = (name) => {
    const changesMap = {
        video: 'Videos',
        track: 'Tracks',
        frame: 'Frames',
        segment: 'Segments',
    };
    return changesMap[name] || name;
};

export const validateFilter = (value) => (AVAILABLE_FILTERS.includes(value) ? value : AVAILABLE_FILTERS[0]);
export const validateChartType = (value) => (AVAILABLE_CHART_TYPES.includes(value) ? value : AVAILABLE_CHART_TYPES[0]);

export const isArea = (chartType) => chartType === AVAILABLE_CHART_TYPES[0];
