import { createReducer } from '@reduxjs/toolkit';

import { actions } from '../actions';

const initialEndpointState = {
    details: {
        set: false,
        data: '',
    },
};

export const project = createReducer(initialEndpointState, {
    [actions.project.details.set]: (state, action) => ({
        ...state,
        details: {
            set: true,
            data: action.payload,
        },
    }),

    [actions.project.details.reset]: (state, action) => ({
        ...initialEndpointState,
    }),
});
