import { createAction } from '@reduxjs/toolkit';

export const dataset = {
    search: createAction('dataset/search'),
    update: createAction('dataset/update'),
    details: {
        set: createAction('dataset/details/set'),
        reset: createAction('dataset/details/reset'),
    },
};
