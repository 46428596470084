import { useTheme } from '@emotion/react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import FilterListIcon from '@mui/icons-material/FilterList';
import {
    Box,
    Card,
    CardContent,
    Checkbox,
    FormControlLabel,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    Tooltip,
    Typography,
} from '@mui/material';
import React, { useState } from 'react';

import { RATING_TYPES_OPTIONS } from '../panels/ProjectCreation/constant';
import { CustomLegend } from './CustomLegend';
import { ProgressRow } from './ProgressRow';
import ProjectGraph from './ProjectGraph';

function ProjectDetails(props) {
    const { graphs, tables, config } = props;
    const theme = useTheme();
    const styles = {
        graphSubTitle: { textAlign: 'center' },
        filter: {
            marginLeft: '10px',
            marginBottom: '4px',
        },
        card: {
            marginBottom: theme.spacing(3),
            backgroundColor: theme.palette.common.verylightgray,
        },
    };
    const [anchorEl, setAnchorEl] = useState({});
    const [showDetails, setShowDetails] = useState(graphs.map(() => true));

    const [filteredSubjects, setFilteredSubjects] = useState(
        graphs.map((graph) => graph.data.filter((item) => !item.hide).map((item) => item.subject)),
    );

    const handleFilterClick = (event, graphIndex) => {
        setAnchorEl((prev) => ({ ...prev, [graphIndex]: event.currentTarget }));
    };

    const handleClose = (graphIndex) => {
        setAnchorEl((prev) => ({ ...prev, [graphIndex]: null }));
    };

    const handleToggle = (graphIndex, subject) => {
        setFilteredSubjects((prevState) => {
            const newState = [...prevState];
            if (newState[graphIndex].includes(subject)) {
                newState[graphIndex] = newState[graphIndex].filter((subj) => subj !== subject);
            } else {
                newState[graphIndex] = [...newState[graphIndex], subject];
            }
            return newState;
        });
    };

    const toggleShowDetails = (index) => {
        setShowDetails((prevState) => {
            const newState = [...prevState];
            newState[index] = !newState[index];
            return newState;
        });
    };
    return (
        <Grid container direction='row' alignItems='center' justifyContent='center' spacing={2}>
            {graphs?.map(function (graph, index) {
                const table_data = tables[index];
                const config_data = config[index];

                return (
                    <Grid item key={index} xs={12} md={12} lg={12}>
                        <Card sx={styles.card}>
                            <CardContent>
                                <Box display='flex' justifyContent='center' alignItems='center'>
                                    <Typography variant='h6' style={styles.graphSubTitle}>
                                        {graph.title}
                                    </Typography>
                                    <IconButton onClick={(event) => handleFilterClick(event, index)} sx={styles.filter}>
                                        <FilterListIcon />
                                    </IconButton>
                                    <Menu
                                        anchorEl={anchorEl[index]}
                                        open={Boolean(anchorEl[index])}
                                        onClose={() => handleClose(index)}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        sx={{ maxHeight: 300 }}
                                    >
                                        {graph.data.map((item, i) => (
                                            <MenuItem key={i}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={filteredSubjects[index].includes(item.subject)}
                                                            onChange={() => handleToggle(index, item.subject)}
                                                        />
                                                    }
                                                    label={item.subject}
                                                />
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                </Box>
                                <CustomLegend padding={'0 0 12px 0'} />

                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        width: '100%',
                                        overflow: 'visible',
                                        padding: 0,
                                    }}
                                >
                                    <Grid container direction='row' alignItems='center' justifyContent='center'>
                                        <Box sx={{ textAlign: 'right', marginRight: '20px' }}>
                                            <Tooltip
                                                title={showDetails[index] ? 'Less Details' : 'More Details'}
                                                placement={'top'}
                                                arrow
                                            >
                                                <IconButton
                                                    onClick={() => toggleShowDetails(index)}
                                                    sx={{ marginTop: '32px' }}
                                                >
                                                    {showDetails[index] ? (
                                                        <ArrowBackIosIcon sx={{ paddingLeft: '8px' }} />
                                                    ) : (
                                                        <ArrowForwardIosIcon
                                                            sx={{ paddingRight: '4px', paddingLeft: '4px' }}
                                                        />
                                                    )}
                                                </IconButton>
                                            </Tooltip>
                                        </Box>
                                        <Grid item xs={10} md={10} lg={5}>
                                            <Box
                                                sx={{
                                                    width: '100%',
                                                    minWidth: '300px',
                                                }}
                                            >
                                                {table_data?.data
                                                    .filter((item) => filteredSubjects[index].includes(item.subject))
                                                    .map(function (item, i) {
                                                        const configElt = config_data?.data.filter((item) =>
                                                            filteredSubjects[index].includes(item.subject),
                                                        )[i];
                                                        const graphElt = graph?.data.filter((item) =>
                                                            filteredSubjects[index].includes(item.subject),
                                                        )[i];

                                                        const type = configElt?.rating?.type;
                                                        const steps = RATING_TYPES_OPTIONS.find(
                                                            (rating) => rating.value === type,
                                                        )?.steps;

                                                        let devSteps = steps;
                                                        let testSteps = steps;
                                                        const additional = configElt?.rating?.additional || false;

                                                        if (!steps) {
                                                            if (additional) {
                                                                devSteps = configElt?.rating?.devValues?.map((tick) =>
                                                                    parseInt(tick),
                                                                );
                                                                testSteps = configElt?.rating?.testValues?.map((tick) =>
                                                                    parseInt(tick),
                                                                );
                                                            } else {
                                                                const intTicks = configElt?.rating?.values?.map(
                                                                    (tick) => parseInt(tick),
                                                                );
                                                                devSteps = intTicks;
                                                                testSteps = intTicks;
                                                            }
                                                        }

                                                        return (
                                                            <ProgressRow
                                                                key={i}
                                                                testingValue={item.testing}
                                                                developmentValue={item.development}
                                                                progressTestingValue={graphElt.testing_progress}
                                                                progressDevelopmentValue={graphElt.development_progress}
                                                                devGraphValue={Math.floor(graphElt.development)}
                                                                testGraphValue={Math.floor(graphElt.testing)}
                                                                developmentSteps={devSteps}
                                                                testingSteps={testSteps}
                                                                subject={item.subject}
                                                                type={configElt?.rating?.type}
                                                                isHeader={i === 0}
                                                                showDetails={showDetails[index]}
                                                            />
                                                        );
                                                    })}
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={12} lg={6}>
                                            <ProjectGraph
                                                graph={graph.data.filter((item) =>
                                                    filteredSubjects[index].includes(item.subject),
                                                )}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                );
            })}
        </Grid>
    );
}

export default ProjectDetails;
