import { combineReducers } from 'redux';

import { annotation } from './annotation';
import { auth } from './auth';
import { data } from './data';
import { dataset } from './dataset';
import { details } from './details';
import { data_health, health } from './health';
import { notification } from './notification';
import { preference } from './preference';
import { processedData } from './processed/processedData';
import { project } from './project';
import { qbuilder } from './qbuilder';
import { stats } from './stats';

const createRootReducer = () =>
    combineReducers({
        auth,
        data,
        health,
        data_health,
        notification,
        preference,
        qbuilder,
        annotation,
        stats,
        dataset,
        details,
        processedData,
        project,
    });

export default createRootReducer;
