import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { styled } from '@mui/material/styles';

export const LittleColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 15,
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor: 'transparent',
        borderRadius: 1,
        position: 'relative',
    },
    [`& .${stepConnectorClasses.line}:before`]: {
        content: '""',
        position: 'absolute',
        left: 0,
        right: 0,
        top: '-100%',
        transform: 'translateY(-50%)',
        height: 3,
        borderRadius: 1,
        backgroundColor: theme.palette.common.lightgray,
        transition: 'background-color 1s',
    },
    [`&.${stepConnectorClasses.active} .${stepConnectorClasses.line}:before`]: {
        backgroundColor: theme.palette.primary.main,
    },
    [`&.${stepConnectorClasses.completed} .${stepConnectorClasses.line}:before`]: {
        backgroundColor: theme.palette.primary.main,
    },
    [`&.${stepConnectorClasses.skipped} .${stepConnectorClasses.line}:before`]: {
        backgroundColor: theme.palette.common.mediumlightgray,
    },
}));
