import { blue } from '@mui/material/colors';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { darcula, oneLight } from 'react-syntax-highlighter/dist/cjs/styles/prism';

export const themeDark = responsiveFontSizes(
    createTheme({
        components: {
            MuiTooltip: {
                styleOverrides: {
                    tooltip: {
                        maxWidth: 'none',
                        width: 'auto',
                    },
                },
            },
        },
        palette: {
            mode: 'dark',
            primary: {
                main: '#0a84ff',
            },
            secondary: {
                main: blue[500],
            },
            background: {
                main: '#121212',
            },
            common: {
                sectionGray: '#2c2c2c',
                darkgray: '#aaa',
                lightgray: '#555',
                simplegray: '#333',
                mediumlightgray: '#141414',
                verylightgray: '#1c1c1c',
                paper: '#1e1e1e',
                white: '#fff',
                black: '#fff',
            },
            text: {
                success: '#33a5ff',
                warning: '#ff8731',
            },
            light: {
                main: '#fff',
            },
            white: {
                main: '#fff',
            },
            black: {
                main: '#000',
            },
            missing: {
                main: '#64FFDA',
            },
            events: {
                error: '#ff2922',
                pending: '#9e9e9e',
                processing: '#21bbf3',
                succeeded: '#1976d2',
                autoFixed: '#31a457',
                warning: '#ff6a00',
            },
            project: {
                colors: ['#0088FE', '#FFBB28'],
            },
        },
        graph: {
            colors: [
                '#29b6f6', // Bright sky blue
                '#26c6da', // Bright teal
                '#66bb6a', // Light green
                '#ffa726', // Light orange
                '#ec407a', // Bright pink
                '#ab47bc', // Light purple
                '#5c6bc0', // Light indigo
                '#ef5350', // Soft red
                '#ffca28', // Amber
                '#9ccc65', // Lime green
            ],
            otherColor: '#9e9e9e',
        },
        material: darcula,
    }),
);

export const themeLight = responsiveFontSizes(
    createTheme({
        components: {
            MuiTooltip: {
                styleOverrides: {
                    tooltip: {
                        maxWidth: 'none',
                        width: 'auto',
                    },
                },
            },
        },
        palette: {
            mode: 'light',
            primary: {
                main: '#1976d2',
            },
            secondary: {
                main: blue[500],
            },
            common: {
                sectionGray: '#eee',
                darkgray: '#555',
                lightgray: '#ccc',
                simplegray: '#bbb',
                mediumlightgray: '#f0f0f0',
                verylightgray: '#f5f5f5',
                paper: '#fff',
                white: '#fff',
                black: '#000',
            },
            text: {
                success: '#00589e',
                warning: '#FF6A00',
            },
            light: {
                main: '#ccc',
            },
            white: {
                main: '#fff',
            },
            black: {
                main: '#000',
            },
            background: {
                main: '#fff',
            },
            missing: {
                main: '#49c0a3',
            },
            events: {
                error: '#F44336',
                pending: '#616161',
                processing: '#03e0f4',
                succeeded: '#0a84ff',
                autoFixed: '#31a457',
                warning: '#ff6a00',
            },
            project: {
                colors: ['#006ECD', '#DE6D00'],
            },
        },
        graph: {
            colors: [
                '#29b6f6', // Bright sky blue
                '#26c6da', // Bright teal
                '#66bb6a', // Light green
                '#ffa726', // Light orange
                '#ec407a', // Bright pink
                '#ab47bc', // Light purple
                '#5c6bc0', // Light indigo
                '#ef5350', // Soft red
                '#ffca28', // Amber
                '#9ccc65', // Lime green
            ],
            otherColor: '#9e9e9e',
        },
        material: oneLight,
    }),
);
