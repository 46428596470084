import { createReducer } from '@reduxjs/toolkit';

import { actions } from '../actions';

const sec = 1000;

const initialState = {
    lightOn: false,
    dataTracker: {
        partialUpdateInterval: 15 * sec /* data fetching of the most recent data (date > last update) */,
        nbPartialUpdateBeforeCompleteUpdate: 10 /* flush data, and perform complete data fetching */,
    },
    refreshDelay: {
        datasets: {
            fetchAll: 12 * sec /* Fetch everything */,
            fetchLast: 3 * sec /* Fetch the last tasks */,
            fetchPending: 4 * sec /* Update state of the pending tasks */,
            autoupdate: 14 * sec /* Update the state of the pending tasks */,
        },
        health: 5 * sec,
    },
};

const localPreferences = JSON.parse(localStorage.getItem('preferences'));
if (localPreferences) {
    Object.assign(initialState, localPreferences);
}

export const preference = createReducer(initialState, {
    [actions.preference.toggleLight]: (state) => ({ ...state, lightOn: !state.lightOn }),
});
