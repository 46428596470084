import { select } from '@redux-saga/core/effects';
import { all, call, takeLatest } from 'redux-saga/effects';

import { actions } from '../actions';
import { retrySaga } from './apiWrapper';

const savePreferencesLocalStorage = (preferences) => {
    localStorage.setItem('preferences', JSON.stringify({ lightOn: preferences.lightOn }));
};

function* save() {
    const preferences = yield select((state) => state.preference);
    yield call(retrySaga, savePreferencesLocalStorage, [preferences]);
}

export default function* watchPreference() {
    yield all([yield takeLatest(actions.preference.toggleLight.toString(), save)]);
}
