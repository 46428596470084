import { createReducer } from '@reduxjs/toolkit';

import { actions } from '../actions';

const initialEndpointState = {
    search: '',
    details: {
        set: false,
        data: '',
    },
};

export const annotation = createReducer(initialEndpointState, {
    [actions.annotation.search]: (state, action) => ({
        ...state,
        ...action.payload,
    }),

    [actions.annotation.details.set]: (state, action) => ({
        ...state,
        details: {
            set: true,
            data: action.payload,
        },
    }),

    [actions.annotation.details.reset]: (state, action) => ({
        ...initialEndpointState,
    }),
});
