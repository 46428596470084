import axios from 'axios';

function fetch(username, password) {
    return axios.post('api/api-token-auth/', { username, password });
}

function validate(username, password) {
    return axios.post('api/api-token-valid/');
}

function refresh() {
    return axios.get('api/api-token-refresh/');
}

export const auth = {
    fetch,
    validate,
    refresh,
};
