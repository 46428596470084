export const getStatusFormat = (status) => {
    return status.toLowerCase().replace(/\b\w/g, (l) => l.toUpperCase());
};

export const getWarningFormat = (warn) => {
    return warn
        .toLowerCase()
        .replace(/_/g, ' ')
        .replace(/\b\w/g, (l) => l.toUpperCase());
};

export const getFormattedName = (name) => {
    return name.replace(/_/g, ' ').replace(/\b\w/g, (l) => l.toUpperCase());
};

export const isEqual = (a, b) => {
    if (!a && !b) return true;
    if (!isNaN(a) && !isNaN(b)) return parseInt(a) === parseInt(b);
    if (!isNaN(a) && !b) return a === 0;
    if (!isNaN(b) && !a) return b === 0;
    if (!a || !b) return false;
    if (a === b) return true;

    return a.toLowerCase() === b.toLowerCase();
};

export const formatTimestampDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleDateString();
};

export const formatTimestampTime = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleTimeString();
};
