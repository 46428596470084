import { useTheme } from '@emotion/react';
import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import React from 'react';

import ProjectGraph from './ProjectGraph';

function ProjectCard({ project, graphMaxLength, graphFontSize }) {
    const theme = useTheme();

    const styles = {
        card: {
            backgroundColor: theme.palette.common.verylightgray,
            color: theme.palette.common.black,
            padding: 0,
            overflow: 'visible',
        },
        cardContent: {
            paddingBottom: '14px !important',
            padding: 0,
            position: 'relative',
        },
        iconBox: {
            display: 'flex',
            alignItems: 'center',
            marginRight: 2,
        },
        subDatasets: {
            marginRight: '4px',
            fontSize: '1vw',
            [theme.breakpoints.down('lg')]: {
                fontSize: '12px',
            },
        },
        folderIcon: {
            fontSize: 20,
            marginBottom: '4px',
        },
        projectName: {
            textAlign: 'center',
            maxWidth: '100%',
            fontSize: '1vw',
            [theme.breakpoints.down('lg')]: {
                fontSize: '20px',
            },
            fontWeight: 'bold',
        },
        graphNames: {
            textAlign: 'center',
            maxWidth: '100%',
            fontSize: '0.8vw',
            padding: 1,
            [theme.breakpoints.down('lg')]: {
                fontSize: '16px',
            },
        },
        lastUpdate: {
            position: 'absolute',
            right: 4,
            bottom: 0,
        },
        lastUpdateText: {
            fontSize: '10px',
            color: theme.palette.common.darkgray,
        },
        cardHeader: {
            backgroundColor: theme.palette.common.sectionGray,
            padding: 2,
        },
        hr: {
            borderBottom: `2px solid ${theme.palette.common.sectionGray}`,
            width: '100%',
        },
    };

    return (
        <Card sx={styles.card} className={'project-card'}>
            <CardContent sx={styles.cardContent}>
                <Grid container alignItems='center' sx={styles.cardHeader}>
                    <Grid item xs={12} height={'auto'}>
                        <Typography component='div' sx={styles.projectName}>
                            {project.name}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={2} sx={styles.cardBody}>
                    {project?.graph_data?.map((graph, index) => {
                        return (
                            <Grid item xs={12} height={'auto'} key={index}>
                                <Box sx={styles.hr} />

                                <Typography component='div' sx={styles.graphNames}>
                                    {graph?.title}
                                </Typography>
                                <ProjectGraph
                                    graph={graph?.data?.filter((item) => !item.hide)}
                                    graphMaxLength={graphMaxLength}
                                    graphFontSize={graphFontSize}
                                />
                            </Grid>
                        );
                    })}
                </Grid>
                <Box sx={styles.lastUpdate}>
                    <Typography variant='caption' sx={styles.lastUpdateText}>
                        Last update: {new Date(project.updated_at).toLocaleString()}
                    </Typography>
                </Box>
            </CardContent>
        </Card>
    );
}

export default ProjectCard;
