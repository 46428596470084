import { Box, useTheme } from '@mui/material';
import React, { useEffect } from 'react';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';

import { denormalizeTree } from '../../../../reducers/qbuilder';
import { FilterTree } from './FilterTree';

const defaultNode = { type: 'FILTER', negate: false, name: null, body: [], validated: false };

function LocalSegmentQueryBuilder(props) {
    const { nodes, setNodes, noDevSyntax, id } = props;

    useEffect(() => {
        if (!nodes || nodes.length === 0) {
            setNodes([{ id, ...defaultNode }]);
        }
    }, [nodes, setNodes, id]);

    const theme = useTheme();
    const styles = {
        root: {
            width: '100%',
        },
    };

    const addNode = ({ id, parentId }) => {
        setNodes((prevNodes) => {
            const newNode = { id: id, ...defaultNode };

            if (parentId) {
                const updatedNodes = prevNodes.map((node) =>
                    node.id === parentId ? { ...node, body: [...node.body, id] } : node,
                );
                return [...updatedNodes, newNode];
            }

            return [...prevNodes, newNode];
        });
    };

    const removeNode = ({ id }) => {
        setNodes((prevNodes) => {
            const nodesToRemove = new Set([id, ..._findAllSubNodes(id, prevNodes)]);

            const remainingNodes = prevNodes.filter((node) => !nodesToRemove.has(node.id));

            const nodeParent = prevNodes.find((node) => node.body.includes(id));
            if (nodeParent) {
                return remainingNodes.map((node) =>
                    node.id === nodeParent.id ? { ...node, body: node.body.filter((childId) => childId !== id) } : node,
                );
            }

            return remainingNodes;
        });
    };

    const editNode = ({ id, ...newProperties }) => {
        setNodes((prevNodes) => {
            return prevNodes.map((node) => (node.id === id ? { ...node, ...newProperties } : node));
        });
    };

    return (
        <Box display='flex' flexDirection='column' data-testid='query-builder-element'>
            <Box sx={styles.root}>
                <FilterTree
                    level={0}
                    id={id}
                    key={id}
                    filterType='segment'
                    addNode={addNode}
                    editNode={editNode}
                    removeNode={removeNode}
                    nodes={nodes}
                    local
                />
            </Box>
            {process.env.NODE_ENV !== 'production' && !noDevSyntax && (
                <SyntaxHighlighter data-testid={'debug-json'} language='json' style={theme.material}>
                    {JSON.stringify(denormalizeTree(id, nodes), null, 4)}
                </SyntaxHighlighter>
            )}
        </Box>
    );
}

function _findAllSubNodes(rootIdx, flattenedTree) {
    const root = flattenedTree.find((node) => node.id === rootIdx);
    if (root === undefined || root.type !== 'OPERATOR') return [];
    return [].concat.apply(
        [...root.body],
        root.body.map((childIdx) => _findAllSubNodes(childIdx, flattenedTree)),
    );
}

LocalSegmentQueryBuilder.requireProps = ['id', 'nodes', 'setNodes'];

export default LocalSegmentQueryBuilder;
