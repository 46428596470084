import { useTheme } from '@emotion/react';
import { Box, Button, Card, CardContent, Divider, Grid, TextField, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { v1 as uuidv1 } from 'uuid';

import LAttributeListInput from '../../../../../../../components/FilterSelector/LAttributeInput';
import LocalSegmentQueryBuilder from '../../../../../QBuilder/components/LocalSegmentQBuilder';
import { DELAY_BEFORE_ERROR, PROJECT_ENVIRONMENTS } from '../../constant';

const QuerySelectionForm = ({
    tables,
    setTables,
    areQueryValidated,
    handleNext,
    handleBack,
    setValidationCheck,
    editMode,
    doc,
}) => {
    const [showErrors, setShowErrors] = useState(false);
    const theme = useTheme();

    const styles = {
        buttonContainer: {
            margin: theme.spacing(2),
            display: 'flex',
            justifyContent: 'flex-end',
        },
        center: {
            width: '100%',
            textAlign: 'center',
        },
        section: {
            margin: '4px 0 20px 0',
            borderRadius: '5px',
            padding: theme.spacing(3),
            backgroundColor: theme.palette.common.sectionGray,
        },
        sectionTitle: {
            marginBottom: theme.spacing(2),
            textAlign: 'center',
        },
        name: {
            marginBottom: theme.spacing(3),
            textAlign: 'center',
        },
        subcategory: {
            marginBottom: theme.spacing(2),
        },
        card: {
            marginBottom: theme.spacing(2),
            backgroundColor: theme.palette.common.sectionGray,
            boxShadow: theme.shadows[3],
            borderRadius: '10px',
        },
        subcategoryTitle: {
            textAlign: 'center',
            fontStyle: 'italic',
        },
        inputRow: {
            display: 'flex',
            gap: theme.spacing(2),
            maxWidth: '400px',
            margin: 'auto',
        },
        inputField: {
            maxWidth: '200px',
        },
    };

    const handleQueryChange = (tableIndex, subjectIndex, queryIndex, nodes) => {
        const updatedTables = [...tables];

        if (typeof nodes === 'function') {
            const currentQuery =
                queryIndex !== undefined
                    ? updatedTables[tableIndex].data[subjectIndex].subcategories[queryIndex].query
                    : updatedTables[tableIndex].data[subjectIndex].query;

            const updatedNodes = nodes(currentQuery);

            if (queryIndex !== undefined) {
                updatedTables[tableIndex].data[subjectIndex].subcategories[queryIndex].query = updatedNodes;
            } else {
                updatedTables[tableIndex].data[subjectIndex].query = updatedNodes;
            }
        } else {
            if (queryIndex !== undefined) {
                updatedTables[tableIndex].data[subjectIndex].subcategories[queryIndex].query = nodes;
            } else {
                updatedTables[tableIndex].data[subjectIndex].query = nodes;
            }
        }

        setTables(updatedTables);
    };

    const handleHardcodedChange = (tableIndex, subjectIndex, queryIndex, field, value) => {
        value = parseInt(value);
        const updatedTables = [...tables];
        if (queryIndex !== undefined) {
            updatedTables[tableIndex].data[subjectIndex].subcategories[queryIndex][field] = value;
        } else {
            updatedTables[tableIndex].data[subjectIndex][field] = value;
        }
        setTables(updatedTables);
    };

    const getAndSetSubcategoryId = (tableIndex, subjectIndex, subIndex) => {
        let currentId = undefined;

        if (subIndex !== undefined) currentId = tables[tableIndex].data[subjectIndex].subcategories[subIndex]?.id;
        else currentId = tables[tableIndex].data[subjectIndex]?.id;

        if (currentId) return currentId;

        const updatedTables = [...tables];
        currentId = uuidv1();
        if (subIndex !== undefined) updatedTables[tableIndex].data[subjectIndex].subcategories[subIndex].id = currentId;
        else updatedTables[tableIndex].data[subjectIndex].id = currentId;

        setTables(updatedTables);
        return currentId;
    };

    const handleUniqueElementsChange = (tableIndex, subjectIndex, levelIndex, selectedValues) => {
        const updatedTables = [...tables];

        if (levelIndex !== null) {
            updatedTables[tableIndex].data[subjectIndex].subcategories[levelIndex].unique = selectedValues;
        } else {
            updatedTables[tableIndex].data[subjectIndex].unique = selectedValues;
        }
        setTables(updatedTables);
    };

    const allTableQuerySet = useCallback(() => {
        for (let table of tables) {
            for (let subject of table.data) {
                if (subject.subcategories && subject.subcategories.length > 0) {
                    for (let subcategory of subject.subcategories) {
                        if (subcategory.hardcoded) continue;
                        if (
                            !subcategory.query ||
                            subcategory.query.length === 0 ||
                            !subcategory.query.some((q) => q.name)
                        ) {
                            return false;
                        }
                    }
                } else if (
                    !subject.hardcoded &&
                    (!subject.query || subject.query.length === 0 || !subject.query.some((q) => q.name))
                ) {
                    return false;
                }
            }
        }
        return true;
    }, [tables]);

    const allHardcodedValuesFilled = useCallback(() => {
        for (let table of tables) {
            for (let subject of table.data) {
                if (subject.subcategories && subject.subcategories.length > 0) {
                    for (let subcategory of subject.subcategories) {
                        if (subcategory.hardcoded && !PROJECT_ENVIRONMENTS.every((key) => !isNaN(subcategory[key]))) {
                            return false;
                        }
                    }
                } else if (subject.hardcoded && !PROJECT_ENVIRONMENTS.every((key) => !isNaN(subject[key]))) {
                    return false;
                }
            }
        }
        return true;
    }, [tables]);

    const areUniqueFilled = useCallback(() => {
        for (let table of tables) {
            for (let subject of table.data) {
                if (subject.subcategories && subject.subcategories.length > 0) {
                    for (let subcategory of subject.subcategories) {
                        if (!subcategory.hardcoded && subcategory?.unique && !subcategory?.unique?.every((u) => u)) {
                            return false;
                        }
                    }
                } else if (!subject.hardcoded && subject?.unique && !subject?.unique?.every((u) => u)) {
                    return false;
                }
            }
        }
        return true;
    }, [tables]);

    useEffect(() => {
        const errorTimeout = setTimeout(() => {
            setShowErrors(true);
        }, DELAY_BEFORE_ERROR);

        return () => clearTimeout(errorTimeout);
    }, []);
    const checkFailure = () => {
        return !areQueryValidated || !allTableQuerySet() || !allHardcodedValuesFilled() || !areUniqueFilled();
    };

    useEffect(() => {
        setValidationCheck(() => () => !checkFailure());
        return () => setValidationCheck(() => () => true);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Box>
            <Box sx={styles.center}>
                <Typography variant='h5' sx={styles.sectionTitle}>
                    Queries Selection
                </Typography>
            </Box>
            {tables.map((table, tableIndex) => (
                <Box key={tableIndex} sx={styles.section}>
                    <Grid container justifyContent={'center'}>
                        <Grid item xs={11} sm={6} md={4} lg={3}>
                            <Grid container spacing={3} justifyContent={'center'} direction={'column'}>
                                <Grid item xs={12} sm={12} md={12} lg={12} sx={styles.name}>
                                    <Typography variant='h6'>{table.title}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {table.data.map((subject, subjectIndex) => (
                        <Card key={subjectIndex} sx={styles.card}>
                            <CardContent>
                                <Typography variant='body1' sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                                    {subject.subject}
                                </Typography>
                                {subject.subcategories &&
                                    subject.subcategories.map((subcategory, subIndex) => {
                                        const subcategoryId = getAndSetSubcategoryId(
                                            tableIndex,
                                            subjectIndex,
                                            subIndex,
                                        );

                                        return (
                                            <Box key={subIndex}>
                                                <Typography variant='body1' sx={styles.subcategoryTitle}>
                                                    {subcategory.subject}
                                                </Typography>
                                                {subcategory.hardcoded ? (
                                                    <Box sx={styles.inputRow}>
                                                        {PROJECT_ENVIRONMENTS.toReversed().map((key) => (
                                                            <TextField
                                                                key={key}
                                                                label={key.charAt(0).toUpperCase() + key.slice(1)}
                                                                value={
                                                                    isNaN(subcategory[key]) || subcategory[key] === null
                                                                        ? ''
                                                                        : subcategory[key]
                                                                }
                                                                onChange={(e) =>
                                                                    handleHardcodedChange(
                                                                        tableIndex,
                                                                        subjectIndex,
                                                                        subIndex,
                                                                        key,
                                                                        e.target.value,
                                                                    )
                                                                }
                                                                type='number'
                                                                sx={{
                                                                    ...styles.inputField,
                                                                    '& .MuiOutlinedInput-root': {
                                                                        '& fieldset': isNaN(
                                                                            Number(subcategory[key]),
                                                                        ) && {
                                                                            borderColor: theme.palette.error.main,
                                                                        },
                                                                    },
                                                                }}
                                                                margin='normal'
                                                            />
                                                        ))}
                                                    </Box>
                                                ) : (
                                                    <>
                                                        <LocalSegmentQueryBuilder
                                                            key={subcategoryId}
                                                            setNodes={(nodes) =>
                                                                handleQueryChange(
                                                                    tableIndex,
                                                                    subjectIndex,
                                                                    subIndex,
                                                                    nodes,
                                                                )
                                                            }
                                                            nodes={subcategory.query}
                                                            noDevSyntax
                                                            id={subcategoryId}
                                                        />
                                                        {subcategory?.unique !== undefined && (
                                                            <>
                                                                <Typography
                                                                    variant='body2'
                                                                    sx={{
                                                                        margin: theme.spacing(3),
                                                                        fontWeight: 'bold',
                                                                    }}
                                                                >
                                                                    Unique attributes
                                                                </Typography>
                                                                <LAttributeListInput
                                                                    onChange={(selectedValues) =>
                                                                        handleUniqueElementsChange(
                                                                            tableIndex,
                                                                            subjectIndex,
                                                                            subIndex,
                                                                            selectedValues,
                                                                        )
                                                                    }
                                                                    doc={doc}
                                                                    defaultValue={subcategory.unique}
                                                                />
                                                            </>
                                                        )}
                                                    </>
                                                )}
                                                {subIndex < subject.subcategories.length - 1 && (
                                                    <Divider sx={{ marginY: theme.spacing(2) }} />
                                                )}
                                            </Box>
                                        );
                                    })}
                                {!subject.subcategories && (
                                    <Box>
                                        {subject.hardcoded ? (
                                            <Box sx={styles.inputRow}>
                                                {PROJECT_ENVIRONMENTS.toReversed().map((key) => (
                                                    <TextField
                                                        key={key}
                                                        label={key.charAt(0).toUpperCase() + key.slice(1)}
                                                        value={
                                                            isNaN(subject[key]) || subject[key] === null
                                                                ? ''
                                                                : subject[key]
                                                        }
                                                        onChange={(e) =>
                                                            handleHardcodedChange(
                                                                tableIndex,
                                                                subjectIndex,
                                                                undefined,
                                                                key,
                                                                e.target.value,
                                                            )
                                                        }
                                                        type='number'
                                                        margin='normal'
                                                        sx={{
                                                            ...styles.inputField,
                                                            '& .MuiOutlinedInput-root': {
                                                                '& fieldset': isNaN(Number(subject[key])) && {
                                                                    borderColor: theme.palette.error.main,
                                                                },
                                                            },
                                                        }}
                                                    />
                                                ))}
                                            </Box>
                                        ) : (
                                            <>
                                                <LocalSegmentQueryBuilder
                                                    setNodes={(nodes) =>
                                                        handleQueryChange(tableIndex, subjectIndex, undefined, nodes)
                                                    }
                                                    nodes={subject.query}
                                                    noDevSyntax
                                                    id={getAndSetSubcategoryId(tableIndex, subjectIndex)}
                                                />
                                                {subject.unique !== undefined && (
                                                    <>
                                                        <Typography
                                                            variant='body2'
                                                            sx={{
                                                                margin: theme.spacing(3),
                                                                fontWeight: 'bold',
                                                            }}
                                                        >
                                                            Unique attributes
                                                        </Typography>
                                                        <LAttributeListInput
                                                            onChange={(selectedValues) =>
                                                                handleUniqueElementsChange(
                                                                    tableIndex,
                                                                    subjectIndex,
                                                                    null,
                                                                    selectedValues,
                                                                )
                                                            }
                                                            doc={doc}
                                                            defaultValue={subject.unique}
                                                        />
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </Box>
                                )}
                            </CardContent>
                        </Card>
                    ))}
                </Box>
            ))}

            <Box sx={{ ...styles.buttonContainer, display: 'flex-end', flexDirection: 'column', textAlign: 'right' }}>
                {showErrors && !areQueryValidated && (
                    <Typography variant='body2' color='error'>
                        Please validate all queries before saving.
                    </Typography>
                )}
                {showErrors && !allTableQuerySet() && (
                    <Typography variant='body2' color='error'>
                        Please enter a query for each element of each table row.
                    </Typography>
                )}
                {showErrors && !allHardcodedValuesFilled() && (
                    <Typography variant='body2' color='error'>
                        Please enter a value for each hardcoded field.
                    </Typography>
                )}
                {showErrors && !areUniqueFilled() && (
                    <Typography variant='body2' color='error'>
                        Please select a unique attribute for each unique query.
                    </Typography>
                )}
            </Box>

            <Box sx={styles.buttonContainer}>
                <Button onClick={handleBack} disabled={checkFailure() && editMode}>
                    Back
                </Button>
                <Button onClick={handleNext} disabled={checkFailure()}>
                    Next
                </Button>
            </Box>
        </Box>
    );
};

const mapStateToProps = (state) => {
    return {
        doc: state.qbuilder.doc['segment']['SEGMENT_ATTR_MATCH'],
    };
};

export default connect(mapStateToProps)(QuerySelectionForm);
