import React from 'react';
import { Area, Line } from 'recharts';

import { OTHER_ROW, isArea } from '../utils';

export const renderChartElement = (props) => {
    const {
        key,
        index,
        hoveredLegendKey,
        hoveredIndex,
        selectedName,
        handleAreaClick,
        isStacked,
        otherColor,
        colors,
        chartType,
        setHoveredIndex,
    } = props;

    const isHovered = key === hoveredLegendKey || hoveredIndex === index;
    const color = key === OTHER_ROW ? otherColor : colors[index % colors.length];

    if (isArea(chartType)) {
        return (
            <Area
                key={index}
                type='monotone'
                dataKey={key}
                stackId={isStacked ? '1' : undefined}
                stroke={color}
                fill={color}
                isAnimationActive={true}
                cursor={selectedName || key === OTHER_ROW ? 'default' : 'pointer'}
                onClick={() => handleAreaClick(key, index)}
                onMouseEnter={() => setHoveredIndex(index)}
                onMouseLeave={() => setHoveredIndex(null)}
                strokeOpacity={isHovered ? 0.8 : 1}
                strokeWidth={2}
                fillOpacity={isHovered ? 0.75 : 0.9}
                animationEasing='linear'
                animationDuration={300}
                animationBegin={0}
                activeDot={{ r: 0 }}
            />
        );
    }
    return (
        <Line
            key={index}
            type='monotone'
            dataKey={key}
            stroke={color}
            isAnimationActive={true}
            animationEasing='linear'
            cursor={selectedName || key === OTHER_ROW ? 'default' : 'pointer'}
            onClick={() => handleAreaClick(key, index)}
            activeDot={{
                r: 8,
                fill: color,
                stroke: 'white',
                strokeWidth: 2,
            }}
            strokeOpacity={isHovered ? 0.8 : 1}
            strokeWidth={2}
            fillOpacity={isHovered ? 0.8 : 0.5}
            animationDuration={300}
            animationBegin={0}
        />
    );
};
