import { annotation } from './annotation';
import { api } from './api';
import { dataset } from './dataset';
import { notification } from './notification';
import { preference } from './preference';
import { project } from './project';
import { qbuilder } from './qbuilder';

export const actions = {
    api,
    notification,
    preference,
    qbuilder,
    dataset,
    annotation,
    project,
};
