import { Face, Fingerprint, Visibility, VisibilityOff } from '@mui/icons-material';
import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl,
    IconButton,
    Input,
    InputAdornment,
    InputLabel,
    useMediaQuery,
} from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { actions } from '../../actions';
import CustomBackdrop from '../CustomBackdrop';

function LoginForm(props) {
    const { error, loggingIn } = props;

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [errorBadCredentials, setErrorBadCredentials] = useState(false);

    useEffect(() => {
        if (error && error.response && error.response.status === 400) setErrorBadCredentials(true);
    }, [error]);

    const handleSubmit = (event) => {
        event.preventDefault(); // avoid default page reloading
        setErrorBadCredentials(false);
        props.authRequest(username, password);
    };

    return (
        <Dialog fullScreen={fullScreen} open={true}>
            <DialogTitle>Authentication Form</DialogTitle>
            <DialogContent sx={{ paddingTop: '8px !important' }}>
                <form onSubmit={handleSubmit}>
                    <Box display='flex' flexDirection='column'>
                        <Box display='flex' alignItems='flex-end'>
                            <Face />
                            <Box p={1} />
                            <FormControl fullWidth required>
                                <InputLabel htmlFor='username' error={errorBadCredentials}>
                                    Username
                                </InputLabel>
                                <Input
                                    id='username'
                                    data-testid='username'
                                    autoFocus
                                    value={username}
                                    autoComplete='username'
                                    onChange={(e) => setUsername(e.target.value)}
                                    error={errorBadCredentials}
                                />
                            </FormControl>
                        </Box>
                        <Box display='flex' mt={2} alignItems='flex-end'>
                            <Fingerprint />
                            <Box p={1} />
                            <FormControl fullWidth required>
                                <InputLabel htmlFor='standard-adornment-password' error={errorBadCredentials}>
                                    Password
                                </InputLabel>
                                <Input
                                    id='standard-adornment-password'
                                    data-testid='password'
                                    type={showPassword ? 'text' : 'password'}
                                    autoComplete='current-password'
                                    value={password}
                                    onChange={(event) => setPassword(event.target.value)}
                                    error={errorBadCredentials}
                                    endAdornment={
                                        <InputAdornment position='end'>
                                            <IconButton
                                                aria-label='toggle password visibility'
                                                onClick={() => setShowPassword(!showPassword)}
                                            >
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </Box>
                    </Box>
                    <Box display='flex' pt={4} flexDirection='row-reverse'>
                        <Button type='submit' color='primary' variant='outlined' style={{ textTransform: 'none' }}>
                            Login
                        </Button>
                    </Box>
                </form>
            </DialogContent>
            {loggingIn && <CustomBackdrop />}
        </Dialog>
    );
}

const mapStateToProps = (state) => {
    return {
        loggedIn: state.auth.loggedIn,
        loggingIn: state.auth.loggingIn,
        error: state.auth.error,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        authRequest: (username, password) => dispatch(actions.api.auth.fetch.request({ username, password })),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
