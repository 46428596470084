import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';

import createRootReducer from '../reducers';
import rootSaga from '../sagas';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const sagaMiddleware = createSagaMiddleware();

export function configureStore(preloadedState) {
    return createStore(createRootReducer(), preloadedState, composeEnhancers(applyMiddleware(sagaMiddleware)));
}

export const store = configureStore(undefined);

sagaMiddleware.run(rootSaga);
