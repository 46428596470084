import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import React from 'react';

const BodyBuilderGrid = ({ children, ...props }) => {
    return (
        <Grid container direction='row' alignItems='center' spacing={4} {...props}>
            {children}
        </Grid>
    );
};

BodyBuilderGrid.propTypes = {
    children: PropTypes.node.isRequired,
};

export default BodyBuilderGrid;
