import {
    AccessTime,
    AccountCircle,
    DataUsage,
    ImportExport,
    Memory,
    Replay,
    Speed,
    StopCircle,
    WarningAmber,
} from '@mui/icons-material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import WarningIcon from '@mui/icons-material/Warning';
import {
    Box,
    Button,
    Card,
    CardContent,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogTitle,
    Grid,
    IconButton,
    Paper,
    Tooltip,
    Typography,
} from '@mui/material';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import { useTheme } from '@mui/material/styles';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';

import { actions } from '../../../actions';
import { api } from '../../../services';
import { useInterval } from '../../../services/helpers';
import ConfirmDeleteButton from '../../components/Button/ConfirmDeleteButton';
import { getPanelUri } from '../../panels';
import { PermissionManager } from '../../utils/Permissions/PermissionManager';
import { formatDataSize } from '../../utils/Table/Size';
import DataSetsPanel from '../DataSets';
import TaskState, {
    AVAILABLE_TASKS,
    STATE_FAILURE,
    STATE_SUCCESS,
    getTitleForTaskState,
    getWarningMessageForTaskState,
} from '../DataSets/components/TaskState';
import QBuilder from '../QBuilder';
import { StateStatus } from './components/State';
import { LittleColorlibConnector } from './components/StepConnector';

const PAGE_HEADER_HEIGHT = 46;
const REFRESH_INTERVAL = 1000 * 5; // 5 seconds
const DOC_REFRESH_INTERVAL = 1000 * 60 * 5; // 5 minutes
const REFRESH_INTERVAL_FAST = 1000 * 10; // 10 seconds
const REFRESH_MAX_RETRIES = 10;
const DEFAULT_BODY_HEIGHT = 248;

const sortOrder = ['DB_QUERY', 'COMPRESS', 'DS_BUILD'];

const sortEntries = ([a], [b]) => sortOrder.indexOf(a) - sortOrder.indexOf(b);

const steps = [
    {
        title: 'Step 1: Time taken to execute the query',
        stateKey: 'duration_query',
    },
    {
        title: 'Step 2: Time taken to process the annotations',
        stateKey: 'duration_specs',
    },
    {
        title: 'Step 3: Time taken to merge the data',
        stateKey: 'duration_merging',
    },
    {
        title: 'Step 4: Time taken to compress the dataset',
        stateKey: 'duration_compressing',
    },
    {
        title: 'Step 5: Time taken to upload',
        stateKey: 'duration_uploading',
    },
];

function DataSetsDetails(props) {
    const {
        completeIsFinished,
        dsProcessedComplete,
        dsMatched,
        fetchLasts,
        dsCompleteCheck,
        dsMatchedCheck,
        dsReRun,
        dsRemove,
        resetCurrentDataset,
        dsProcessedTasks,
        rerunPerms,
        deletePerms,
        dsCountCheck,
        setDatasetDetailsId,
        dsFetchDataset,
        isLoading,
        celeryFetching,
    } = props;

    const theme = useTheme();
    const styles = {
        paper: {
            width: '100%',
            position: 'relative',
            boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2)',
            marginBottom: '4px',
        },
        outPaper: {
            [theme.breakpoints.down('md')]: {
                marginBottom: theme.spacing(4),
            },
        },
        title: {
            fontWeight: 'bold',
            paddingTop: theme.spacing(3),
            paddingBottom: theme.spacing(2),
            textAlign: 'center',
            height: '5rem',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
        },
        topPage: {
            margin: '0 16px',
        },
        loading: {
            textAlign: 'center',
        },
        taskStatus: {
            textAlign: 'center',
            color: theme.palette.text.secondary,
            fontStyle: 'italic',
            fontSize: '1rem',
        },
        subTitle: {
            textAlign: 'center',
            marginBottom: theme.spacing(2),
        },
        queryCard: {
            margin: theme.spacing(1),
            backgroundColor: theme.palette.common.verylightgray,
        },
        manage: {
            position: 'absolute',
            top: 0,
            right: 0,
            marginRight: theme.spacing(2),
            marginTop: theme.spacing(2),
        },
        rerunDialog: {
            '& .MuiDialog-paper': {
                backgroundColor: theme.palette.background.main,
            },
            margin: theme.spacing(1),
        },
        cell: {
            borderBottom: 'none',
        },
        centerError: {
            textAlign: 'center',
            color: theme.palette.error.main,
            padding: theme.spacing(2),
        },
        export: {
            position: 'absolute',
            top: 0,
            left: 0,
            marginRight: theme.spacing(2),
            marginTop: theme.spacing(2),
        },
        progress: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '400px',
        },
        separator: {
            height: 2,
            margin: '0 8px',
            borderColor: theme.palette.common.lightgray,
            width: '50%',
        },
        stepper: {
            marginTop: theme.spacing(2),

            paddingLeft: theme.spacing(0),
            paddingRight: theme.spacing(0),
            [theme.breakpoints.up('nd')]: {
                paddingLeft: theme.spacing(4),
                paddingRight: theme.spacing(4),
            },
            [theme.breakpoints.up('lg')]: {
                paddingLeft: theme.spacing(8),
                paddingRight: theme.spacing(8),
            },
        },
        stepLabel: {
            '& .MuiStepLabel-label': {
                marginTop: '4px',
            },
        },
        elips: {
            overflowX: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        },
        date: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: theme.spacing(1),
        },
    };

    const { id } = useParams();
    const datasetId = parseInt(id, 10);

    const [reRun, setReRun] = useState(false);
    const [isRunning, setIsRunning] = useState(false);
    const [warning, setWarning] = useState(null);
    const [activeStep, setActiveStep] = useState(0);
    const [refreshing, setRefreshing] = useState(false);
    const refreshInterval = useRef(null);
    const currentRefreshRep = useRef(0);

    const navigate = useNavigate();

    const dsTasksLocal = useMemo(() => {
        return dsProcessedTasks.find((ds) => ds.id === datasetId);
    }, [dsProcessedTasks, datasetId]);

    const dsMatchedObj = Object.fromEntries(new Map(dsMatched.map((obj) => [obj.id, obj.matched])));

    useEffect(() => {
        if (celeryFetching) return;

        dsFetchDataset(datasetId);
        dsCountCheck(datasetId);
    }, [celeryFetching]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!datasetId) return;
        setDatasetDetailsId(datasetId);
        fetchLasts(moment(), api.endpoints.celery);
    }, [datasetId, dsFetchDataset, setDatasetDetailsId, fetchLasts]);

    useEffect(() => {
        if (completeIsFinished) {
            console.log('Dataset is already finished and up to date.');
            if (refreshInterval.current) {
                clearInterval(refreshInterval.current);
                refreshInterval.current = null;
            }
            if (refreshing) setRefreshing(false);
            return;
        }

        if (refreshing && datasetId) {
            if (!refreshInterval.current) {
                console.log('Starting refresh interval...');
                currentRefreshRep.current = 0;
                refreshInterval.current = setInterval(() => {
                    if (currentRefreshRep.current >= REFRESH_MAX_RETRIES) {
                        console.log('Refresh interval reached maximum retries.');
                        if (refreshInterval.current) {
                            clearInterval(refreshInterval.current);
                            refreshInterval.current = null;
                        }
                        setRefreshing(false);
                        return;
                    }
                    currentRefreshRep.current += 1;

                    console.log('Refreshing dataset details...');
                    dsCompleteCheck(datasetId);
                    dsMatchedCheck(datasetId);
                }, REFRESH_INTERVAL_FAST);

                dsCompleteCheck(datasetId);
                dsMatchedCheck(datasetId);
            }

            return () => {
                if (refreshInterval.current) {
                    clearInterval(refreshInterval.current);
                    refreshInterval.current = null;
                }
            };
        }
    }, [refreshing, completeIsFinished, datasetId, dsCompleteCheck, dsMatchedCheck]);

    useEffect(() => {
        return () => {
            if (refreshInterval.current) {
                clearInterval(refreshInterval.current);
                refreshInterval.current = null;
            }
        };
    }, []);

    useEffect(() => {
        if (!datasetId) return;

        const states = dsTasksLocal?.states;
        if (!states) return;

        const finished = isEntirelyFinished();
        setWarning(getWarningMessageForTaskState(states));

        if (finished && isRunning) {
            setRefreshing(true);
            setIsRunning(false);
        } else if (!finished) {
            setIsRunning(true);
        }
    }, [dsTasksLocal, dsCompleteCheck, dsMatchedCheck, datasetId, setRefreshing]); // eslint-disable-line react-hooks/exhaustive-deps

    useInterval(() => {
        if (!datasetId) return;
        if (!isRunning) return;

        dsMatchedCheck(datasetId);
        dsCompleteCheck(datasetId);
    }, DOC_REFRESH_INTERVAL);

    useInterval(() => {
        if (!datasetId) return;
        fetchLasts(moment(), api.endpoints.celery);
    }, REFRESH_INTERVAL);

    useEffect(() => {
        if (!datasetId) return;
        dsCompleteCheck(datasetId);
        dsMatchedCheck(datasetId);
    }, [datasetId, dsCompleteCheck, dsMatchedCheck]);

    const isFinished = (state) => {
        return state?.state === STATE_SUCCESS || state?.state === STATE_FAILURE;
    };

    const isEntirelyFinished = useCallback(() => {
        const states = dsTasksLocal?.states;
        if (!states) return false;

        const not_finished = Object.entries(states).filter(([name, state]) => !isFinished(state));
        return not_finished.length === 0;
    }, [dsTasksLocal]);

    useEffect(() => {
        if (!dsTasksLocal) {
            setActiveStep(0);
            return;
        }

        if (isEntirelyFinished()) {
            setActiveStep(steps.length);
            return;
        }

        let currentStep = 0;
        steps.forEach((step, index) => {
            if (dsTasksLocal[step.stateKey]) {
                currentStep = index + 1;
            }
        });

        setActiveStep(currentStep);
    }, [dsTasksLocal, isEntirelyFinished]);

    const handleDialogReRun = () => {
        setReRun(true);
    };

    const handleReRun = () => {
        dsReRun(dsTasksLocal);
        setTimeout(() => {
            fetchLasts(moment(), api.endpoints.celery, true);
        }, 300);
        cancelReRun();
    };

    const cancelReRun = () => {
        setReRun(false);
    };

    const handleDelete = () => {
        navigate(getPanelUri(<DataSetsPanel />));
        dsRemove(datasetId);
        resetCurrentDataset();
    };

    if (isNaN(datasetId)) {
        return (
            <Box data-testid={'details-page'}>
                <Paper style={styles.paper}>
                    <Typography variant='h5' style={styles.title}>
                        Dataset details
                    </Typography>
                    <Typography variant='body1' style={styles.centerError}>
                        {id} is not a valid dataset id.
                    </Typography>
                </Paper>
            </Box>
        );
    }

    if (!dsTasksLocal) {
        if (isLoading) {
            return (
                <Box data-testid={'details-page'}>
                    <Paper style={styles.paper}>
                        <Typography variant='h5' style={styles.title}>
                            Dataset details
                        </Typography>
                        <Box sx={styles.progress}>
                            <CircularProgress />
                        </Box>
                    </Paper>
                </Box>
            );
        }

        return (
            <Box data-testid={'details-page'}>
                <Paper style={styles.paper}>
                    <Typography variant='h5' style={styles.title}>
                        Dataset details
                    </Typography>
                    <Typography variant='body1' style={styles.centerError}>
                        Dataset with id {datasetId} not found.
                    </Typography>
                </Paper>
            </Box>
        );
    }

    const handleExportQbuilder = () => {
        const base64Query = btoa(JSON.stringify(dsTasksLocal.query));
        const uri = getPanelUri(<QBuilder />, undefined, { query: base64Query });
        navigate(uri);
    };

    const getDocumentCount = () => {
        const state = dsTasksLocal?.states;
        if (!state) {
            return 'Fetching...';
        }

        const success = state?.[AVAILABLE_TASKS.DS_BUILD]?.state === STATE_SUCCESS;
        if (success) {
            if (!completeIsFinished) {
                return 'Refreshing...';
            }
            return dsProcessedComplete[datasetId]?.count || 'Fetching...';
        }

        const failure =
            state?.[AVAILABLE_TASKS.DB_QUERY]?.state === STATE_FAILURE ||
            state?.[AVAILABLE_TASKS.COMPRESS]?.state === STATE_FAILURE ||
            state?.[AVAILABLE_TASKS.DS_BUILD]?.state === STATE_FAILURE;

        if (failure) {
            return 'N/A';
        }

        return 'Processing...';
    };

    const formatDuration = (duration) => {
        if (!duration) return 'N/A';

        const durationObj = moment.duration(duration);
        const hours = durationObj.hours();
        const minutes = durationObj.minutes();
        const seconds = durationObj.seconds();
        const milliseconds = durationObj.milliseconds();

        if (hours > 0) {
            return `${hours}h${minutes}m${seconds}s`;
        }
        if (minutes > 0 || hours > 0) {
            return `${minutes}m${seconds}s`;
        }
        // Keep only 2 decimals
        const secs = seconds + milliseconds / 1000;
        return `${secs.toFixed(2)}s`;
    };

    const totalDuration = moment
        .duration(moment(dsTasksLocal.time_end).diff(moment(dsTasksLocal.time_start)))
        .asMilliseconds();

    return (
        <Box data-testid={'details-page'} sx={styles.outPaper}>
            <Paper style={styles.paper}>
                <Box style={styles.manage}>
                    {(rerunPerms || deletePerms) && (
                        <Box align='center' sx={styles.cell}>
                            {rerunPerms && (
                                <Tooltip title={'Rerun dataset'}>
                                    <IconButton onClick={() => handleDialogReRun()}>
                                        <Replay />
                                    </IconButton>
                                </Tooltip>
                            )}
                            {deletePerms && (
                                <ConfirmDeleteButton onClick={() => handleDelete()} elementTitle={'dataset'} />
                            )}
                        </Box>
                    )}
                </Box>
                <Box style={styles.export}>
                    <Box align='center' sx={styles.cell}>
                        <span style={{ marginLeft: theme.spacing(2) }}>
                            <Tooltip title='Export dataset to the query builder'>
                                <IconButton onClick={handleExportQbuilder}>
                                    <ImportExport />
                                </IconButton>
                            </Tooltip>
                        </span>
                    </Box>
                </Box>

                <Box sx={styles.topPage}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                        <Typography variant='h5' sx={styles.title}>
                            {dsTasksLocal.name}
                        </Typography>

                        {/*Loading*/}
                        {dsTasksLocal?.states === undefined && (
                            <span style={{ marginLeft: theme.spacing(2) }}>
                                <TaskState states={undefined} />
                            </span>
                        )}

                        {/* Success */}
                        {isEntirelyFinished() && (
                            <span style={{ marginLeft: theme.spacing(2) }}>
                                <TaskState states={dsTasksLocal.states} />
                            </span>
                        )}
                    </Box>
                </Box>

                <Grid container spacing={1}>
                    <Grid
                        item
                        xs={12}
                        md={6}
                        sx={{
                            marginTop: theme.spacing(3),
                            [theme.breakpoints.up('md')]: {
                                maxHeight: `calc(100vh - ${DEFAULT_BODY_HEIGHT}px)`,
                                overflowY: 'auto',
                                overflowX: 'hidden',
                            },
                        }}
                    >
                        {/*Details*/}
                        <Typography variant='h5' style={styles.subTitle}>
                            Details
                        </Typography>
                        <Grid container sx={{ paddingBottom: theme.spacing(3) }} justifyContent='center' spacing={3}>
                            <Grid item xs={12} sm={4} md={4} lg={3} align='center'>
                                <Tooltip title={`Created at ${new Date(dsTasksLocal?.datetime).toLocaleString()}`}>
                                    <Box>
                                        <AccessTime />
                                        <Typography sx={styles.elips}>
                                            {new Date(dsTasksLocal?.datetime).toLocaleDateString()}
                                        </Typography>
                                    </Box>
                                </Tooltip>
                            </Grid>
                            <Grid item xs={12} sm={4} md={4} lg={3} align='center'>
                                <AccountCircle />
                                <Typography sx={styles.elips}>{dsTasksLocal.user.username}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={4} md={4} lg={3} align='center'>
                                <Memory />
                                <Typography>
                                    {(dsTasksLocal?.size && formatDataSize(dsTasksLocal.size)) || 'N/A'}
                                </Typography>
                            </Grid>
                        </Grid>

                        {/*Documents*/}
                        <Grid container spacing={3} sx={{ paddingBottom: theme.spacing(3) }} justifyContent='center'>
                            <Grid item xs={12} sm={4} md={4} lg={3} align='center'>
                                <DataUsage />
                                <Typography>{`Documents: ${getDocumentCount()}`}</Typography>
                            </Grid>
                            {completeIsFinished &&
                                dsMatched[0] &&
                                dsProcessedComplete[datasetId] &&
                                dsProcessedComplete[datasetId].count !== dsMatchedObj[datasetId] &&
                                isEntirelyFinished() && (
                                    <Grid item xs={12} sm={4} md={4} lg={3} align='center'>
                                        <WarningAmber />
                                        {dsMatchedObj[datasetId] - dsProcessedComplete[datasetId].count > 0 ? (
                                            <Typography>
                                                {`${dsMatchedObj[datasetId] - dsProcessedComplete[datasetId].count}
                                            new document${
                                                dsMatchedObj[datasetId] - dsProcessedComplete[datasetId].count > 1
                                                    ? 's'
                                                    : ''
                                            }
                                            found`}
                                            </Typography>
                                        ) : (
                                            <Typography>
                                                {`${dsProcessedComplete[datasetId].count - dsMatchedObj[datasetId]}
                                            document${
                                                dsProcessedComplete[datasetId].count - dsMatchedObj[datasetId] > 1
                                                    ? 's'
                                                    : ''
                                            }
                                            removed`}
                                            </Typography>
                                        )}
                                    </Grid>
                                )}

                            <Grid item xs={12} sm={4} md={4} lg={3} align='center'>
                                {dsProcessedComplete[datasetId] ? (
                                    <>
                                        {dsProcessedComplete[datasetId].up_to_date === true ? (
                                            <CheckCircleOutlineIcon />
                                        ) : dsProcessedComplete[datasetId].up_to_date === false ? (
                                            <ErrorOutlineIcon />
                                        ) : (
                                            <HelpOutlineIcon />
                                        )}
                                        <Typography>
                                            {dsProcessedComplete[datasetId].up_to_date === true
                                                ? 'Up to date'
                                                : dsProcessedComplete[datasetId].up_to_date === false
                                                ? 'Outdated'
                                                : 'Status not available'}
                                        </Typography>
                                    </>
                                ) : (
                                    <>
                                        <HelpOutlineIcon />
                                        <Typography>Fetching status...</Typography>
                                    </>
                                )}
                            </Grid>
                        </Grid>

                        {/*Process*/}
                        <Grid container spacing={3} sx={{ paddingBottom: theme.spacing(3) }} justifyContent='center'>
                            <Grid item xs={12} align='center'>
                                <hr style={styles.separator} />
                            </Grid>
                            <Grid item xs={12} align='center'>
                                <Typography variant='h5' style={styles.subTitle}>
                                    Process
                                </Typography>
                                <Grid
                                    container
                                    spacing={2}
                                    justifyContent='center'
                                    sx={{ marginBottom: theme.spacing(1), padding: '0 32px' }}
                                >
                                    <Grid item xs={12} sm={4} md={3} lg={2} sx={{ paddingTop: theme.spacing(1) }}>
                                        <Tooltip
                                            arrow
                                            placement='top'
                                            title={`Start time (${
                                                dsTasksLocal?.time_start
                                                    ? new Date(dsTasksLocal.time_start).toLocaleString()
                                                    : 'N/A'
                                            })`}
                                        >
                                            <Box sx={styles.date}>
                                                <PlayCircleFilledIcon />
                                                <Typography>
                                                    {dsTasksLocal?.time_start
                                                        ? new Date(dsTasksLocal.time_start).toLocaleTimeString()
                                                        : 'N/A'}
                                                </Typography>
                                            </Box>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={12} sm={4} md={3} lg={2} sx={{ paddingTop: theme.spacing(1) }}>
                                        <Tooltip arrow placement='top' title='Total processing time'>
                                            <Box sx={styles.date}>
                                                <Speed />
                                                <Typography>{formatDuration(totalDuration)}</Typography>
                                            </Box>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={12} sm={4} md={3} lg={2} sx={{ paddingTop: theme.spacing(1) }}>
                                        <Tooltip
                                            arrow
                                            placement='top'
                                            title={`End time (${
                                                dsTasksLocal?.time_end
                                                    ? new Date(dsTasksLocal.time_end).toLocaleString()
                                                    : 'N/A'
                                            })`}
                                        >
                                            <Box sx={styles.date}>
                                                <StopCircle />
                                                <Typography>
                                                    {dsTasksLocal?.time_end
                                                        ? new Date(dsTasksLocal.time_end).toLocaleTimeString()
                                                        : 'N/A'}
                                                </Typography>
                                            </Box>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                                <Stepper
                                    activeStep={activeStep}
                                    alternativeLabel
                                    connector={<LittleColorlibConnector />}
                                    sx={styles.stepper}
                                >
                                    {steps.map((step) => (
                                        <Tooltip title={step.title} key={step.title} placement='top'>
                                            <Step>
                                                <StepLabel sx={styles.stepLabel}>
                                                    {formatDuration(dsTasksLocal[step.stateKey])}
                                                </StepLabel>
                                            </Step>
                                        </Tooltip>
                                    ))}
                                </Stepper>
                                {!isEntirelyFinished() && dsTasksLocal.states && (
                                    <Grid item xs={12} align='center' sx={{ marginTop: theme.spacing(2) }}>
                                        <Box style={styles.loading}>
                                            <TaskState states={dsTasksLocal.states} />
                                        </Box>
                                        <Typography style={styles.taskStatus}>
                                            {getTitleForTaskState(dsTasksLocal.states)}
                                        </Typography>
                                    </Grid>
                                )}
                                <Grid item xs={12} sx={{ marginTop: theme.spacing(2) }}>
                                    <Grid container justifyContent='center'>
                                        {dsTasksLocal?.states ? (
                                            <>
                                                {Object.entries(dsTasksLocal.states)
                                                    .sort(sortEntries)
                                                    .map(([name, state], index) => (
                                                        <StateStatus key={index} name={name} state={state} />
                                                    ))}
                                            </>
                                        ) : (
                                            <>
                                                <StateStatus />
                                                <StateStatus />
                                                <StateStatus />
                                            </>
                                        )}
                                    </Grid>
                                    <Grid container justifyContent='center'>
                                        <Typography variant='body2'>Task status</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        {/*Warnings*/}
                        <Grid container spacing={3} sx={{ paddingBottom: theme.spacing(3) }} justifyContent='center'>
                            {warning && (
                                <>
                                    <Grid item xs={12} align='center'>
                                        <hr style={styles.separator} />
                                    </Grid>
                                    <Grid item xs={12} align='center'>
                                        <Typography variant='h5' style={styles.subTitle}>
                                            Warnings
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} align='center' sx={{ paddingTop: '0 !important' }}>
                                        <WarningIcon color='warning' />
                                        <Box>
                                            {warning.split('\n').map((line, index) =>
                                                index === 0 ? (
                                                    <Typography key={index} sx={{ color: theme.palette.warning.main }}>
                                                        {line}
                                                    </Typography>
                                                ) : (
                                                    <Tooltip
                                                        title={line}
                                                        key={index}
                                                        placement='top'
                                                        sx={{ color: theme.palette.warning.main }}
                                                    >
                                                        <Typography variant={'body2'}>
                                                            {line.slice(0, 20) + '...'}
                                                        </Typography>
                                                    </Tooltip>
                                                ),
                                            )}
                                        </Box>
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Card sx={styles.queryCard}>
                            <CardContent>
                                <Typography variant='h5' style={styles.subTitle}>
                                    Query
                                </Typography>
                                <SyntaxHighlighter
                                    language='json'
                                    style={theme.material}
                                    customStyle={{
                                        maxHeight: `calc(100vh - ${
                                            isEntirelyFinished() ? 352 : 416
                                        }px + ${PAGE_HEADER_HEIGHT}px)`,
                                    }}
                                >
                                    {JSON.stringify(dsTasksLocal.query, null, 2)}
                                </SyntaxHighlighter>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Paper>
            <Dialog open={reRun} onClose={() => setReRun(false)}>
                <DialogTitle>{`Are you sure you want to rerun?`}</DialogTitle>
                <DialogActions sx={styles.rerunDialog}>
                    <Button variant='outlined' onClick={cancelReRun}>
                        Cancel
                    </Button>

                    <Button variant='contained' onClick={handleReRun}>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

const mapStateToProps = (state) => {
    return {
        dsProcessedComplete: (state.processedData[api.endpoints.dsComplete] || {}).uptodateMapping || {},
        completeIsFinished: (state.processedData[api.endpoints.dsComplete] || {}).isFinished || false,
        dsMatched: (state.data[api.endpoints.dsMatched] || {}).data || [],
        dsProcessedTasks: (state.processedData[api.endpoints.dsTasks] || {}).data || [],
        celeryFetching: (state.processedData[api.endpoints.celery] || {}).isFetching || false,
        rerunPerms: state.auth?.user?.perms?.[PermissionManager.rerunDatasetPerm] || state.auth?.user?.staff || false,
        deletePerms: state.auth?.user?.perms?.[PermissionManager.deleteDatasetPerm] || state.auth?.user?.staff || false,
        isLoading:
            state.processedData[api.endpoints.dsTasks]?.isFetching ||
            state.processedData[api.endpoints.dsTasks]?.isUpdating ||
            false,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        resetCurrentDataset: () => dispatch(actions.dataset.details.reset()),
        fetchLasts: (datetime, endpoint, force = undefined) =>
            dispatch(
                actions.api.data.fetch.request({
                    endpoint: endpoint,
                    datetime,
                    force,
                }),
            ),
        dsCompleteCheck: (id) => dispatch(actions.api.data.update.request({ endpoint: api.endpoints.dsComplete, id })),
        dsMatchedCheck: (id) => dispatch(actions.api.data.update.request({ endpoint: api.endpoints.dsMatched, id })),
        dsReRun: (row) => dispatch(actions.qbuilder.rerun.request({ endpoint: api.endpoints.dsReRun, row })),
        dsRemove: (id) => dispatch(actions.api.data.remove.request({ endpoint: api.endpoints.dsTasks, id })),
        dsCountCheck: (id) => dispatch(actions.api.data.update.request({ endpoint: api.endpoints.dsCount, id })),
        dsFetchDataset: (id) => dispatch(actions.api.data.update.request({ endpoint: api.endpoints.dsTasks, id })),
        setDatasetDetailsId: (id) => dispatch(actions.dataset.details.set(id)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DataSetsDetails);
