import { createReducer } from '@reduxjs/toolkit';

import { actions } from '../actions';

const initialState = {
    search: '',
    error: false,
};

export const dataset = createReducer(initialState, {
    [actions.dataset.search]: (state, action) => ({
        ...state,
        ...action.payload,
    }),
});
