import * as icons from '@mui/icons-material';
import { Box, Collapse, IconButton, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { v1 as uuidv1 } from 'uuid';

import { actions } from '../../../../actions';
import FilterSelector from '../../../../components/FilterSelector';
import { OperatorSelector } from './OperatorSelector';

export const FilterTree = (props) => {
    const theme = useTheme();
    const { level, id, parentId, filterType, nodes, addNode, editNode, removeNode, local } = props;

    const [node, setNode] = useState(null);
    const [open, setOpen] = React.useState(true);

    const isOperator = () => node.type !== 'FILTER';

    useEffect(() => {
        setNode(nodes.find((node) => node.id === id));
    }, [nodes, id]);

    const onToggleType = () => {
        if (isOperator()) editNode({ id, type: 'FILTER', name: null, body: [], validated: false });
        else editNode({ id, type: 'OPERATOR', name: 'AND', body: [], validated: true });
    };

    const onToggleNegate = () => {
        editNode({ id, negate: !node.negate, validated: false });
    };

    const onChangeName = (name) => {
        editNode({ id, name });
    };
    const onChangeBody = (body) => {
        editNode({ id, body });
    };
    const onAddChild = () => {
        addNode({ id: uuidv1(), parentId: id });
    };
    const onRemoveChild = () => {
        removeNode({ id, parentId });
    };

    return (
        node != null && (
            <Box ml={theme.spacing(level * 3)} data-testid={'node-element'}>
                <ListItem button>
                    <ListItemIcon>
                        <IconButton onClick={onToggleNegate} data-testid={'toggle-negate-btn'}>
                            <icons.PriorityHigh
                                data-testid={'negate-btn-icon'}
                                color={node.negate ? 'error' : 'disabled'}
                            />
                        </IconButton>
                    </ListItemIcon>

                    <ListItemIcon>
                        <IconButton onClick={onToggleType} data-testid={'toggle-type-btn'}>
                            {(isOperator() && <icons.FilterList data-testid={'type-btn-filter-list-icon'} />) || (
                                <icons.Filter data-testid={'type-btn-filter-icon'} />
                            )}
                        </IconButton>
                    </ListItemIcon>

                    {(isOperator() && <OperatorSelector defaultValue={node.name} onChange={onChangeName} />) || (
                        <FilterSelector
                            defaultFilter={node.name}
                            defaultData={node.body}
                            filterType={filterType}
                            onFilterChange={onChangeName}
                            onDataChange={onChangeBody}
                            nodeId={id}
                            local={local}
                        />
                    )}

                    <ListItemText />
                    {isOperator() && (
                        <IconButton onClick={onAddChild} data-testid={'add-child-btn'}>
                            <icons.PlaylistAdd color='secondary' />
                        </IconButton>
                    )}
                    {level > 0 && (
                        <IconButton onClick={onRemoveChild} data-testid={'delete-filter-btn'}>
                            <icons.Delete color='error' />
                        </IconButton>
                    )}
                    {isOperator() && node.body.length > 0 && (
                        <IconButton data-testid={'expand-child-btn'} onClick={() => setOpen(!open)}>
                            {(open && <icons.ExpandLess data-testid={'expand-less-icon'} />) || (
                                <icons.ExpandMore data-testid={'expand-more-icon'} />
                            )}
                        </IconButton>
                    )}
                </ListItem>
                {isOperator() && node.body.length > 0 && (
                    <Collapse in={open} timeout='auto'>
                        <List component='div' disablePadding>
                            {node.body.map((childId) => (
                                <FilterTree
                                    id={childId}
                                    key={childId}
                                    parentId={id}
                                    level={level + 1}
                                    filterType={filterType}
                                    addNode={addNode}
                                    editNode={editNode}
                                    removeNode={removeNode}
                                    nodes={nodes}
                                    local={local}
                                />
                            ))}
                        </List>
                    </Collapse>
                )}
            </Box>
        )
    );
};

FilterTree.propTypes = {
    id: PropTypes.string.isRequired,
    parentId: PropTypes.string,
    level: PropTypes.number.isRequired,
    filterType: PropTypes.string.isRequired,

    nodes: PropTypes.array.isRequired,
    addNode: PropTypes.func.isRequired,
    editNode: PropTypes.func.isRequired,
    removeNode: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
    return {
        nodes: state.qbuilder.nodes,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addNode: ({ id, parentId }) => dispatch(actions.qbuilder.node.add({ id, parentId })),
        editNode: ({ id, type, name, body, negate }) =>
            dispatch(actions.qbuilder.node.edit({ id, type, name, body, negate })),
        removeNode: ({ id, parentId }) => dispatch(actions.qbuilder.node.remove({ id, parentId })),
    };
};

const ConnectedNode = connect(mapStateToProps, mapDispatchToProps)(FilterTree);

export default ConnectedNode;
