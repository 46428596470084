import { createAction } from '@reduxjs/toolkit';

export const api = {
    auth: {
        fetch: {
            request: createAction('api/auth/fetch/request'),
            success: createAction('api/auth/fetch/success'),
            failure: createAction('api/auth/fetch/failure'),
            cancel: createAction('api/auth/fetch/cancel'),
        },
        validate: {
            request: createAction('api/auth/validate/request'),
            success: createAction('api/auth/validate/success'),
            failure: createAction('api/auth/validate/failure'),
        },
        refresh: {
            request: createAction('api/auth/refresh/request'),
            success: createAction('api/auth/refresh/success'),
            failure: createAction('api/auth/refresh/failure'),
        },
    },
    // Backend health check actions
    health: {
        request: createAction('api/health/request'),
        success: createAction('api/health/success'),
        failure: createAction('api/health/failure'),
    },
    data_health: {
        request: createAction('api/data/health/request'),
        success: createAction('api/data/health/success'),
        failure: createAction('api/data/health/failure'),
    },
    // Handling data exposed by the backend API
    data: {
        busy: createAction('api/data/busy'),

        reset: createAction('api/data/reset'),

        // Managing "tracked" api endpoints (for automatic periodic data fetching)
        tracker: {
            /*
                Add an endpoint to the tracking engine
                payload: {
                    endpoint: str: endpoint to track (ex: "api/videos")
                    freqPartial: int: frequency in millisecond of partial updates (default: value from preferences)
                    nbPartialBeforeComplete: uint: Number of partial update before complete updates (default: value from preferences)
                }
             */
            add: createAction('api/data/tracker/add'),
            // Remove an endpoint from the tracking engine
            remove: createAction('api/data/tracker/remove'),
            // Remove all the endpoints
            clear: createAction('api/data/tracker/clear'),
        },
        // Light-weight update of the DATA by fetching only the recent data not fetched yet (filter time > last update)
        update: {
            request: createAction('api/data/update/request'),
            success: createAction('api/data/update/success'),
            failure: createAction('api/data/update/failure'),
        },
        // Complete update of the data: fetch the backend for the whole dataset at the given endpoint, and update local
        // data on success.
        fetch: {
            request: createAction('api/data/fetch/request'),
            success: createAction('api/data/fetch/success'),
            failure: createAction('api/data/fetch/failure'),
        },

        // Query the backend to remove a given entry referenced by an `id`
        remove: {
            request: createAction('api/data/remove/request'),
            success: createAction('api/data/remove/success'),
            failure: createAction('api/data/remove/failure'),
        },

        // Query the backend to update a given entry referenced by an `id`
        put: {
            request: createAction('api/data/put/request'),
            success: createAction('api/data/put/success'),
            failure: createAction('api/data/put/failure'),
        },

        post: {
            request: createAction('api/data/post/request'),
            success: createAction('api/data/post/success'),
            failure: createAction('api/data/post/failure'),
        },
    },
    processedData: {
        fetch: {
            request: createAction('api/processedData/fetch/request'),
            success: createAction('api/processedData/fetch/success'),
            failure: createAction('api/processedData/fetch/failure'),
        },
        update: {
            request: createAction('api/processedData/update/request'),
            success: createAction('api/processedData/update/success'),
            failure: createAction('api/processedData/update/failure'),
        },
        reset: createAction('api/processedData/reset'),
    },
    qbuilder: {
        doc: {
            request: createAction('api/qbuilder/doc/request'),
            success: createAction('api/qbuilder/doc/success'),
            failure: createAction('api/qbuilder/doc/failure'),
        },
        build: {
            request: createAction('api/qbuilder/build/request'),
            success: createAction('api/qbuilder/build/success'),
            failure: createAction('api/qbuilder/build/failure'),
        },
        check: {
            request: createAction('api/qbuilder/check/request'),
            success: createAction('api/qbuilder/check/success'),
            failure: createAction('api/qbuilder/check/failure'),
        },
        rerun: {
            request: createAction('api/data/qbuilder/rerun/request'),
            success: createAction('api/data/qbuilder/rerun/success'),
            failure: createAction('api/data/qbuilder/rerun/failure'),
        },
    },
    stats: {
        fetch: {
            request: createAction('api/stats/fetch/request'),
            success: createAction('api/stats/fetch/success'),
            failure: createAction('api/stats/fetch/failure'),
        },
        reset: createAction('api/stats/reset'),
    },
};
