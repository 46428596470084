import { all, put, takeEvery } from '@redux-saga/core/effects';

import { actions } from '../../actions';

function* notifAuthSuccess(action) {
    yield put(actions.notification.add.success(`Successfully connected as ${action.payload.username}`));
}

function* notifAuthFailure(action) {
    const response = action.payload.response;
    let errorMsg = 'Unable to login: ';

    if (response && response.status === 401) errorMsg += 'bad credentials';
    else errorMsg += action.payload.message;

    yield put(actions.notification.add.failure(errorMsg));
}

export default function* watchNotifAuth() {
    yield all([
        yield takeEvery(actions.api.auth.fetch.failure.toString(), notifAuthFailure),
        yield takeEvery(actions.api.auth.fetch.success.toString(), notifAuthSuccess),
    ]);
}
