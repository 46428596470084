import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { actions } from '../../../../../actions';
import { api } from '../../../../../services';
import ProjectDetailsPanel from '../../../ProjectDetails';
import { getPanelUri } from '../../../index';
import ProjectPanel from '../../index';
import ProjectForm from './ProjectForm';

const ProjectCreationPanel = (props) => {
    const { createProject, projectCreated, resetProjectCreated, toastSuccess, project, isProcessing } = props;
    const navigate = useNavigate();
    const [submitted, setSubmitted] = useState(false);

    useEffect(() => {
        resetProjectCreated();
    }, [resetProjectCreated]);

    useEffect(() => {
        if (projectCreated && submitted) {
            setSubmitted(false);
            resetProjectCreated();
            if (!project?.id) {
                navigate(getPanelUri(<ProjectPanel />));
            } else {
                navigate(getPanelUri(<ProjectDetailsPanel />, { id: project.id }));
            }
            toastSuccess('Project created successfully with id: ' + project?.id);
        }
    }, [projectCreated, resetProjectCreated, navigate, toastSuccess, submitted]); // eslint-disable-line

    const handleSubmit = (name, project_query, development_query, config) => {
        createProject(name, project_query, development_query, config);
        setSubmitted(true);
    };
    return <ProjectForm submitProject={handleSubmit} isLoading={isProcessing} />;
};

const mapStateToProps = (state) => {
    return {
        projectCreated: state.data[api.endpoints.dataProject]?.data?.status === 'Success',
        project: state.data[api.endpoints.dataProject]?.data,
        isProcessing: state.data[api.endpoints.dataProject]?.isPosting || false,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        createProject: (name, project_query, development_query, config) =>
            dispatch(
                actions.api.data.post.request({
                    endpoint: api.endpoints.dataProject,
                    data: {
                        name: name,
                        project_query: project_query,
                        development_query: development_query,
                        config: config,
                    },
                }),
            ),
        resetProjectCreated: () => dispatch(actions.api.data.reset({ endpoint: api.endpoints.dataProject })),
        toastSuccess: (message) => dispatch(actions.notification.add.success(message)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectCreationPanel);
