import * as icons from '@mui/icons-material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AppBar from '@mui/material/AppBar';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { actions } from '../../actions';
import BackendStatus from '../../components/BackendStatus';
import LightSwitch from '../../components/LightSwitch';
import { getPanelUri } from '../panels';
import EventPanel from '../panels/Events';

function NavBar(props) {
    const theme = useTheme();

    const {
        isOpen,
        onToggle,
        apiResponseTime,
        apiIsCheckingHealth,
        apiCheckBackendHealth,
        dataResponseTime,
        dataIsCheckingHealth,
        dataCheckBackendHealth,
        refreshInterval,
        nbNotifications,
    } = props;

    const navigate = useNavigate();
    const location = useLocation();

    const onNotificationsClick = () => {
        const newState = {
            status: 'notif',
            type: 'all',
            page: 1,
            start_date: 'null',
            end_date: 'null',
            warning_code: '',
            user: '',
            search: '',
        };

        const panelUri = getPanelUri(<EventPanel />, {}, newState);

        if (location.pathname === getPanelUri(<EventPanel />)) {
            navigate(panelUri, {
                replace: true,
                state: { forceReload: Date.now(), ...newState },
            });
        } else {
            navigate(panelUri, { state: newState });
        }
    };

    const sxAppBar = () => {
        return {
            ...{
                zIndex: theme.zIndex.drawer + 1,
                transition: theme.transitions.create(['width', 'margin'], {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
            },
        };
    };

    const sxIconButton = () => {
        return {
            marginRight: 2,
        };
    };

    return (
        <AppBar position='fixed' sx={sxAppBar()}>
            <Toolbar>
                <IconButton color='inherit' onClick={onToggle} edge='start' sx={sxIconButton()}>
                    {isOpen ? <icons.ChevronLeftRounded /> : <icons.Menu />}
                </IconButton>
                <Typography variant='h6' noWrap>
                    Odin DB
                </Typography>
                <Box sx={{ flexGrow: 1 }} />
                <Box>
                    <IconButton color='inherit' onClick={onNotificationsClick} data-testid={'NavbarNotificationsIcon'}>
                        <Badge badgeContent={nbNotifications} color='error'>
                            <NotificationsIcon />
                        </Badge>
                    </IconButton>
                    <BackendStatus
                        title={'CORE'}
                        isCheckingHealth={apiIsCheckingHealth}
                        checkBackendHealth={apiCheckBackendHealth}
                        responseTime={apiResponseTime}
                        refreshInterval={refreshInterval}
                    />
                    <BackendStatus
                        title={'DATA'}
                        isCheckingHealth={dataIsCheckingHealth}
                        checkBackendHealth={dataCheckBackendHealth}
                        responseTime={dataResponseTime}
                        refreshInterval={refreshInterval}
                    />
                    <LightSwitch />
                </Box>
            </Toolbar>
        </AppBar>
    );
}

NavBar.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onToggle: PropTypes.func.isRequired,
    apiResponseTime: PropTypes.number,
    apiIsCheckingHealth: PropTypes.bool,
    apiCheckBackendHealth: PropTypes.func,
    dataResponseTime: PropTypes.number,
    dataIsCheckingHealth: PropTypes.bool,
    dataCheckBackendHealth: PropTypes.func,
    refreshInterval: PropTypes.number,
    nbNotifications: PropTypes.number,
};

const mapStateToProps = (state) => {
    return {
        apiResponseTime: state.health.responseTime,
        apiIsCheckingHealth: state.health.isFetching,
        nbNotifications: state.health.notif,
        dataResponseTime: state.data_health.responseTime,
        dataIsCheckingHealth: state.data_health.isFetching,
        refreshInterval: state.preference.refreshDelay.health,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        apiCheckBackendHealth: () => dispatch(actions.api.health.request()),
        dataCheckBackendHealth: () => dispatch(actions.api.data_health.request()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
