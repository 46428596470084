import { createReducer } from '@reduxjs/toolkit';

import { actions } from '../actions';

const initialState = {
    datasetId: null,
};

export const details = createReducer(initialState, {
    [actions.dataset.details.set]: (state, action) => ({
        ...state,
        datasetId: action.payload,
    }),
    [actions.dataset.details.reset]: (state) => ({
        ...state,
        ...initialState,
    }),
});
