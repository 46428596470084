import { createReducer, current } from '@reduxjs/toolkit';

import { actions } from '../../actions';
import { api } from '../../services/api';
import { processAnnList } from './modules/annList';
import { processAnnProcess } from './modules/annProcess';
import { processCelery } from './modules/celery';
import { processDsComplete } from './modules/dsComplete';
import { processDsCount } from './modules/dsCount';
import { processDsTasks } from './modules/dsTasks';

const initialEndpointState = {
    errorOnFetch: null,
    errorOnUpdate: null,
    lastUpdate: null,
    isFetching: false,
    isUpdating: false,
};

const initialState = {};

const processFunctionMap = {
    [api.endpoints.dsTasks]: processDsTasks,
    [api.endpoints.celery]: processCelery,
    [api.endpoints.dsComplete]: processDsComplete,
    [api.endpoints.dsCount]: processDsCount,
    [api.endpoints.annList]: processAnnList,
    [api.endpoints.annProcess]: processAnnProcess,
};

export const processedData = createReducer(initialState, {
    [actions.api.processedData.fetch.success]: (state, action) => {
        let addData = {};
        if (action.payload.data) {
            addData = processFunctionMap[action.payload.endpoint]
                ? processFunctionMap[action.payload.endpoint](state, action, action.payload.data)
                : {};
        }

        return {
            ...state,
            [action.payload.endpoint]: {
                ...initialEndpointState,
                ...state[action.payload.endpoint],
                data: action.payload.data,
                ...addData,
                isFetching: false,
                lastUpdate: Date.now(),
            },
        };
    },

    [actions.api.processedData.fetch.failure]: (state, action) => ({
        ...state,
        [action.payload.endpoint]: {
            ...initialEndpointState,
            ...state[action.payload.endpoint],
            errorOnFetch: true,
            isFetching: false,
        },
    }),

    [actions.api.processedData.update.success]: (state, action) => {
        const dsToUpdate = new Set(action.payload.data.map((ds) => ds.id));
        const stateEndpointData = current(state)[action.payload.endpoint]?.data || [];

        const addData = processFunctionMap[action.payload.endpoint]
            ? processFunctionMap[action.payload.endpoint](state, action, [
                  ...stateEndpointData.filter((ds) => !dsToUpdate.has(ds.id)),
                  ...action.payload.data,
              ])
            : {};
        return {
            ...state,
            [action.payload.endpoint]: {
                ...initialEndpointState,
                data: action.payload.data,
                ...addData,
                isUpdating: false,
                lastUpdate: Date.now(),
            },
        };
    },

    [actions.api.processedData.update.failure]: (state, action) => ({
        ...state,
        [action.payload.endpoint]: {
            ...initialEndpointState,
            ...state[action.payload.endpoint],
            errorOnUpdate: true,
            isUpdating: false,
        },
    }),

    [actions.api.processedData.update.request]: (state, action) => ({
        ...state,
        [action.payload.endpoint]: {
            ...initialEndpointState,
            ...state[action.payload.endpoint],
            isUpdating: true,
        },
    }),

    [actions.api.processedData.fetch.request]: (state, action) => ({
        ...state,
        [action.payload.endpoint]: {
            ...initialEndpointState,
            ...state[action.payload.endpoint],
            isFetching: true,
        },
    }),

    [actions.api.processedData.reset]: (state, action) => ({
        ...state,
        [action.payload.endpoint]: {
            ...initialEndpointState,
        },
    }),
});
