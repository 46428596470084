import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { actions } from '../../../actions';
import { api } from '../../../services';
import { useInterval } from '../../../services/helpers';
import EnhancedTable from './components/EnhancedTable';

function DataExplorerPanel(props) {
    const {
        annotationList,
        isLoading,
        annotationFetch,
        annotationRemove,
        annotationRestore,
        isRemoving,
        isRestoring,
        refreshDelayFetchPending,
        annVcRestoreUpdatingIds,
        annotationProcessing,
        annotationProcess,
        annotationFetchActive,
        everyAnnotationAvailable,
        forceAnnotationFetch,
    } = props;
    const [annotationListLocal, setAnnotationListLocal] = useState([]);

    useEffect(() => {
        annotationFetchActive();
        forceAnnotationFetch();
    }, [annotationFetchActive, forceAnnotationFetch]);

    useEffect(() => {
        if (!isRemoving && !isRestoring) {
            annotationFetch();
        }
    }, [isRemoving, isRestoring, annotationFetch]);

    useEffect(() => {
        setAnnotationListLocal(annotationList);
    }, [annotationList]);

    useInterval(() => {
        annotationFetch();
        annotationProcess();
    }, refreshDelayFetchPending);

    return (
        <EnhancedTable
            rows={annotationListLocal}
            isLoading={isLoading}
            onAnnotationRemove={annotationRemove}
            onAnnotationRestore={annotationRestore}
            updatingIds={annVcRestoreUpdatingIds}
            annotationProcessing={annotationProcessing}
            everyAnnotationAvailable={everyAnnotationAvailable}
        />
    );
}

const mapStateToProps = (state) => {
    return {
        annotationList: state.processedData[api.endpoints.annList]?.mergedData || [],
        everyAnnotationAvailable: state.processedData[api.endpoints.annList]?.everything || undefined,
        annotationProcessing: (state.processedData[api.endpoints.annProcess] || {}).data || [],
        isLoading:
            state.processedData[api.endpoints.annList]?.isFetching ||
            state.processedData[api.endpoints.annList]?.isUpdating ||
            state.data[api.endpoints.annList]?.isFetching ||
            state.data[api.endpoints.annList]?.isUpdating ||
            false,
        isRemoving: state.data[api.endpoints.annList]?.isRemoving || false,
        isRestoring: state.data[api.endpoints.annVcRestore]?.isUpdating || false,
        refreshDelayFetchPending: state.preference.refreshDelay.datasets.fetchPending,
        annVcRestoreUpdatingIds: state.data[api.endpoints.annVcRestore]?.updatingIds || [],
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        annotationFetch: () => dispatch(actions.api.data.fetch.request({ endpoint: api.endpoints.annList })),
        forceAnnotationFetch: () =>
            dispatch(
                actions.api.data.fetch.request({
                    endpoint: api.endpoints.annList,
                    force: true,
                }),
            ),
        annotationFetchActive: () =>
            dispatch(
                actions.api.data.fetch.request({
                    endpoint: api.endpoints.annList,
                    active: true,
                    force: true,
                }),
            ),
        annotationRemove: (hash) =>
            dispatch(
                actions.api.data.remove.request({
                    endpoint: api.endpoints.annRemove,
                    id: hash,
                }),
            ),
        annotationRestore: (hash) =>
            dispatch(
                actions.api.data.put.request({
                    endpoint: api.endpoints.annVcRestore,
                    id: hash,
                }),
            ),
        annotationProcess: () => dispatch(actions.api.data.fetch.request({ endpoint: api.endpoints.annProcess })),
    };
};
DataExplorerPanel.propTypes = {};

export default connect(mapStateToProps, mapDispatchToProps)(DataExplorerPanel);
