export const processDsComplete = (state, action, data) => {
    return {
        data: data,
        countMapping: Object.fromEntries(new Map(data.map((obj) => [obj.id, obj.count]))),
        uptodateMapping: Object.fromEntries(
            new Map(
                data.map((obj) => [
                    obj.id,
                    {
                        count: obj.count,
                        up_to_date: obj.up_to_date,
                    },
                ]),
            ),
        ),
        isFinished: data.every((obj) => obj?.is_finished && obj?.count !== null),
    };
};
