import { createReducer } from '@reduxjs/toolkit';

import { actions } from '../actions';

const initialState = {
    stats: [{}],
    type: undefined,
    error: true,
};

function statsSuccess(state, action) {
    const data = action.payload?.stats?.data || {};
    const type = action.payload?.stats?.type || undefined;
    return {
        ...state,
        stats: data,
        type: type,
        error: !data,
    };
}

function statsFailure(state, action) {
    return { ...state, error: true };
}

export const stats = createReducer(initialState, {
    [actions.api.stats.fetch.success]: statsSuccess,
    [actions.api.stats.fetch.failure]: statsFailure,
    [actions.api.stats.reset]: () => initialState,
});
