import * as icons from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

import ConfirmButton from './ConfirmButton';

function ConfirmDeleteButton(props) {
    const { onClick, elementTitle, disabled } = props;

    return (
        <ConfirmButton
            onClick={onClick}
            title='Are you sure?'
            content={`If you delete this ${elementTitle}, you will never be able to access it again. Forever. (That's a long time!)`}
            disabled={disabled}
        >
            <Tooltip title={`Delete this ${elementTitle}`}>
                <icons.Delete color='error' />
            </Tooltip>
        </ConfirmButton>
    );
}

ConfirmDeleteButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    elementTitle: PropTypes.string.isRequired,
};

export default ConfirmDeleteButton;
