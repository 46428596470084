import { useTheme } from '@emotion/react';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { actions } from '../../actions';
import BodyBuilderGrid from './BodyBuilderGrid';
import ValidationButton from './ValidationButton';

function LStrSelect(props) {
    const { onChange, doc, endpoints, defaultValue, nodeId, setValidation, disableValidation } = props;
    const { endpoint, key_label } = doc;
    const theme = useTheme();

    const [value, setValue] = useState('');
    const [updated, setUpdated] = useState(true);

    useEffect(() => {
        if (Array.isArray(defaultValue) && defaultValue.length === 1) {
            setValue(defaultValue[0]);
        }
    }, [defaultValue]);

    const handleValueChange = (event, newValue) => {
        const newValueLabel = typeof newValue === 'string' ? newValue : newValue?.[key_label] || '';
        setValue(newValueLabel);

        if (!disableValidation) setValidation({ nodeId, validate: false });
        setUpdated(true);
    };

    const handleValidationClick = () => {
        if (!value || value.trim() === '') {
            setUpdated(true);
            return;
        }

        if (!disableValidation) setValidation({ nodeId, validate: true });
        setUpdated(false);
        onChange([value]);
    };

    return (
        <BodyBuilderGrid>
            <Grid item>
                <Autocomplete
                    freeSolo
                    autoComplete
                    autoHighlight
                    value={value}
                    onChange={handleValueChange}
                    onInputChange={handleValueChange}
                    options={(endpoints[endpoint]?.data || []).map((item) => item[key_label] || item)}
                    style={{ width: 400 }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label='Available options'
                            fullWidth
                            variant='outlined'
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': typeof value === 'string' &&
                                        !value.trim() && {
                                            borderColor: theme.palette.error.main,
                                        },
                                },
                            }}
                        />
                    )}
                    data-testid='lstr-select'
                />
            </Grid>
            <Grid item>
                <ValidationButton onClick={handleValidationClick} updated={updated} />
            </Grid>
        </BodyBuilderGrid>
    );
}

LStrSelect.propTypes = {
    onChange: PropTypes.func.isRequired,
    doc: PropTypes.object.isRequired,
    endpoints: PropTypes.object.isRequired,
    nodeId: PropTypes.string.isRequired,
    defaultValue: PropTypes.array,
};

const mapStateToProps = (state) => ({
    endpoints: state.data,
});

const mapDispatchToProps = (dispatch) => ({
    setValidation: (...args) => dispatch(actions.qbuilder.validation.set(...args)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LStrSelect);
