export const getBaseStyles = (theme) => ({
    root: {
        width: '80%',
        margin: '0 auto',
    },
    title: {
        fontWeight: 'bold',
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(2),
        textAlign: 'center',
    },
    paper: {
        width: '100%',
        position: 'relative',
        boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2)',
        marginBottom: '4px',
    },
    eventContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '4px',
    },
    error: {
        color: theme.palette.events.error,
    },
    pending: {
        color: theme.palette.events.pending,
    },
    processing: {
        color: theme.palette.events.processing,
    },
    succeeded: {
        color: theme.palette.events.succeeded,
    },
    autoFixed: {
        color: theme.palette.events.autoFixed,
    },
    warning: {
        color: theme.palette.events.warning,
    },
    icon: {
        flexShrink: 0,
        margin: 'auto 4px',
        fontSize: '2rem',
        height: '100%',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
    },
    timestamp: {
        margin: 'auto 24px',
        textAlign: 'center',
        width: '100px',
        fontSize: '0.875rem',
    },
    status_message: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        margin: 'auto 4px',
        textAlign: 'center',
        width: '400px',
        fontSize: '0.875rem',
        [theme.breakpoints.down('xl')]: {
            width: '300px',
        },
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    id: {
        fontSize: '0.875rem',
        paddingLeft: '3px',
        margin: 'auto 4px',
        width: '4%',
        [theme.breakpoints.down('xl')]: {
            width: '64px',
        },
    },
    message: {
        fontSize: '0.875rem',
        margin: 'auto',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        textAlign: 'center',
        maxWidth: '400px',
        [theme.breakpoints.down('xl')]: {
            maxWidth: '300px',
        },
        [theme.breakpoints.down('lg')]: {
            display: 'none',
        },
    },
    user: {
        fontSize: '0.875rem',
        paddingLeft: '3px',
        margin: 'auto 4px',
        width: '10%',
        textAlign: 'center',
    },
    eventContainerHover: {
        backgroundColor: theme.palette.common.simplegray,
    },
    loop: {
        animation: 'spin 2s linear infinite',
        '@keyframes spin': {
            '0%': {
                transform: 'rotate(360deg)',
            },
            '100%': {
                transform: 'rotate(0deg)',
            },
        },
    },
    inactive: {
        backgroundColor: theme.palette.common.verylightgray,
    },
    center: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        marginTop: '4px',
    },
    nbResult: {
        position: 'absolute',
        top: '8px',
        left: '8px',
        color: theme.palette.text.secondary,
        fontStyle: 'italic',
        fontSize: '1rem',
    },
    reviewAll: {
        position: 'absolute',
        top: '-8px',
        right: '20px',
        color: theme.palette.text.secondary,
        fontSize: '14px',
        cursor: 'pointer',
        fontStyle: 'italic',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    separator: {
        height: '32px',
        margin: 'auto 12px',
        borderLeft: `1px solid ${theme.palette.divider}`,
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    status_hover: {
        whiteSpace: 'pre-wrap',
        wordBreak: 'break-word',
        margin: 0,
        maxHeight: '400px',
        maxWidth: '400px',
        overflow: 'auto',
        paddingRight: '10px',
        ...theme.typography.body2,
    },
});
