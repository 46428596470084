import CancelIcon from '@mui/icons-material/Cancel';
import SucceededIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CircleIcon from '@mui/icons-material/Circle';
import LoopIcon from '@mui/icons-material/Loop';
import NotificationsIcon from '@mui/icons-material/Notifications';
import PendingIcon from '@mui/icons-material/Schedule';
import WarningIcon from '@mui/icons-material/Warning';
import React from 'react';

export const NB_EVENTS = 20;
export const REFRESH_RATE = 10000;
export const WARNING_TARGET = 'The XML has been fixed and queued';
export const PAPER_MARGIN = 360;
export const ROW_HEIGHT = 50;
export const NOTIFICATION_ANIMATION_TIMEOUT = 1000 * 30;
export const DELAY_REFRESH_EVENTS_AFTER_TOOLTIP_CLOSE = 1000;
export const eventStatus = {
    SUCCESS: 'SUCCESS',
    PENDING: 'PENDING',
    STARTED: 'STARTED',
    WARNING: 'WARNING',
    FAILURE: 'FAILURE',
};

export const eventStatusButton = {
    ...eventStatus,
    ALL: 'ALL',
    AUTOFIXED: 'AUTOFIXED',
    NOTIF: 'NOTIF',
};

export const eventCodeSelection = [eventStatusButton.WARNING.toLowerCase(), eventStatusButton.NOTIF.toLowerCase()];
export const allStatusIcons = {
    [eventStatusButton.ALL]: <CircleIcon />,
    [eventStatusButton.PENDING]: <PendingIcon />,
    [eventStatusButton.STARTED]: <LoopIcon />,
    [eventStatusButton.SUCCESS]: <SucceededIcon />,
    [eventStatusButton.AUTOFIXED]: <CheckCircleOutlineIcon />,
    [eventStatusButton.WARNING]: <WarningIcon />,
    [eventStatusButton.FAILURE]: <CancelIcon />,
    [eventStatusButton.NOTIF]: <NotificationsIcon />,
};

export const eventTypes = {
    ANNOTATION: 'annotation',
    COGNIDOX_UPLOAD_FORM: 'cognidox_upload_form',
};
