import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import * as React from 'react';

export const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 15,
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor: 'transparent',
        borderRadius: 1,
        position: 'relative',
    },
    [`& .${stepConnectorClasses.line}:before`]: {
        content: '""',
        position: 'absolute',
        left: 0,
        right: 0,
        top: '50%',
        transform: 'translateY(-50%)',
        height: 3,
        borderRadius: 1,
        backgroundColor: theme.palette.common.lightgray,
        transition: 'background-color 1s',
    },
    [`&.${stepConnectorClasses.active} .${stepConnectorClasses.line}:before`]: {
        backgroundColor: theme.palette.primary.main,
    },
    [`&.${stepConnectorClasses.completed} .${stepConnectorClasses.line}:before`]: {
        backgroundColor: theme.palette.primary.main,
    },
    [`&.${stepConnectorClasses.skipped} .${stepConnectorClasses.line}:before`]: {
        backgroundColor: theme.palette.common.mediumlightgray,
    },
}));

export const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.common.lightgray,
    zIndex: 1,
    color: theme.palette.white.main,
    width: 30,
    height: 30,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'transform 0.3s',
    ...(ownerState.active && {
        backgroundImage: `linear-gradient(136deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 50%, ${theme.palette.primary.main} 100%)`,
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
        transform: 'scale(1.2)',
    }),
    ...(ownerState.completed && {
        backgroundImage: `linear-gradient(136deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 50%, ${theme.palette.primary.main} 100%)`,
        transform: 'scale(1.2)',
    }),
    ...(ownerState.skipped && {
        backgroundImage: `linear-gradient(136deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 50%, ${theme.palette.primary.main} 100%)`,
        transform: 'scale(1.2)',
        cursor: 'not-allowed',
    }),
}));

export function ColorlibStepIcon(props) {
    const { active, completed, skipped, className } = props;

    const getIcon = () => {
        if (skipped) {
            return <RemoveCircleOutlineIcon fontSize='small' data-testid='skippedIcon' />;
        } else if (completed) {
            return <CheckIcon fontSize='small' data-testid='completedIcon' />;
        } else {
            return <ClearIcon fontSize='small' data-testid='clearIcon' />;
        }
    };

    return (
        <ColorlibStepIconRoot ownerState={{ completed, active, skipped }} className={className}>
            {getIcon()}
        </ColorlibStepIconRoot>
    );
}

ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,

    /**
     * Whether this step is skipped.
     * @default false
     */
    skipped: PropTypes.bool,
};
