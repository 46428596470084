export const getStyles = (theme) => ({
    paper: {
        width: '100%',
        position: 'relative',
        boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2)',
        minWidth: '600px',
        marginBottom: '4px',
    },
    progress: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '400px',
    },
    barChartWrapper: {
        position: 'relative',
        paddingBottom: theme.spacing(2),
    },
    filterButton: {
        marginLeft: '10px',
        marginBottom: '10px',
    },
    chartBox: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
    },
    chart: {
        flex: '1',
    },
    listItems: {
        marginRight: '10px',
    },
    chartMargin: { top: 5, right: 30, left: 50, bottom: 5 },
    center: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
    },
    typography: {
        fontSize: '24px',
        fontWeight: 'bold',
    },
    title: {
        paddingTop: theme.spacing(2),
    },
    loading: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '40vh',
    },
    fullWidth: {
        width: '100%',
    },
    back: {
        transform: 'translateY(-50%)',
        borderRadius: '50%',
        zIndex: 10,
        width: '40px !important',
        height: '40px',
        padding: 0,
        '&:hover': {
            color: `${theme.palette.common.black} !important`,
            backgroundColor: `${theme.palette.common.lightgray} !important`,
        },
        position: 'absolute',
        left: '10px',
        top: '30px',
        marginTop: '0px',
    },
    mainContainer: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
    },
    chartContainer: {
        flex: 1,
        transition: 'flex 0.3s',
    },
});

export const getToolTipStyles = (theme) => ({
    tooltipTitle: {
        color: theme.palette.common.black,
        fontWeight: 'bold',
        margin: 0,
    },
    toolTipBody: {
        margin: 0,
    },
    tooltip: {
        backgroundColor: theme.palette.common.verylightgray,
        borderRadius: '4px',
        color: theme.palette.common.black,
        border: `2px solid ${theme.palette.common.lightgray}`,
        fontSize: '14px',
        fontWeight: 'bold',
        textAlign: 'center',
        padding: '8px',
    },
});

export const getMoreAttributeStyles = (theme) => ({
    panel: {
        width: '300px',
        transition: 'width 0.3s',
        overflowY: 'auto',
        marginTop: '0px',
        maxHeight: '500px',
        display: 'flex',
        flexDirection: 'column',
        overflowX: 'hidden',
    },
    panelHidden: {
        width: 0,
        overflow: 'hidden',
        transition: 'width 0.3s',
    },
    toggleButton: {
        transform: 'translateY(-50%)',
        position: 'absolute',
        top: '18px',
        right: '-20px',
        borderRadius: '50%',
        zIndex: 10,
        width: '40px',
        height: '40px',
        padding: 0,
        backgroundColor: theme.palette.common.simplegray,
        '&:hover': {
            color: `${theme.palette.common.black} !important`,
            backgroundColor: `${theme.palette.common.lightgray} !important`,
        },
    },
    panelHeader: {
        justifyContent: 'space-between',
        padding: '4px',
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
        overflow: 'hidden',
        marginLeft: '6px',
    },
    valueTitle: {
        fontSize: 16,
        fontWeight: 'bold',
        textAlign: 'center',
        transition: 'opacity 0.3s',
        flex: 1,
        height: '32px',
        overflow: 'hidden',
    },
    fullWidth: {
        width: '100%',
    },
    container: {
        position: 'relative',
        transition: 'flex 0.3s',
        marginLeft: '-1vw',
    },
    labelTypography: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        flexGrow: 1,
    },
    valueTypography: {
        flexShrink: 0,
        paddingLeft: '8px',
        fontSize: 10,
        overflow: 'hidden',
    },
    checkbox: {
        marginLeft: '16px',
    },
});

export const getGraphConfigStyles = (theme) => {
    return {
        center: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            padding: '8px 0',
        },
        buttonGroup: {
            '.MuiToggleButton-root': {
                border: 'none',
                '&:not(:first-of-type)': {
                    borderLeft: '1px solid',
                    borderColor: 'divider',
                },
            },
        },
    };
};

export const getAttributeSelectionStyles = (theme) => ({
    container: {
        width: '100%',
        padding: '8px',
    },
    icon: {
        marginRight: '8px',
    },
    centerGrid: {
        display: 'flex',
        justifyContent: 'center',
    },
});
