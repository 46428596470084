import { useTheme } from '@emotion/react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { actions } from '../../actions';
import BodyBuilderGrid from './BodyBuilderGrid';
import ValidationButton from './ValidationButton';

function LNumber(props) {
    const { onChange, defaultValue, nodeId, setValidation, disableValidation } = props;

    const [updated, setUpdated] = useState(true);
    const [value, setValue] = useState(0.0);
    const theme = useTheme();

    useEffect(() => {
        if (Array.isArray(defaultValue) && defaultValue.length === 1) setValue(defaultValue[0]);
    }, [setValue, defaultValue]);

    const handleValidationClick = () => {
        if (!disableValidation) setValidation({ nodeId, validate: true });
        setUpdated(false);
        onChange([value]);
    };

    const handleFloat = (event) => {
        if (!disableValidation) setValidation({ nodeId, validate: false });
        setValue(parseFloat(event.target.value));
        setUpdated(true);
    };

    return (
        <BodyBuilderGrid>
            <Grid item>
                <TextField
                    variant='outlined'
                    label='Value'
                    type='number'
                    onChange={handleFloat}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': isNaN(value) && {
                                borderColor: theme.palette.error.main,
                            },
                        },
                    }}
                />
            </Grid>
            <Grid item>
                <ValidationButton onClick={handleValidationClick} updated={updated} disabled={value === null} />
            </Grid>
        </BodyBuilderGrid>
    );
}

LNumber.propTypes = {
    onChange: PropTypes.func.isRequired,
    nodeId: PropTypes.string.isRequired,
    defaultValue: PropTypes.array,
};

const mapDispatchToProps = (dispatch) => {
    return {
        setValidation: (...args) => dispatch(actions.qbuilder.validation.set(...args)),
    };
};

export default connect(null, mapDispatchToProps)(LNumber);
