import { Add } from '@mui/icons-material';
import { CircularProgress, Tooltip } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import { useTheme } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { actions } from '../../actions';
import BodyBuilderGrid from './BodyBuilderGrid';
import ValidationButton from './ValidationButton';

function LAttributeListInput(props) {
    const { onChange, doc, endpoints, defaultValue, errorToast } = props;
    const { endpoint } = doc;

    const [updated, setUpdated] = useState(false);
    const [values, setValues] = useState(defaultValue || ['']);
    const [inputName, setInputName] = useState('');
    const [completion, setCompletion] = useState({});
    const theme = useTheme();

    useEffect(() => {
        const data = endpoints[endpoint].data;

        if (!data) return;

        setInputName(data?.params[0] || 'Attribute');
        setCompletion(data?.data);
    }, [endpoint, endpoints]);

    const handleValidationClick = () => {
        setUpdated(false);

        let newValues = [...values];
        newValues = newValues.filter((value) => value && value !== '');

        if (newValues.length === 0) {
            setUpdated(true);
            errorToast('Please select a value');
            setValues(['']);
            return;
        }

        setValues(newValues);
        onChange(newValues);
        setUpdated(false);
    };

    const handleInputChange = (index, event, newValue) => {
        const newValues = [...values];
        newValues[index] = newValue || event?.target?.value;

        setValues(newValues);
        setUpdated(true);
    };

    const handleAddClick = () => {
        setValues([...values, '']);
        setUpdated(true);
    };

    return (
        <Box sx={{ marginLeft: theme.spacing(3), marginRight: theme.spacing(3) }}>
            <BodyBuilderGrid>
                {values.map((input, index) => (
                    <Grid item key={index}>
                        <Tooltip title={values[index] && values[index].length > 20 ? values[index] : ''} arrow>
                            <Autocomplete
                                freeSolo
                                autoComplete
                                autoHighlight
                                value={values[index] || ''}
                                onChange={(event, newValue) => handleInputChange(index, event, newValue)}
                                onInputChange={(event, newInputValue) => handleInputChange(index, event, newInputValue)}
                                options={Object.keys(completion)}
                                renderOption={(props, option) => (
                                    <Tooltip
                                        title={option.length > 20 ? option : ''}
                                        arrow
                                        placement={'right'}
                                        key={option}
                                    >
                                        <li key={`${index}-${option}`} {...props}>
                                            {option}
                                        </li>
                                    </Tooltip>
                                )}
                                renderInput={(params) => (
                                    <Tooltip
                                        title={
                                            params?.inputProps?.value && params.inputProps.value.length > 15
                                                ? params.inputProps.value
                                                : ''
                                        }
                                        arrow
                                    >
                                        <Box sx={{ width: { xs: '100%', sm: 300, md: 150, lg: 200 } }} key={index}>
                                            <TextField
                                                {...params}
                                                variant='outlined'
                                                label={inputName}
                                                fullWidth
                                                sx={{
                                                    fontSize: '1rem',
                                                    '& .MuiOutlinedInput-root': {
                                                        '& fieldset': !values[index] && {
                                                            borderColor: theme.palette.error.main,
                                                        },
                                                    },
                                                }}
                                            />
                                        </Box>
                                    </Tooltip>
                                )}
                            />
                        </Tooltip>
                    </Grid>
                ))}
                {endpoints[endpoint].data ? (
                    <>
                        <Grid item>
                            <IconButton onClick={handleAddClick} aria-label='add' data-testid='plus-button'>
                                <Add />
                            </IconButton>
                        </Grid>
                        <Grid item>
                            <ValidationButton onClick={handleValidationClick} updated={updated} />
                        </Grid>
                    </>
                ) : (
                    <Grid item sx={{ marginTop: '16px' }}>
                        <CircularProgress size={24} />
                    </Grid>
                )}
            </BodyBuilderGrid>
        </Box>
    );
}

const mapStateToProps = (state) => ({
    endpoints: state.data,
});

const mapDispatchToProps = (dispatch) => {
    return {
        errorToast: (message) => dispatch(actions.notification.add.failure(message)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LAttributeListInput);
