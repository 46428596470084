import { Box, Switch, Tooltip as TooltipMui } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { capitalizeFirstLetter, isCheckedValuesType, validateAttribute } from './AttributeUtils';
import { getNextValue } from './AttributeUtils';

export const AttributeSwitch = (props) => {
    const { setType: setParentType, isLoading, setIsLoading, reset, absolute, color, values } = props;
    const [searchParams, setSearchParams] = useSearchParams();
    const [type, setType] = useState(validateAttribute(searchParams.get('type')));

    useEffect(() => {
        const newParams = { ...Object.fromEntries(searchParams.entries()) };
        newParams.type = type;
        setSearchParams(newParams, { replace: true });
    }, [type, setSearchParams, searchParams]);

    useEffect(() => {
        setParentType(type);
    }, [setParentType, type]);

    function handleChangeType() {
        if (isLoading) {
            return;
        }
        setIsLoading(true);
        if (reset) {
            reset();
        }

        setType(getNextValue(type));
    }

    const styles = useMemo(() => {
        return {
            box: {
                ...(absolute !== false && {
                    position: 'absolute',
                    left: 0,
                    top: 0,
                }),
            },
            switch: {
                ...(color && {
                    '& .MuiSwitch-thumb': {
                        backgroundColor: color + ' !important',
                    },
                    '& .Mui-checked+.MuiSwitch-track': {
                        backgroundColor: color + ' !important',
                    },
                }),
            },
        };
    }, [absolute, color]);

    return (
        <Box justifyContent='flex' alignItems='center' style={styles.box}>
            <TooltipMui title={capitalizeFirstLetter(type)}>
                <Switch checked={isCheckedValuesType(type, values)} onChange={handleChangeType} sx={styles.switch} />
            </TooltipMui>
        </Box>
    );
};
