import ClearIcon from '@mui/icons-material/Clear';
import {
    Box,
    CircularProgress,
    Paper,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow,
    Tooltip,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import moment from 'moment/moment';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { actions } from '../../../../actions';
import { generateHashFromData } from '../../../../services/utils/annotation';
import EnhancedTableHead from '../../../components/Table/EnhancedTableHead';
import EnhancedTableToolbar from '../../../components/Table/EnhancedTableToolbar';
import LoadingTable from '../../../components/Table/LoadingTable';
import { getPanelUri } from '../../../panels';
import { PermissionManager } from '../../../utils/Permissions/PermissionManager';
import { getSorting, stableSort } from '../../../utils/Table/Search';
import AnnotationDetails from '../../Annotation';
import AnnotationState from './AnnotationState';
import ManagementCell from './ManagementCell';

const headCells = [
    { id: 'video_hash', label: 'Video Hash', align: 'center' },
    { id: 'user', label: 'Author' },
    // {id: 'date_creation', label: 'Created', align: 'center'},
    { id: 'date_update', label: 'Uploaded', align: 'center' },
    { id: 'date_apply', label: 'Applied', align: 'center' },
    { id: 'active_version', label: 'Version', align: 'center' },
    { id: 'state', label: 'State', align: 'center' },
    { id: 'manage', label: 'Manage', align: 'center', staff: true },
];

const PAGE_HEADER_HEIGHT = 54;

function EnhancedTable(props) {
    const {
        rows,
        search,
        isLoading,
        setSearch,
        onAnnotationRemove,
        onAnnotationRestore,
        updatingIds,
        restorePerms,
        deletePerms,
        annotationProcessing,
        everyAnnotationAvailable,
    } = props;
    const theme = useTheme();

    const styles = useMemo(
        () => ({
            root: {
                width: '100%',
            },
            paper: {
                width: '100%',
                position: 'relative',
                boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2)',
                marginBottom: '4px',
            },
            tableContainer: {
                maxHeight: `calc(100vh - 364px + ${PAGE_HEADER_HEIGHT}px)`,
                overflowY: 'auto',
            },
            cell: {
                borderBottom: 'none',
            },
            editMode: {
                backgroundColor: theme.palette.common.simplegray,
            },
        }),
        [theme],
    );

    const classes = styles;
    const [allowDeleted, setAllowDeleted] = useState(false);
    const [editModeRow, setEditModeRow] = useState({});

    // Router
    const navigate = useNavigate();

    // Params for the table
    const validOrders = ['asc', 'desc'];
    const validOrderBy = headCells.map((cell) => cell.id);
    const validRowsPerPageOptions = [5, 10, 25];

    const validateOrder = (order) => (validOrders.includes(order) ? order : 'desc');
    const validateOrderBy = (orderBy) => (validOrderBy.includes(orderBy) ? orderBy : 'date_apply');
    const validateRowsPerPage = (rowsPerPage) => (validRowsPerPageOptions.includes(rowsPerPage) ? rowsPerPage : 5);
    const validatePage = (page, maxPage) => (page >= 0 && page <= maxPage ? page : 0);

    const [searchParams, setSearchParams] = useSearchParams();
    const [order, setOrder] = useState(validateOrder(searchParams.get('order')));
    const [orderBy, setOrderBy] = useState(validateOrderBy(searchParams.get('orderBy')));
    const [rowsPerPage, setRowsPerPage] = useState(validateRowsPerPage(parseInt(searchParams.get('rowsPerPage'), 10)));
    const [page, setPage] = useState(parseInt(searchParams.get('page') || 0, 10));
    const [rowHash, setRowHash] = useState(null);

    useEffect(
        () => {
            const currentHash = generateHashFromData(rows);
            if (currentHash !== rowHash) {
                setRowHash(currentHash);
            }
        },
        [rows, setRowHash], // eslint-disable-line react-hooks/exhaustive-deps
    );

    const filterR = useCallback(
        (row) => {
            const nb_version = row.versions?.length.toString() || undefined;
            return (
                row.video_hash?.toLocaleUpperCase().includes(search.toLocaleUpperCase()) ||
                row.user.toLocaleUpperCase().includes(search.toLocaleUpperCase()) ||
                moment(row.date_update)
                    .format('MMMM Do YYYY, hh:mm:ss')
                    .toLocaleUpperCase()
                    .includes(search.toLocaleUpperCase()) ||
                moment(row.date_apply)
                    .format('MMMM Do YYYY, hh:mm:ss')
                    .toLocaleUpperCase()
                    .includes(search.toLocaleUpperCase()) ||
                `${row.active_version}/${nb_version}`.toLocaleUpperCase().includes(search.toLocaleUpperCase())
            );
        },
        [search],
    );

    // Filter
    const filterRows = useMemo(
        () => {
            return stableSort(
                rows.filter((row) => allowDeleted || !row.is_deleted).filter(filterR),
                getSorting(order, orderBy),
            );
        },
        [allowDeleted, search, orderBy, order, rowHash], // eslint-disable-line react-hooks/exhaustive-deps
    );

    const currentPage = useMemo(
        () => filterRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
        [filterRows, page, rowsPerPage],
    );

    useEffect(() => {
        if (search) {
            setPage(0);
        }
    }, [search]);

    useEffect(() => {
        if (!filterRows.length) return;

        const maxPage = Math.ceil(filterRows.length / rowsPerPage) - 1;
        const newPage = validatePage(page, maxPage);

        if (newPage !== page) {
            setPage(newPage);
        }
    }, [filterRows.length, rowsPerPage, setPage, page]);

    useEffect(() => {
        const newParams = { ...Object.fromEntries(searchParams.entries()) };
        newParams.order = order;
        newParams.orderBy = orderBy;
        newParams.page = page.toString();
        newParams.rowsPerPage = rowsPerPage.toString();
        setSearchParams(newParams, { replace: true });
    }, [order, orderBy, page, rowsPerPage, setSearchParams, searchParams]);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const handleAllowDeletedChange = async () => {
        setAllowDeleted(!allowDeleted);
    };

    const openAnnotation = useCallback(
        (hash) => {
            const uri = getPanelUri(<AnnotationDetails />, { hash: hash });
            navigate(uri);
        },
        [navigate],
    );

    const renderTableRows = useMemo(
        () =>
            currentPage.map((row, index) => {
                let editMode = false;
                let versionIndex = undefined;
                let currentVersion = null;
                let isUpdating = false;

                if (everyAnnotationAvailable && row?.versions) {
                    editMode = editModeRow[row?.video_hash] !== undefined;
                    versionIndex = editMode
                        ? editModeRow[row?.video_hash].active_version_index
                        : row?.active_version_index;
                    currentVersion = row?.versions[versionIndex] || row?.versions[0];

                    for (const version of row?.versions) {
                        if (updatingIds.includes(version?.id)) {
                            isUpdating = true;
                            break;
                        }
                    }
                    if (!currentVersion) {
                        return null;
                    }
                } else {
                    currentVersion = row;
                    if (updatingIds.includes(currentVersion?.id)) {
                        isUpdating = true;
                    }
                }

                return (
                    <TableRow hover tabIndex={index} key={currentVersion?.id} style={editMode ? classes.editMode : {}}>
                        <TableCell
                            align='center'
                            sx={{ ...classes.cell, cursor: 'pointer' }}
                            component='th'
                            scope='row'
                            onClick={() => openAnnotation(row?.video_hash)}
                        >
                            <Tooltip title={row?.video_hash.toLocaleUpperCase()} placement='top-start'>
                                <span>
                                    {row?.video_hash.substring(0, 20).toLocaleUpperCase()}
                                    ...
                                </span>
                            </Tooltip>
                        </TableCell>
                        <TableCell align='left' sx={classes.cell}>
                            {currentVersion?.user}
                        </TableCell>
                        <TableCell align='center' sx={classes.cell}>
                            {moment(currentVersion?.date_update).format('MMMM Do YYYY, hh:mm:ss')}
                        </TableCell>
                        <TableCell align='center' sx={classes.cell}>
                            {moment(currentVersion?.date_apply).format('MMMM Do YYYY, hh:mm:ss')}
                        </TableCell>
                        <TableCell align='center' sx={classes.cell}>
                            {isUpdating || annotationProcessing.includes(`annotation_${row.video_hash}`) ? (
                                <CircularProgress size={24} color='warning' />
                            ) : everyAnnotationAvailable && currentVersion?.version ? (
                                currentVersion?.version + '/' + row?.nb_versions
                            ) : (
                                <CircularProgress color='primary' size={24} />
                            )}
                        </TableCell>
                        <TableCell align='center' sx={classes.cell}>
                            {row?.is_deleted && !editMode ? (
                                <Tooltip title={'Annotation deleted'}>
                                    <ClearIcon color='error' />
                                </Tooltip>
                            ) : (
                                <AnnotationState state={currentVersion?.state} />
                            )}
                        </TableCell>
                        <ManagementCell
                            row={row}
                            editMode={editMode}
                            editModeRow={editModeRow}
                            versionIndex={versionIndex}
                            isUpdating={isUpdating}
                            currentVersion={currentVersion}
                            onAnnotationRemove={onAnnotationRemove}
                            restorePerms={restorePerms}
                            deletePerms={deletePerms}
                            onAnnotationRestore={onAnnotationRestore}
                            setEditModeRow={setEditModeRow}
                            isLoading={!everyAnnotationAvailable}
                        />
                    </TableRow>
                );
            }),
        [
            currentPage,
            classes,
            editModeRow,
            everyAnnotationAvailable,
            updatingIds,
            annotationProcessing,
            deletePerms,
            onAnnotationRemove,
            onAnnotationRestore,
            restorePerms,
            openAnnotation,
        ],
    );

    return (
        <Box sx={classes.root} data-testid={'tasks-table'}>
            <Paper sx={classes.paper}>
                <div>
                    <EnhancedTableToolbar isLoading={isLoading} title='Annotations' setSearch={setSearch} />

                    <Box justifyContent='flex' alignItems='center' style={{ position: 'absolute', left: 0, top: 0 }}>
                        {everyAnnotationAvailable ? (
                            <Tooltip title={'Displays deleted annotations'}>
                                <Switch
                                    checked={allowDeleted}
                                    onChange={handleAllowDeletedChange}
                                    data-testid={'show-deleted'}
                                />
                            </Tooltip>
                        ) : (
                            <CircularProgress sx={{ margin: '12px' }} color='primary' size={24} />
                        )}
                    </Box>
                    <TableContainer sx={styles.tableContainer}>
                        <Table stickyHeader aria-label='sticky table'>
                            <EnhancedTableHead
                                classes={classes}
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                                rowCount={filterRows.length}
                                staff={restorePerms || deletePerms}
                                headCells={headCells}
                            />

                            {filterRows && filterRows.length === 0 ? (
                                <LoadingTable
                                    isLoading={isLoading || (filterRows.length === 0 && search === '')}
                                    message={'No data matches the search criteria.'}
                                />
                            ) : (
                                <TableBody>{renderTableRows}</TableBody>
                            )}
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={validRowsPerPageOptions}
                        component='div'
                        count={filterRows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        showFirstButton
                        showLastButton
                    />
                </div>
            </Paper>
        </Box>
    );
}

const mapStateToProps = (state) => {
    return {
        search: state.annotation.search,
        restorePerms:
            state.auth?.user?.perms?.[PermissionManager.restoreAnnotationPerm] || state.auth?.user?.staff || false,
        deletePerms:
            state.auth?.user?.perms?.[PermissionManager.deleteAnnotationPerm] || state.auth?.user?.staff || false,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setSearch: (text) =>
            dispatch(
                actions.annotation.search({
                    search: text,
                }),
            ),
    };
};

EnhancedTable.propTypes = {
    rows: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(EnhancedTable);
