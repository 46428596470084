// import * as icons from "@mui/icons-material";
import { Build, Home, Storage } from '@mui/icons-material';
import AddBox from '@mui/icons-material/AddBox';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import AssessmentIcon from '@mui/icons-material/Assessment';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ChromeReaderModeIcon from '@mui/icons-material/ChromeReaderMode';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DatasetIcon from '@mui/icons-material/Dataset';
import FilterListIcon from '@mui/icons-material/FilterList';
import InfoIcon from '@mui/icons-material/Info';
import NotificationsIcon from '@mui/icons-material/Notifications';
import SavedSearchIcon from '@mui/icons-material/SavedSearch';
import React from 'react';

import { PermissionManager } from '../utils/Permissions/PermissionManager';
import AnnotationDetails from './Annotation';
import AnnotationFilterPanel from './AnnotationFilter';
import AnnotationSearchPanel from './AnnotationSearch';
import DataExplorerPanel from './DataExplorer';
import DataSetsPanel from './DataSets';
import DataSetsDetails from './Details';
import EventPanel from './Events';
import HomePanel from './Home';
import Information from './Information';
import MetricLabelsPanel from './MetricLabels';
import MetricSegmentsPanel from './MetricSegments';
import ProjectDetailsPanel from './ProjectDetails';
import ProjectPanel from './Projects';
import ProjectCreationPanel from './Projects/panels/ProjectCreation/ProjectCreationPanel';
import ProjectEditionPanel from './Projects/panels/ProjectCreation/ProjectEditPanel';
import QBuilderPanel from './QBuilder';

export const panels = [
    {
        name: 'Home',
        uri: '/',
        icon: <Home />,
        component: <HomePanel />,
    },
    {
        separator: true,
        name: 'Label Metrics',
        uri: '/metric/labels',
        icon: <AnalyticsIcon />,
        component: <MetricLabelsPanel />,
    },
    {
        name: 'Segment Metrics',
        uri: '/metric/segments',
        icon: <AssessmentIcon />,
        component: <MetricSegmentsPanel />,
    },
    {
        separator: true,
        name: 'Snapshots',
        uri: '/snapshots',
        icon: <Storage />,
        component: <DataSetsPanel />,
    },
    {
        name: 'Snapshot Builder',
        uri: '/builder',
        icon: <Build />,
        component: <QBuilderPanel />,
    },
    {
        name: 'Snapshot Details',
        uri: '/snapshots/:id',
        icon: <ChromeReaderModeIcon />,
        component: <DataSetsDetails />,
    },
    {
        separator: true,
        name: 'Annotations',
        uri: '/annotations',
        icon: <DatasetIcon />,
        component: <DataExplorerPanel />,
    },
    {
        name: 'Annotations Filter',
        uri: '/annotations/filter',
        icon: <FilterListIcon />,
        component: <AnnotationFilterPanel />,
    },
    {
        name: 'Annotations Search',
        uri: '/annotations/search',
        icon: <SavedSearchIcon />,
        component: <AnnotationSearchPanel />,
    },
    {
        name: 'Annotation Details',
        uri: '/annotations/:hash',
        icon: <AddBox />,
        component: <AnnotationDetails />,
    },
    {
        separator: true,
        name: 'Readiness',
        uri: '/readiness',
        icon: <DashboardIcon />,
        component: <ProjectPanel />,
        requiredPermission: PermissionManager.betaTesterPerm,
    },
    {
        name: 'Readiness Details',
        uri: '/readiness/:id',
        icon: <AssignmentIcon />,
        component: <ProjectDetailsPanel />,
        requiredPermission: PermissionManager.betaTesterPerm,
    },
    {
        separator: true,
        annotationProcessingEvents: true,
        name: 'Events',
        uri: '/events',
        icon: <NotificationsIcon />,
        component: <EventPanel />,
    },
    {
        separator: true,
        name: 'Information',
        uri: '/info',
        icon: <InfoIcon />,
        component: <Information />,
    },
];
export const hiddenPanels = [
    {
        uri: '/readiness/new',
        parent: 'Readiness',
        component: <ProjectCreationPanel />,
    },
    {
        uri: '/readiness/edit/:id',
        parent: 'Readiness',
        component: <ProjectEditionPanel />,
    },
];

const getPanelIndexByComponent = (component) => {
    return panels.findIndex((panel) => panel.component.type === component.type);
};

const getHiddenPanelIndexByComponent = (component) => {
    return hiddenPanels.findIndex((panel) => panel.component.type === component.type);
};

export const getPanelUri = (component, params = {}, extra = {}) => {
    let panelIndex = getPanelIndexByComponent(component);
    let uri = panels[panelIndex]?.uri;

    if (panelIndex === -1) {
        panelIndex = getHiddenPanelIndexByComponent(component);
        uri = hiddenPanels[panelIndex]?.uri;
    }

    if (!uri) return '';

    Object.keys(params).forEach((key) => {
        uri = uri.replace(`:${key}`, params[key]);
    });

    // Add parameters with ?key=value&key2=value2...
    const paramsKeys = Object.keys(extra);
    if (paramsKeys.length === 0) return uri;

    uri += '?';
    paramsKeys.forEach((key) => {
        uri += `${key}=${extra[key]}&`;
    });

    uri = uri.slice(0, -1);

    return uri;
};

export const getMaxNamePanelLength = () => {
    return panels.reduce((max, panel) => {
        return Math.max(max, panel.name.length);
    }, 0);
};

export const getHiddenPanelParent = (uri) => {
    const convertToRegex = (pattern) => {
        return pattern.replace(/:\w+/g, '[^/]+');
    };

    const parentName = hiddenPanels.find((panel) => {
        const regexPattern = new RegExp(`^${convertToRegex(panel.uri)}$`);
        return regexPattern.test(uri);
    })?.parent;

    return panels.find((panel) => panel.name === parentName);
};
