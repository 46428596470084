import { Box, IconButton, InputAdornment, TextField, styled } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import React from 'react';
import { connect } from 'react-redux';

import { actions } from '../../../../actions';

const StyledTextField = styled(TextField)(({ theme }) => ({
    width: 200,
    '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
    },
    '& .MuiInputBase-input': {
        color: theme.palette.text.primary,
    },
    fontSize: '0.875rem',
    padding: '4px 8px',
    '& .MuiInputLabel-root': {
        color: theme.palette.text.secondary,
    },
    '& .MuiInputLabel-outlined': {
        transform: 'translate(22px, 14px) scale(1)',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
        transform: 'translate(22px, 0px) scale(0.75)',
    },
    '& .MuiInputBase-root': {
        '> input': {
            marginTop: '6px',
        },
    },
}));

const DateRangePicker = (props) => {
    const { startDate, setStartDate, endDate, setEndDate, errorToast } = props;

    const convertDate = (date) => {
        if (!date) return null;

        const jsDate = date.toDate();
        const year = jsDate.getFullYear();
        const month = String(jsDate.getMonth() + 1).padStart(2, '0');
        const day = String(jsDate.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const handleClearStartDate = () => {
        setStartDate(null);
    };

    const handleClearEndDate = () => {
        setEndDate(null);
    };

    const handleSetStartDate = (newValue) => {
        if (newValue && (!newValue.isValid || !newValue.isValid())) return;

        const value = convertDate(newValue);
        if (endDate && value) {
            const endDateValue = new Date(endDate);
            const valueDate = new Date(value);

            if (valueDate >= endDateValue) {
                errorToast('Start date must be before end date');
                return;
            }
        }
        setStartDate(value);
    };

    const handleSetEndDate = (newValue) => {
        if (newValue && (!newValue.isValid || !newValue.isValid())) return;

        const value = convertDate(newValue);
        if (startDate && value) {
            const startDateValue = new Date(startDate);
            const valueDate = new Date(value);

            if (valueDate <= startDateValue) {
                errorToast('End date must be after start date');
                return;
            }
        }
        setEndDate(value);
    };

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <DatePicker
                label='From'
                value={startDate}
                inputFormat={'DD/MM/YYYY'}
                onChange={(newValue) => handleSetStartDate(newValue)}
                renderInput={(params) => (
                    <StyledTextField
                        {...params}
                        data-testid='startDate'
                        variant='outlined'
                        size='small'
                        error={false}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                    {startDate && (
                                        <InputAdornment position='end'>
                                            <IconButton
                                                onClick={handleClearStartDate}
                                                size='small'
                                                edge='end'
                                                sx={{
                                                    width: '32px',
                                                    height: '32px',
                                                    color: 'inherit',
                                                }}
                                            >
                                                &#10005;
                                            </IconButton>
                                        </InputAdornment>
                                    )}
                                    {params.InputProps.endAdornment}
                                </>
                            ),
                        }}
                    />
                )}
            />
            <DatePicker
                label='To'
                value={endDate}
                inputFormat={'DD/MM/YYYY'}
                onChange={(newValue) => handleSetEndDate(newValue)}
                renderInput={(params) => (
                    <StyledTextField
                        {...params}
                        data-testid='endDate'
                        variant='outlined'
                        size='small'
                        error={false}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                    {endDate && (
                                        <InputAdornment position='end'>
                                            <IconButton
                                                onClick={handleClearEndDate}
                                                size='small'
                                                edge='end'
                                                sx={{
                                                    width: '32px',
                                                    height: '32px',
                                                    color: 'inherit',
                                                }}
                                            >
                                                &#10005;
                                            </IconButton>
                                        </InputAdornment>
                                    )}
                                    {params.InputProps.endAdornment}
                                </>
                            ),
                        }}
                    />
                )}
            />
        </Box>
    );
};

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        errorToast: (message) => dispatch(actions.notification.add.failure(message)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DateRangePicker);
