import { all, put, takeLatest } from '@redux-saga/core/effects';

import { actions } from '../../actions';

function* processFetchSuccess(action) {
    const { endpoint, data } = action.payload;
    yield put(actions.api.processedData.fetch.success({ endpoint, data }));
}

function* processFetchFailure(action) {
    const { endpoint } = action.payload;
    yield put(actions.api.processedData.fetch.failure({ endpoint }));
}

function* processUpdateSuccess(action) {
    const { endpoint, data } = action.payload;
    yield put(actions.api.processedData.update.success({ endpoint, data }));
}

function* processUpdateFailure(action) {
    const { endpoint } = action.payload;
    yield put(actions.api.processedData.update.failure({ endpoint }));
}

function* processFetchRequest(action) {
    const { endpoint } = action.payload;
    yield put(actions.api.processedData.fetch.request({ endpoint }));
}

function* processUpdateRequest(action) {
    const { endpoint } = action.payload;
    yield put(actions.api.processedData.update.request({ endpoint }));
}

export default function* watchProcessedData() {
    yield all([
        yield takeLatest(actions.api.data.fetch.success.toString(), processFetchSuccess),
        yield takeLatest(actions.api.data.fetch.failure.toString(), processFetchFailure),
        yield takeLatest(actions.api.data.fetch.request.toString(), processFetchRequest),
        yield takeLatest(actions.api.data.update.success.toString(), processUpdateSuccess),
        yield takeLatest(actions.api.data.update.failure.toString(), processUpdateFailure),
        yield takeLatest(actions.api.data.update.request.toString(), processUpdateRequest),
    ]);
}
