import { Box, Switch, Tooltip as TooltipMui } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { getNextValue, isCheckedValuesType, validateBooleanAttribute } from './AttributeUtils';

export const AttributeMissingSwitch = (props) => {
    const { setType: setParentType, absolute, color, values } = props;
    const [searchParams, setSearchParams] = useSearchParams();
    const [missing, setMissing] = useState(validateBooleanAttribute(searchParams.get('missing'), values));

    useEffect(() => {
        const newParams = { ...Object.fromEntries(searchParams.entries()) };
        newParams.missing = missing;
        setSearchParams(newParams, { replace: true });
    }, [missing, setSearchParams, searchParams]);

    useEffect(() => {
        setParentType(missing);
    }, [setParentType, missing]);

    function handleChangeMissing() {
        setMissing(getNextValue(missing, values));
    }

    const styles = useMemo(() => {
        return {
            box: {
                ...(absolute !== false && {
                    position: 'absolute',
                    left: 0,
                    top: 0,
                }),
            },
            switch: {
                ...(color && {
                    '& .MuiSwitch-thumb': {
                        backgroundColor: color + ' !important',
                    },
                    '& .Mui-checked+.MuiSwitch-track': {
                        backgroundColor: color + ' !important',
                    },
                }),
            },
        };
    }, [absolute, color]);

    return (
        <Box justifyContent='flex' alignItems='center' style={styles.box}>
            <TooltipMui title='Show missing values'>
                <Switch
                    checked={isCheckedValuesType(missing, values)}
                    onChange={handleChangeMissing}
                    sx={styles.switch}
                />
            </TooltipMui>
        </Box>
    );
};
