import AssignmentIcon from '@mui/icons-material/Assignment';
import DownloadIcon from '@mui/icons-material/Download';
import ImageIcon from '@mui/icons-material/Image';
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';
import TimelineIcon from '@mui/icons-material/Timeline';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import { Box, ButtonGroup, CircularProgress, IconButton, Paper, Stack, Tooltip as TooltipMui } from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { connect } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Bar, BarChart, CartesianGrid, Cell, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { actions } from '../../../../actions';
import AnnotationFilterPanel from '../../../panels/AnnotationFilter';
import { getPanelUri } from '../../../panels/index';
import { AttributeMissingSwitch } from '../../Attributes/Switch/AttributeMissingSwitch';
import { AttributeSwitch } from '../../Attributes/Switch/AttributeSwitch';
import {
    MISSING,
    capitalizeFirstLetter,
    getDefaultAttr,
    sortOrders,
    validateFilter,
    validateSortOrder,
} from '../../Attributes/Switch/AttributeUtils';
import { StatsTableDropdown } from './StatsTableDropdown';

const StatsTable = (props) => {
    const theme = useTheme();

    const styles = {
        paper: {
            width: '100%',
            position: 'relative',
            boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2)',
            minWidth: '600px',
            marginBottom: '4px',
        },
        progress: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        barChartWrapper: {
            position: 'relative',
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
        },
        filterButton: {
            marginLeft: '10px',
            marginBottom: '10px',
        },
        chartBox: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-end',
            minHeight: '100px',
        },
        chart: {
            flex: '1',
        },
        listItems: {
            marginRight: '10px',
        },
        tooltip: {
            backgroundColor: theme.palette.common.verylightgray,
            borderRadius: '4px',
            color: theme.palette.common.black,
            border: `2px solid ${theme.palette.common.lightgray}`,
            fontSize: '14px',
            fontWeight: 'bold',
            textAlign: 'center',
            padding: '8px',
        },
        chartMargin: { top: 5, right: 30, left: 50, bottom: 5 },
        center: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
        },
        tooltipTitle: {
            color: theme.palette.common.black,
            fontWeight: 'bold',
            margin: 0,
        },
        toolTipBody: {
            margin: 0,
        },
        downloadButton: {
            color: 'inherit',
            textDecoration: 'none',
            textAlign: 'center',
            verticalAlign: 'middle',
        },
    };

    const { stats, statsFetch, selectedType } = props;
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [attr, setAttr] = useState(searchParams.get('attr') || '');
    const [filter, setFilter] = useState(validateFilter(searchParams.get('filter')));
    const [displayMissing, setDisplayMissing] = useState(true);
    const [type, setType] = useState(selectedType || '');
    const [sortOrder, setSortOrder] = useState(validateSortOrder(searchParams.get('sortOrder')));

    useEffect(() => {
        if (!type) {
            return;
        }
        statsFetch(filter, type);
    }, [statsFetch, filter, type]);

    useEffect(() => {
        if (stats.error || !stats.stats) {
            return;
        }

        if (selectedType && stats?.type !== selectedType) {
            return;
        }

        const validAttrs = Object.keys(stats.stats);
        const validatedAttr = validAttrs.includes(attr) ? attr : getDefaultAttr(validAttrs);
        setAttr(validatedAttr);
        if (stats.stats[validatedAttr]) {
            const sortedData = [...stats.stats[validatedAttr]];
            if (sortOrder === sortOrders[0]) {
                // Sort by count in descending order
                sortedData.sort((a, b) => b.count - a.count);
            } else if (sortOrder === sortOrders[1]) {
                // Sort by _id lexicographically, considering numeric values as well
                sortedData.sort((a, b) =>
                    a._id.localeCompare(b._id, undefined, { numeric: true, sensitivity: 'base' }),
                );
            }
            const missingIndex = sortedData.findIndex((item) => item._id === MISSING);
            if (missingIndex !== -1) {
                if (displayMissing) {
                    sortedData.push(sortedData.splice(missingIndex, 1)[0]);
                } else {
                    sortedData.splice(missingIndex, 1);
                }
            }

            setIsLoading(false);
            setData(sortedData);
        }
    }, [stats, attr, displayMissing, selectedType, sortOrder]);

    useEffect(() => {
        const newParams = { ...Object.fromEntries(searchParams.entries()) };
        newParams.attr = attr;
        newParams.filter = filter;
        newParams.sortOrder = sortOrder;
        setSearchParams(newParams, { replace: true });
    }, [attr, filter, sortOrder, setSearchParams, searchParams]);

    const handleFilterChange = (newFilter) => {
        if (isLoading || newFilter === filter) {
            return;
        }

        setIsLoading(true);
        setFilter(newFilter);
    };

    const handleChartClick = (event) => {
        const value = event?.activeLabel;

        if (isLoading || !value) {
            return;
        }

        navigate(getPanelUri(<AnnotationFilterPanel />, {}, { name: attr, value, type }));
    };

    const handleSortOrderChange = () => {
        setSortOrder((prevOrder) => (prevOrder === sortOrders[0] ? sortOrders[1] : sortOrders[0]));
    };

    const CustomTooltip = ({ active, payload, label }) => {
        if (active) {
            const isLastBar = label === MISSING;
            const textStyle = {
                color: displayMissing && isLastBar ? theme.palette.missing.main : theme.palette.primary.main,
                ...styles.toolTipBody,
            };

            if (!payload || payload.length === 0) {
                return null;
            }

            return (
                <div className='custom-tooltip' style={styles.tooltip}>
                    <p className='label' style={styles.tooltipTitle}>
                        {label}
                    </p>
                    <p className='intro' style={textStyle}>{`${capitalizeFirstLetter(filter)}: ${payload[0].value}`}</p>
                </div>
            );
        }

        return null;
    };

    const processedData = data.map(({ _id, type, count }) => ({
        attribute: type,
        value: _id,
        [filter]: count,
    }));

    const currentDatetime = new Date().toISOString().replace(/[:.]/g, '-');

    return (
        <Paper
            sx={{
                ...styles.paper,
            }}
        >
            <Box sx={styles.barChartWrapper}>
                <AttributeMissingSwitch
                    setType={setDisplayMissing}
                    color={theme.palette.missing.main}
                    values={[true, false]}
                />
                <Box sx={{ position: 'absolute', right: 60, top: 12 }}>
                    <TooltipMui title={`${capitalizeFirstLetter(sortOrder)} order`} arrow={true} placement='bottom'>
                        <IconButton
                            sx={{ width: '40px', height: '40px' }}
                            onClick={handleSortOrderChange}
                            disabled={isLoading}
                        >
                            {sortOrder === sortOrders[0] ? <TrendingDownIcon /> : <SortByAlphaIcon />}
                        </IconButton>
                    </TooltipMui>
                </Box>
                <Box sx={{ position: 'absolute', right: 12, top: 12 }}>
                    <TooltipMui title='Download CSV'>
                        <IconButton sx={{ width: '40px', height: '40px' }} disabled={isLoading}>
                            <CSVLink
                                data={processedData}
                                headers={[
                                    { label: 'Attribute', key: 'attribute' },
                                    { label: 'Value', key: 'value' },
                                    { label: capitalizeFirstLetter(filter), key: filter },
                                ]}
                                filename={`${selectedType}_stats_data_${attr}_${filter}_${currentDatetime}.csv`}
                                style={styles.downloadButton}
                            >
                                <DownloadIcon sx={{ marginTop: '5px' }} />
                            </CSVLink>
                        </IconButton>
                    </TooltipMui>
                </Box>
                <>
                    <Stack direction='row' alignItems='center' justifyContent='center' spacing={1}>
                        <Box sx={styles.filterButton}>
                            <StatsTableDropdown
                                stats={stats}
                                attr={attr}
                                setAttr={setAttr}
                                loading={isLoading}
                                type={type}
                            />
                        </Box>
                    </Stack>

                    <Box sx={styles.center}>
                        <ButtonGroup color='primary' aria-label='outlined primary button group'>
                            <TooltipMui title='Segments'>
                                <IconButton
                                    onClick={() => handleFilterChange('segments')}
                                    color={filter === 'segments' ? 'primary' : 'default'}
                                >
                                    <TimelineIcon />
                                </IconButton>
                            </TooltipMui>
                            <TooltipMui title='Annotations'>
                                <IconButton
                                    onClick={() => handleFilterChange('annotations')}
                                    color={filter === 'annotations' ? 'primary' : 'default'}
                                >
                                    <AssignmentIcon />
                                </IconButton>
                            </TooltipMui>
                            <TooltipMui title='Frames'>
                                <IconButton
                                    onClick={() => handleFilterChange('frames')}
                                    color={filter === 'frames' ? 'primary' : 'default'}
                                >
                                    <ImageIcon />
                                </IconButton>
                            </TooltipMui>
                        </ButtonGroup>
                    </Box>
                    <Box sx={styles.chartBox} style={{ position: 'relative' }}>
                        <Box sx={styles.chart}>
                            <ResponsiveContainer width='95%' aspect={3}>
                                <BarChart
                                    data={data}
                                    margin={styles.chartMargin}
                                    onClick={handleChartClick}
                                    cursor='pointer'
                                >
                                    <CartesianGrid strokeDasharray='3 3' stroke={theme.palette.common.lightgray} />
                                    <XAxis dataKey='_id' stroke={theme.palette.common.black} />
                                    <YAxis stroke={theme.palette.common.black} />
                                    <Tooltip
                                        wrapperStyle={{ outline: 'none' }}
                                        cursor={{ fill: theme.palette.common.mediumlightgray }}
                                        animationEasing={'ease-in-out'}
                                        animationDuration={200}
                                        position={{ y: 50 }}
                                        formatter={(value, name, props) => {
                                            return [value, capitalizeFirstLetter(type)];
                                        }}
                                        itemStyle={styles.tooltipTitle}
                                        content={<CustomTooltip data={data} />}
                                    />
                                    <Legend
                                        wrapperStyle={{
                                            color: theme.palette.common.black,
                                            fontSize: '15px',
                                            paddingTop: '8px',
                                            marginTop: '8px',
                                            marginLeft: '1.8vw',
                                        }}
                                        payload={[
                                            {
                                                value: capitalizeFirstLetter(type),
                                                type: 'circle',
                                                id: 'count',
                                                color: theme.palette.primary.main,
                                            },
                                        ]}
                                        align='center'
                                        verticalAlign='bottom'
                                    />
                                    <Bar dataKey='count' fill='#8884d8'>
                                        {data.map((entry, index) => (
                                            <Cell
                                                key={`cell-${index}`}
                                                fill={
                                                    entry._id === MISSING
                                                        ? theme.palette.missing.main
                                                        : theme.palette.primary.main
                                                }
                                            />
                                        ))}
                                    </Bar>
                                </BarChart>
                            </ResponsiveContainer>
                        </Box>
                        {isLoading && (
                            <Box
                                sx={styles.progress}
                                style={{
                                    position: 'absolute',
                                    top: '30%',
                                    left: 0,
                                    right: 0,
                                }}
                            >
                                <CircularProgress data-testid='graph-progress' color={'secondary'} />
                            </Box>
                        )}
                    </Box>
                    {!selectedType && (
                        <Box sx={styles.center}>
                            <AttributeSwitch
                                setType={setType}
                                isLoading={isLoading}
                                setIsLoading={setIsLoading}
                                absolute={false}
                            />
                        </Box>
                    )}
                </>
            </Box>
        </Paper>
    );
};

const mapStateToProps = (state) => {
    return {
        stats: state.stats,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        statsFetch: (filter, type) => {
            return dispatch(actions.api.stats.fetch.request({ filter, type }));
        },
        resetStats: () => dispatch(actions.api.stats.reset()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(StatsTable);
