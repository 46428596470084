import { CheckCircleOutlineTwoTone } from '@mui/icons-material/';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React from 'react';

const ValidationButton = (props) => {
    const theme = useTheme();
    const style = {
        button: {
            color: theme.palette.primary.main,
            width: 28,
            height: 28,
        },
    };
    const classes = style;

    const { error, onClick, updated } = props;

    if (error || !updated) return null;

    return (
        <IconButton
            disabled={error || !updated}
            onClick={onClick}
            data-testid={'validation-button'}
            className={'validation-button-query'} // class used by project to identify the validation button
        >
            <CheckCircleOutlineTwoTone sx={classes.button} />
        </IconButton>
    );
};

ValidationButton.propTypes = {
    error: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    updated: PropTypes.bool.isRequired,
};

export default ValidationButton;
