import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useTheme } from '@mui/material/styles';
import React from 'react';

export default function CustomBackdrop(props) {
    const theme = useTheme();
    const classes = {
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
    };

    return (
        <Backdrop className={classes.backdrop} open={true} data-testid='custom-backdrop'>
            <CircularProgress color='inherit' data-testid='circular-progress' />
        </Backdrop>
    );
}
