import {
    Alert,
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Grid,
    MenuItem,
    Paper,
    Select,
    TextField,
    useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { actions } from '../../../../actions';
import { initialState as qbuilderSpecs } from '../../../../reducers/qbuilder';

const checkboxes = ['Videos', 'Frames', 'Masks'];
const radios = ['BMP', 'JPG', 'GIF', 'PNG', 'TIFF'];

const nameRegex = new RegExp('^[a-zA-Z][a-zA-Z0-9_-]{7,63}$');
const nameErrorMessage =
    'Must start with a letter, 8 characters minimum, contain only letters, numbers, underscores and hyphens.';
const latestQueryVersion = qbuilderSpecs.query.version;

function DatasetSpecs(props) {
    const { specs, editSpecs, setError, error, checkName, submit, back, version, setVersion } = props;

    const theme = useTheme();
    const styles = {
        origin: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        root: {
            width: '100%',
        },
        backButton: {
            marginRight: theme.spacing(1),
        },
        form: {
            width: '100%',
            position: 'relative',
            boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2)',
            padding: theme.spacing(2),
            paddingRight: theme.spacing(4),
            paddingLeft: theme.spacing(4),
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
        },
        label: {
            fontWeight: 'bold',
            marginBottom: theme.spacing(1),
        },
        description: {
            marginTop: theme.spacing(2),
        },
        checkLabel: {
            fontWeight: 'bold',
            fontSize: '14px',
            marginRight: theme.spacing(8),
            marginBottom: theme.spacing(1),
        },
        alertText: {
            marginTop: theme.spacing(1),
            width: '100%',
            '& > div:nth-child(2)': {
                width: '100%',
                padding: '4px',
            },
        },
    };
    const classes = styles;

    const [name, setName] = useState(specs.name);
    const [dataToInclude, setDataToInclude] = useState(specs.dataToInclude);
    const [frameEncoding, setFrameEncoding] = useState(specs.frameEncoding);
    const [maskEncoding, setMaskEncoding] = useState(specs.maskEncoding);
    const [isUpdated, setIsUpdated] = useState(false);
    const [isInitialized, setIsInitialized] = useState(false);

    const isErrorName = () => {
        return name === null || !nameRegex.test(name);
    };

    const handleChangeName = (event) => {
        setName(event.target.value);
        syncSpecs({ name: event.target.value });
        setIsUpdated(true);
    };

    const handleChangeDataToInclude = (label, checked) => {
        let newDataToInclude = [];
        if (checked) {
            newDataToInclude = [...dataToInclude, label];
        } else {
            newDataToInclude = dataToInclude.filter((item) => item !== label);
        }
        setDataToInclude(newDataToInclude);
        syncSpecs({ dataToInclude: newDataToInclude });
        setIsUpdated(true);
        setError(true);
    };

    const handleChangeFrameEncoding = (selectedLabel) => {
        setFrameEncoding(selectedLabel);
        syncSpecs({ frameEncoding: selectedLabel });
        setIsUpdated(true);
        setError(true);
    };

    const handleChangeMaskEncoding = (selectedLabel) => {
        setMaskEncoding(selectedLabel);
        syncSpecs({ maskEncoding: selectedLabel });
        setIsUpdated(true);
        setError(true);
    };

    useEffect(() => {
        if (isInitialized && !error) {
            submit();
        }
    }, [error, isInitialized]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleFinish = () => {
        setError(true);
        syncSpecs();
        setIsUpdated(false);
        checkName(name);
        setIsInitialized(true);
    };

    const syncSpecs = ({
        name = specs.name,
        dataToInclude = specs.dataToInclude,
        frameEncoding = specs.frameEncoding,
        maskEncoding = specs.maskEncoding,
    } = {}) => {
        editSpecs({ name, dataToInclude, frameEncoding, maskEncoding });
    };

    const handleBack = () => {
        setIsInitialized(false);
        back();
    };

    return (
        <Box sx={classes.origin}>
            <Box className={classes.root} padding={theme.spacing(0.5)}>
                <Paper sx={classes.form}>
                    <Grid container direction='column' spacing={theme.spacing(1)}>
                        <Grid item>
                            <FormLabel component='legend' sx={classes.label}>
                                Name of the generated snapshot{' '}
                            </FormLabel>
                            <TextField
                                fullWidth
                                required
                                error={isErrorName() && name !== ''}
                                label='Suggested: {snapshot_name}_{query_description}_{YYMMDD}'
                                color='secondary'
                                value={name}
                                onChange={handleChangeName}
                                data-testid='dataset-name'
                            />
                            {isErrorName() && (
                                <Alert severity='error' sx={classes.alertText}>
                                    {nameErrorMessage}
                                </Alert>
                            )}
                        </Grid>
                        <Grid item sx={classes.description}>
                            <Grid container justify='space-between' spacing={5}>
                                <Grid item>
                                    <FormLabel component='legend' sx={classes.checkLabel}>
                                        Data to include
                                    </FormLabel>
                                    <FormGroup data-testid='data-to-include'>
                                        {checkboxes.map((label, idx) => (
                                            <FormControlLabel
                                                key={idx}
                                                onChange={(event) =>
                                                    handleChangeDataToInclude(label.toLowerCase(), event.target.checked)
                                                }
                                                value={label?.toLowerCase()}
                                                checked={dataToInclude.includes(label?.toLowerCase())}
                                                control={<Checkbox />}
                                                label={label}
                                            />
                                        ))}
                                    </FormGroup>
                                </Grid>
                                <Grid item>
                                    <FormLabel component='legend' sx={classes.checkLabel}>
                                        Extracted frames encoding
                                    </FormLabel>
                                    <Select
                                        fullWidth
                                        labelId='frameEncoding'
                                        id='frameEncoding'
                                        value={frameEncoding}
                                        onChange={(event) => handleChangeFrameEncoding(event.target.value)}
                                        data-testid='frame-encoding'
                                    >
                                        {radios.map((label, idx) => (
                                            <MenuItem key={idx} value={label?.toLowerCase()}>
                                                {label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Grid>
                                <Grid item>
                                    <FormLabel component='legend' sx={classes.checkLabel}>
                                        Generated masks encoding
                                    </FormLabel>
                                    <Select
                                        fullWidth
                                        labelId='maskEncoding'
                                        id='maskEncoding'
                                        value={maskEncoding}
                                        onChange={(event) => handleChangeMaskEncoding(event.target.value)}
                                        data-testid='mask-encoding'
                                    >
                                        {radios.map((label, idx) => (
                                            <MenuItem key={idx} value={label?.toLowerCase()}>
                                                {label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {version !== latestQueryVersion && (
                        <Alert severity='warning' sx={classes.alertText}>
                            <Grid container alignItems='center' justifyContent='space-between' sx={{ width: '100%' }}>
                                The current query is using an older version.
                                <Button
                                    color='warning'
                                    variant='outlined'
                                    size='small'
                                    onClick={() => setVersion(latestQueryVersion)}
                                    sx={{
                                        float: 'right',
                                    }}
                                >
                                    Use query version v{latestQueryVersion}
                                </Button>
                            </Grid>
                        </Alert>
                    )}
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: theme.spacing(2) }}>
                        <Box
                            sx={{
                                color: 'gray',
                                fontSize: '14px',
                                textAlign: 'center',
                                fontStyle: 'italic',
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'flex-start',
                            }}
                        >
                            Query v{version}
                        </Box>
                        <Button onClick={handleBack} sx={classes.backButton}>
                            Back
                        </Button>
                        <Button
                            variant='contained'
                            color='primary'
                            onClick={handleFinish}
                            sx={classes.finishButton}
                            disabled={!isUpdated || isErrorName()}
                            data-testid='finish-button'
                        >
                            Finish
                        </Button>
                    </Box>
                </Paper>
            </Box>
        </Box>
    );
}

const mapStateToProps = (state) => {
    return {
        version: state.qbuilder.query.version,
        specs: state.qbuilder.specs,
        error: state.qbuilder.error,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        checkName: (name) => dispatch(actions.api.qbuilder.check.request(name)),
        editSpecs: (...args) => dispatch(actions.qbuilder.specs.edit(...args)),
        setError: (err) => dispatch(actions.qbuilder.error(err)),
        setVersion: (version) => dispatch(actions.qbuilder.query.version(version)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DatasetSpecs);
