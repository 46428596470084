import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import VisibilityIcon from '@mui/icons-material/Visibility';
import WarningIcon from '@mui/icons-material/Warning';
import Tooltip from '@mui/material/Tooltip';

function AnnotationStateToReview(props) {
    const { title } = props;
    return (
        <Tooltip title={title} data-testid={'annotation-state-to-review'}>
            <VisibilityIcon color='disabled' />
        </Tooltip>
    );
}

function AnnotationStateToUpdate(props) {
    const { title } = props;
    return (
        <Tooltip title={title} data-testid={'annotation-state-to-update'}>
            <WarningIcon color='warning' />
        </Tooltip>
    );
}

function AnnotationStateReviewed(props) {
    const { title } = props;
    return (
        <Tooltip title={title} data-testid={'annotation-state-reviewed'}>
            <CheckCircleIcon color='primary' />
        </Tooltip>
    );
}

function AnnotationStateUnknown() {
    return (
        <Tooltip title={'Unknown state'} data-testid={'annotation-state-unknown'}>
            <HelpOutlineIcon />
        </Tooltip>
    );
}

const matchingElements = {
    0: AnnotationStateToReview,
    1: AnnotationStateToUpdate,
    2: AnnotationStateReviewed,
};

const existingStates = {
    0: 'REVIEW',
    1: 'WARNING',
    2: 'SUCCESS',
    3: 'PENDING',
    4: 'PROCESSING',
    5: 'FAILED',
};

function AnnotationState(props) {
    const { state } = props;

    try {
        const title = existingStates[state];
        const titleCapitalized = title.charAt(0).toUpperCase() + title.slice(1).toLowerCase();
        const Element = matchingElements[state] || AnnotationStateUnknown;
        return <Element {...props} title={titleCapitalized} />;
    } catch (e) {
        return <AnnotationStateUnknown />;
    }
}

export default AnnotationState;
