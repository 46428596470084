import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';

import { actions } from '../../../actions';
import { api } from '../../../services/api';
import { useInterval } from '../../../services/helpers';
import { isTaskPending } from '../../utils/Tasks/Status';
import EnhancedTable from './components/EnhancedTable';

function DataSetsPanel(props) {
    const {
        lastUpdate,
        dsUpdate,
        dsFetchLasts,
        dsRemove,
        isLoading,
        dsCountCheck,
        dsProcessedTasks,
        celeryFetching,
        dsReRun,
    } = props;
    const [dsToUpdate, setDsToUpdate] = useState([]);

    useEffect(() => {
        dsUpdate();
    }, [dsUpdate]);

    const [dsTasksLocal, setDsTasksLocal] = useState(dsProcessedTasks);

    useMemo(() => {
        setDsToUpdate(dsProcessedTasks.filter((ds) => isTaskPending(ds)));
        setDsTasksLocal(dsProcessedTasks);
        return dsProcessedTasks;
    }, [dsProcessedTasks]);

    const onDeleteTask = (id) => {
        dsRemove(id);
        setDsTasksLocal(dsTasksLocal.filter((ds) => ds.id !== id));
    };

    useEffect(() => {
        if (celeryFetching) return;

        dsFetchLasts(lastUpdate, api.endpoints.dsTasks);
    }, [celeryFetching]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        dsFetchLasts(lastUpdate, api.endpoints.celery);
    }, [dsFetchLasts]); // eslint-disable-line react-hooks/exhaustive-deps

    useInterval(() => {
        dsToUpdate.forEach((ds) => {
            const creation = moment(ds.datetime).format('YYYY-MM-DDTHH:mm:ss');
            if (moment().isSame(creation, 'day')) {
                return dsCountCheck(ds.id);
            }
        });
    }, props.autoupdate);

    useInterval(() => {
        dsFetchLasts(lastUpdate, api.endpoints.celery);
    }, props.refreshDelayFetchPartial);

    const handleRerun = (row) => {
        dsReRun(row);
        dsFetchLasts(lastUpdate, api.endpoints.celery);
    };

    return (
        <EnhancedTable rows={dsTasksLocal} isLoading={isLoading} onDatasetRemove={onDeleteTask} dsReRun={handleRerun} />
    );
}

const mapStateToProps = (state) => {
    return {
        refreshDelayFetchAll: state.preference.refreshDelay.datasets.fetchAll,
        refreshDelayFetchPartial: state.preference.refreshDelay.datasets.fetchLast,
        refreshDelayFetchPending: state.preference.refreshDelay.datasets.fetchPending,
        autoupdate: state.preference.refreshDelay.datasets.autoupdate,
        isLoading:
            state.data[api.endpoints.dsTasks]?.isFetching ||
            state.data[api.endpoints.dsTasks]?.isUpdating ||
            state.data[api.endpoints.dsTasks]?.isRemoving ||
            false,
        dsProcessedTasks: (state.processedData[api.endpoints.dsTasks] || {}).data || [],
        lastUpdate: (state.data[api.endpoints.dsTasks] || {}).lastUpdate,
        celeryFetching: (state.processedData[api.endpoints.celery] || {}).isFetching || false,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dsRemove: (id) => dispatch(actions.api.data.remove.request({ endpoint: api.endpoints.dsTasks, id })),
        dsUpdate: (id) => dispatch(actions.api.data.update.request({ endpoint: api.endpoints.dsTasks, id })),
        dsCountCheck: (id) => dispatch(actions.api.data.update.request({ endpoint: api.endpoints.dsCount, id })),
        dsFetchLasts: (datetime, endpoint) =>
            dispatch(
                actions.api.data.fetch.request({
                    endpoint: endpoint,
                    datetime,
                }),
            ),
        dsReRun: (row) => dispatch(actions.qbuilder.rerun.request({ endpoint: api.endpoints.dsReRun, row })),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DataSetsPanel);
