import { Box } from '@mui/material';
import React from 'react';

import LabelAttributesStatsAnnotations from '../../components/Metrics/AttributeStats/LabelAttributesStatsAnnotations';
import StatsTable from '../../components/Metrics/ClickableStats/StatsTable';
import StatsLabelTree from '../../components/Metrics/Tree/StatsLabelTree';

function MetricLabelsPanel() {
    return (
        <Box>
            <StatsTable selectedType='labels' />
            <Box sx={{ height: 32 }} />
            <LabelAttributesStatsAnnotations />
            <Box sx={{ height: 32 }} />
            <StatsLabelTree />
            <Box sx={{ height: 32 }} />
        </Box>
    );
}

export default MetricLabelsPanel;
