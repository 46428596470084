import { Close } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import RestoreIcon from '@mui/icons-material/Restore';
import { IconButton, TableCell, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

import ConfirmDeleteButton from '../../../components/Button/ConfirmDeleteButton';
import ConfirmRestoreButton from '../../../components/Button/ConfirmRestoreButton';

function ManagementCell(props) {
    const {
        row,
        editMode,
        editModeRow,
        versionIndex,
        isUpdating,
        currentVersion,
        onAnnotationRemove,
        onAnnotationRestore,
        setEditModeRow,
        restorePerms,
        deletePerms,
        isLoading,
    } = props;

    const styles = {
        cell: {
            borderBottom: 'none',
            height: '72px',
            width: '200px',
        },
    };
    const classes = styles;

    const handleClickOnDeleteData = (hash) => {
        onAnnotationRemove(hash);
    };

    const handleClickOnRestoreData = (id) => {
        onAnnotationRestore(id);
    };

    const handleEditMode = (row) => {
        const videoHash = row.video_hash;

        let current_state = {
            ...editModeRow,
        };
        if (current_state[videoHash] !== undefined) {
            current_state[videoHash] = undefined;
        } else {
            current_state[row?.video_hash] = row;
        }
        setEditModeRow(current_state);
    };

    const closeEditMode = (row) => {
        const videoHash = row.video_hash;

        let current_state = {
            ...editModeRow,
        };
        if (current_state[videoHash] !== undefined) {
            current_state[videoHash] = undefined;
        }
        setEditModeRow(current_state);
    };

    const changeEditVersion = (row, newIndex) => {
        if (newIndex < 0 || newIndex >= row?.versions?.length) {
            return;
        }

        const videoHash = row.video_hash;

        let current_state = {
            ...editModeRow,
        };

        current_state[videoHash] = {
            ...current_state[videoHash],
            active_version_index: newIndex,
        };
        setEditModeRow(current_state);
    };

    if (!restorePerms && !deletePerms) {
        return null;
    }

    if (isLoading) {
        return (
            <TableCell align='center' sx={classes.cell}>
                loading...
            </TableCell>
        );
    }

    return (
        <TableCell align='center' sx={classes.cell}>
            {isUpdating || row?.is_processing ? (
                <>processing...</>
            ) : (
                <>
                    {((!row?.is_deleted || editMode) && (
                        <>
                            {(!editMode && (
                                <>
                                    {restorePerms && (
                                        <IconButton
                                            onClick={() => handleEditMode(row)}
                                            disabled={row?.versions?.length <= 1}
                                        >
                                            <Tooltip title={'Edit this annotation'}>
                                                <EditIcon />
                                            </Tooltip>
                                        </IconButton>
                                    )}
                                    {deletePerms && (
                                        <ConfirmDeleteButton
                                            onClick={() => {
                                                handleClickOnDeleteData(currentVersion?.id);
                                            }}
                                            elementTitle={'annotation'}
                                        />
                                    )}
                                </>
                            )) || (
                                <>
                                    {restorePerms && (
                                        <>
                                            <IconButton
                                                onClick={() => changeEditVersion(row, versionIndex - 1)}
                                                disabled={versionIndex === 0}
                                            >
                                                <Tooltip title={'Previous version'}>
                                                    <NavigateBeforeIcon />
                                                </Tooltip>
                                            </IconButton>
                                            <ConfirmRestoreButton
                                                onClick={() => {
                                                    handleClickOnRestoreData(currentVersion?.id);
                                                    closeEditMode(row);
                                                }}
                                                elementTitle={'annotation'}
                                                disabled={
                                                    row?.active_version_index === versionIndex && !row?.is_deleted
                                                }
                                            />
                                            <IconButton onClick={() => closeEditMode(row)}>
                                                <Tooltip title={'Cancel'}>
                                                    <Close />
                                                </Tooltip>
                                            </IconButton>
                                            <IconButton
                                                onClick={() => changeEditVersion(row, versionIndex + 1)}
                                                disabled={versionIndex === row?.versions.length - 1}
                                            >
                                                <Tooltip title={'Next version'}>
                                                    <NavigateNextIcon />
                                                </Tooltip>
                                            </IconButton>
                                        </>
                                    )}
                                </>
                            )}
                        </>
                    )) || (
                        <>
                            {restorePerms && (
                                <IconButton
                                    onClick={() => {
                                        handleEditMode(row);
                                    }}
                                >
                                    <RestoreIcon />
                                </IconButton>
                            )}
                        </>
                    )}
                </>
            )}
        </TableCell>
    );
}

ManagementCell.propTypes = {
    row: PropTypes.object.isRequired,
    editMode: PropTypes.bool.isRequired,
    editModeRow: PropTypes.object.isRequired,
    versionIndex: PropTypes.number,
    isUpdating: PropTypes.bool.isRequired,
    currentVersion: PropTypes.object.isRequired,
    onAnnotationRemove: PropTypes.func.isRequired,
    onAnnotationRestore: PropTypes.func.isRequired,
    setEditModeRow: PropTypes.func.isRequired,
    restorePerms: PropTypes.bool.isRequired,
    deletePerms: PropTypes.bool.isRequired,
};

export default ManagementCell;
