import { useTheme } from '@emotion/react';
import {
    Box,
    Button,
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';

import { DELAY_BEFORE_ERROR, RATING_TYPES_OPTIONS } from '../../constant';

export const getRatingType = (row) =>
    row?.subcategories?.length ? RATING_TYPES_OPTIONS[0].value : RATING_TYPES_OPTIONS[1].value;

export const RatingSelectionForm = ({
    tables,
    handleRatingChange,
    handleSubmit,
    handleBack,
    editMode,
    setValidationCheck,
    handleAdditionalRatingChange,
}) => {
    const theme = useTheme();
    const styles = {
        buttonContainer: {
            margin: theme.spacing(2),
            display: 'flex',
            justifyContent: 'flex-end',
        },
        section: {
            backgroundColor: theme.palette.common.sectionGray,
            margin: `4px 0 20px 0`,
            borderRadius: '5px',
            padding: theme.spacing(3),
        },
    };

    useEffect(() => {
        tables.forEach((table, tableIndex) => {
            table.data.forEach((subject, subjectIndex) => {
                const expectedType = getRatingType(subject);
                if (!subject.rating || subject.rating.type !== expectedType) {
                    handleRatingChange(tableIndex, subjectIndex, null, 'type', expectedType);
                }
            });
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const allTableRatingSet = () => {
        for (let table of tables) {
            for (let subject of table.data) {
                if (!subject.rating || !subject.rating.type) {
                    return false;
                }

                const option = RATING_TYPES_OPTIONS.find((opt) => opt.value === subject.rating.type);
                if (!option) {
                    return false;
                }
                const nbFields = option.fields.length;

                if (subject.rating.additional) {
                    if (!subject.rating.devValues || !subject.rating.testValues) {
                        return false;
                    }
                    const devValues = subject.rating.devValues.slice(0, nbFields);
                    for (let value of devValues) {
                        if (!value) {
                            return false;
                        }
                    }
                    const testValues = subject.rating.testValues.slice(0, nbFields);
                    for (let value of testValues) {
                        if (!value) {
                            return false;
                        }
                    }
                } else {
                    const values = subject.rating.values.slice(0, nbFields);
                    for (let value of values) {
                        if (!value) {
                            return false;
                        }
                    }
                }
            }
        }
        return true;
    };

    const [showErrors, setShowErrors] = useState(false);

    useEffect(() => {
        const errorTimeout = setTimeout(() => {
            setShowErrors(true);
        }, DELAY_BEFORE_ERROR);

        return () => clearTimeout(errorTimeout);
    }, []);

    useEffect(() => {
        setValidationCheck(() => () => allTableRatingSet());
        return () => setValidationCheck(() => () => true);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const getTypeIcon = (type) => {
        const option = RATING_TYPES_OPTIONS.find((opt) => opt.value === type);
        return option?.icon || null;
    };

    return (
        <Box>
            <Box textAlign='center'>
                <Typography variant='h5' sx={{ marginBottom: '24px' }}>
                    Ratings
                </Typography>
            </Box>
            {tables.map((table, tableIndex) => (
                <Box key={tableIndex} sx={styles.section}>
                    <Box textAlign='center'>
                        <Typography variant='h6'>{table.title}</Typography>
                    </Box>
                    <TableContainer>
                        <Table sx={{ minWidth: 650 }} aria-label='rating table'>
                            <TableHead>
                                <TableRow>
                                    <TableCell align='center'>Subject</TableCell>
                                    <TableCell align='center'>Rating Type</TableCell>
                                    <TableCell align='center'>Split</TableCell>
                                    <TableCell align='center'>Levels</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {table.data.map((subject, subjectIndex) => (
                                    <React.Fragment key={subjectIndex}>
                                        <TableRow>
                                            <TableCell>{subject.subject}</TableCell>
                                            <TableCell align='center'>
                                                {getTypeIcon(subject.rating?.type) || 'Select Type'}
                                            </TableCell>
                                            <TableCell>
                                                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                                    <Checkbox
                                                        checked={subject.rating?.additional || false}
                                                        onChange={() =>
                                                            handleAdditionalRatingChange(tableIndex, subjectIndex)
                                                        }
                                                    />
                                                </Box>
                                            </TableCell>
                                            <TableCell>
                                                {subject.rating?.additional ? (
                                                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                                                        <Box sx={{ display: 'flex', gap: 2 }}>
                                                            {(
                                                                RATING_TYPES_OPTIONS.find(
                                                                    (opt) => opt.value === subject.rating?.type,
                                                                )?.fields || []
                                                            ).map((label, levelIndex) => (
                                                                <TextField
                                                                    key={`dev-${levelIndex}`}
                                                                    label={`${label} (Development)`}
                                                                    value={
                                                                        subject.rating?.devValues?.[levelIndex] || ''
                                                                    }
                                                                    onChange={(e) =>
                                                                        handleRatingChange(
                                                                            tableIndex,
                                                                            subjectIndex,
                                                                            levelIndex,
                                                                            'devValue',
                                                                            e.target.value,
                                                                            true,
                                                                        )
                                                                    }
                                                                    fullWidth
                                                                    type='number'
                                                                    sx={{
                                                                        '& .MuiOutlinedInput-root': {
                                                                            '& fieldset': !subject.rating?.devValues?.[
                                                                                levelIndex
                                                                            ] && {
                                                                                borderColor: theme.palette.error.main,
                                                                            },
                                                                        },
                                                                    }}
                                                                />
                                                            ))}
                                                        </Box>
                                                        <Box sx={{ display: 'flex', gap: 2 }}>
                                                            {(
                                                                RATING_TYPES_OPTIONS.find(
                                                                    (opt) => opt.value === subject.rating?.type,
                                                                )?.fields || []
                                                            ).map((label, levelIndex) => (
                                                                <TextField
                                                                    key={`test-${levelIndex}`}
                                                                    label={`${label} (Testing)`}
                                                                    value={
                                                                        subject.rating?.testValues?.[levelIndex] || ''
                                                                    }
                                                                    onChange={(e) =>
                                                                        handleRatingChange(
                                                                            tableIndex,
                                                                            subjectIndex,
                                                                            levelIndex,
                                                                            'testValue',
                                                                            e.target.value,
                                                                            true,
                                                                        )
                                                                    }
                                                                    fullWidth
                                                                    type='number'
                                                                    sx={{
                                                                        '& .MuiOutlinedInput-root': {
                                                                            '& fieldset': !subject.rating?.testValues?.[
                                                                                levelIndex
                                                                            ] && {
                                                                                borderColor: theme.palette.error.main,
                                                                            },
                                                                        },
                                                                    }}
                                                                />
                                                            ))}
                                                        </Box>
                                                    </Box>
                                                ) : (
                                                    <Box sx={{ display: 'flex', gap: 2 }}>
                                                        {(
                                                            RATING_TYPES_OPTIONS.find(
                                                                (opt) => opt.value === subject.rating?.type,
                                                            )?.fields || []
                                                        ).map((label, levelIndex) => (
                                                            <TextField
                                                                key={`value-${levelIndex}`}
                                                                label={`${label}`}
                                                                value={subject.rating?.values?.[levelIndex] || ''}
                                                                onChange={(e) =>
                                                                    handleRatingChange(
                                                                        tableIndex,
                                                                        subjectIndex,
                                                                        levelIndex,
                                                                        'value',
                                                                        e.target.value,
                                                                    )
                                                                }
                                                                fullWidth
                                                                type='number'
                                                                sx={{
                                                                    '& .MuiOutlinedInput-root': {
                                                                        '& fieldset': !subject.rating?.values?.[
                                                                            levelIndex
                                                                        ] && {
                                                                            borderColor: theme.palette.error.main,
                                                                        },
                                                                    },
                                                                }}
                                                            />
                                                        ))}
                                                    </Box>
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    </React.Fragment>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            ))}
            <Box sx={styles.buttonContainer}>
                {showErrors && !allTableRatingSet() && (
                    <Typography variant='body2' color='error'>
                        Please select a rating type and value for each element of each table row.
                    </Typography>
                )}
            </Box>
            <Box sx={styles.buttonContainer}>
                <Button onClick={handleBack}>Back</Button>
                <Button onClick={handleSubmit} disabled={!allTableRatingSet()} variant={'contained'}>
                    {editMode ? 'Update' : 'Create'}
                </Button>
            </Box>
        </Box>
    );
};
