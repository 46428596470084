import { createReducer } from '@reduxjs/toolkit';

import { actions } from '../actions';

const defaultNode = { type: 'FILTER', negate: false, name: null, body: [], validated: false };

export const initialState = {
    doc: {
        frame: null,
        segment: null,
    },
    specs: {
        name: '',
        dataToInclude: [],
        frameEncoding: 'jpg',
        maskEncoding: 'jpg',
    },
    query: {
        version: '2.0.0',
    },
    nodes: [],
    error: true,
};

function isOperatorNode(node) {
    return node.type === 'OPERATOR';
}

export function denormalizeTree(rootId, entities) {
    const root = entities.find((node) => node.id === rootId);
    if (root === undefined) return undefined;

    let body = [];
    if (isOperatorNode(root))
        root.body.forEach((childIdx) => {
            body.push(denormalizeTree(childIdx, entities));
        });
    else body = [...root.body];

    const result = { ...root, body: body, id: undefined, negate: undefined, validated: undefined, parentId: undefined };

    if (root.negate) return { type: 'OPERATOR', name: 'NOT', body: result };

    return result;
}

export function normalizeTree(root, result, negate = false) {
    let body;
    if (isOperatorNode(root)) {
        const isNegateNode = root.name === 'NOT';
        if (isNegateNode) {
            normalizeTree(root.body, result, true);
            return;
        }
        body = root.body.map((childNode) => {
            normalizeTree(childNode, result);
            return childNode.id;
        });
    } else {
        body = root.body;
    }

    result.push({ ...root, negate, body });
}

function _findAllSubNodes(rootIdx, flattenedTree) {
    const root = flattenedTree.find((node) => node.id === rootIdx);
    if (root === undefined || !isOperatorNode(root)) return [];
    return [].concat.apply(
        [...root.body],
        root.body.map((childIdx) => _findAllSubNodes(childIdx, flattenedTree)),
    );
}

function _nodeCopy(node) {
    return { ...node, body: [...node.body] };
}

function _areAllSubNodesValidated(nodeId, nodes) {
    // Check that all nodes are recursively validated

    const node = nodes.find((node) => node.id === nodeId);

    if (node === undefined) return true;

    if (!isOperatorNode(node) && !node.validated) return false;

    return node.body.every((childId) => _areAllSubNodesValidated(childId, nodes));
}

function _getRootNode(nodeId, nodes) {
    // Find the root node of the entire tree that contains the node with nodeId

    const node = nodes.find((node) => node.id === nodeId);

    if (node === undefined) return undefined;

    if (node.parentId === undefined) return node;

    return _getRootNode(node.parentId, nodes);
}

function reducerNodeAdd(state, action) {
    const nodeId = action.payload.id;
    const nodeParentId = action.payload.parentId;

    const newNodes = state.nodes.map(_nodeCopy);
    let nodeToAdd = { id: nodeId, ...defaultNode };

    if (nodeParentId !== undefined) {
        nodeToAdd = { ...nodeToAdd, parentId: nodeParentId };
        newNodes.find((node) => node.id === nodeParentId).body.push(nodeId);
    }

    newNodes.push(nodeToAdd);
    return { ...state, nodes: newNodes, error: true };
}

function reducerNodeRemove(state, action) {
    const stateNodes = state.nodes;

    const nodeId = action.payload.id;
    const nodeParentId = action.payload.parentId;
    const nodesToRemove = new Set([nodeId, ..._findAllSubNodes(nodeId, stateNodes)]);

    // Remove the sub-children of the node to remove
    const newNodes = stateNodes.filter((node) => !nodesToRemove.has(node.id)).map(_nodeCopy);

    // Detach the node form its parent
    if (nodeParentId !== undefined) {
        const parentNode = newNodes.find((node) => node.id === nodeParentId);
        parentNode.body = parentNode.body.filter((childId) => childId !== nodeId);
    }

    return { ...state, nodes: newNodes };
}

function nameSuccess(state, action) {
    return { ...state, error: false };
}

function nameFailure(state, action) {
    return { ...state, error: true };
}

function rerunSuccess(state, action) {
    return { ...state, error: false };
}

function rerunFailure(state, action) {
    return { ...state, error: true };
}

function reducerNodeEdit(state, action) {
    const nodeId = action.payload.id;
    const newNodes = state.nodes.map(_nodeCopy);
    const newNode = newNodes.find((node) => node.id === nodeId);

    newNode.type = action.payload.type === undefined ? newNode.type : action.payload.type;
    newNode.name = action.payload.name === undefined ? newNode.name : action.payload.name;
    newNode.body = action.payload.body === undefined ? newNode.body : action.payload.body;
    newNode.negate = action.payload.negate === undefined ? newNode.negate : action.payload.negate;
    newNode.validated = action.payload.validated === undefined ? newNode.validated : action.payload.validated;

    return { ...state, nodes: newNodes };
}

function reducerSpecsEdit(state, action) {
    const specs = { ...state.specs };
    const { name, dataToInclude, frameEncoding, maskEncoding } = action.payload;

    specs.name = name || specs.name;
    specs.dataToInclude = dataToInclude || specs.dataToInclude;
    specs.frameEncoding = frameEncoding || specs.frameEncoding;
    specs.maskEncoding = maskEncoding || specs.maskEncoding;

    return { ...state, specs };
}

function setQBuilderError(state, action) {
    return { ...state, error: action.payload };
}

function updateQBuilderValidation(state, action) {
    const nodeId = action.payload;

    if (nodeId === undefined) return state;

    // Find the root node of the entire tree that contains the node with nodeId
    const root = _getRootNode(nodeId, state.nodes);

    // Check if all the nodes are valid and update the error status
    const validated = _areAllSubNodesValidated(root.id, state.nodes);

    return { ...state, error: !validated };
}

function setQBuilderValidation(state, action) {
    const { nodeId, validate } = action.payload;

    if (nodeId === undefined) return state;

    // Set the validation status of the current node in the list
    const newNodes = state.nodes.map((node) => (node.id === nodeId ? { ...node, validated: validate } : node));

    // Find the root node of the entire tree that contains the node with nodeId
    const root = _getRootNode(nodeId, newNodes);

    // Check if all the nodes are valid and update the error status
    const validated = _areAllSubNodesValidated(root.id, newNodes);

    return { ...state, nodes: newNodes, error: !validated };
}

export const qbuilder = createReducer(initialState, {
    [actions.qbuilder.node.add]: reducerNodeAdd,
    [actions.qbuilder.node.clear]: (state, action) => ({ ...state, nodes: initialState.nodes }),
    [actions.qbuilder.node.edit]: reducerNodeEdit,
    [actions.qbuilder.node.remove]: reducerNodeRemove,

    [actions.qbuilder.specs.edit]: reducerSpecsEdit,
    [actions.qbuilder.specs.clear]: (state, action) => ({ ...state, specs: initialState.specs }),

    [actions.api.qbuilder.doc.success]: (state, action) => ({
        ...state,
        doc: { frame: action.payload.frame, segment: action.payload.segment },
    }),
    [actions.api.qbuilder.doc.failure]: (state, action) => ({
        ...state,
        error: action.payload,
    }),

    [actions.qbuilder.error]: setQBuilderError,

    [actions.qbuilder.validation.set]: setQBuilderValidation,
    [actions.qbuilder.validation.update]: updateQBuilderValidation,

    [actions.api.qbuilder.check.success]: nameSuccess,
    [actions.api.qbuilder.check.failure]: nameFailure,
    [actions.qbuilder.rerun.success]: rerunSuccess,
    [actions.qbuilder.rerun.failure]: rerunFailure,
    [actions.qbuilder.query.version]: (state, action) => ({ ...state, query: { version: action.payload } }),
});
