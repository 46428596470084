import { Paper, Table, TableBody, TableCell, TableContainer, TablePagination, TableRow, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import moment from 'moment/moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { actions } from '../../../../actions';
import { AttributeSwitch } from '../../../components/Attributes/Switch/AttributeSwitch';
import { MISSING, capitalizeFirstLetter, getDefaultAttr } from '../../../components/Attributes/Switch/AttributeUtils';
import EnhancedTableHead from '../../../components/Table/EnhancedTableHead';
import EnhancedTableToolbar from '../../../components/Table/EnhancedTableToolbar';
import LoadingTable from '../../../components/Table/LoadingTable';
import { getPanelUri } from '../../../panels';
import { getSorting, stableSort } from '../../../utils/Table/Search';
import AnnotationDetails from '../../Annotation';
import AnnotationState from '../../DataExplorer/components/AnnotationState';
import AttributeFilters from './AttributeFilters';

const headCells = [
    { id: 'video_hash', label: 'Video Hash', align: 'center' },
    { id: 'user', label: 'Author' },
    { id: 'date_update', label: 'Uploaded', align: 'center' },
    { id: 'date_apply', label: 'Applied', align: 'center' },
    { id: 'num_segments', label: 'Segments', align: 'center' },
    { id: 'num_frames', label: 'Frames', align: 'center' },
    { id: 'state', label: 'State', align: 'center' },
];
const PAGE_HEADER_HEIGHT = 54;

function EnhancedTable(props) {
    const { rows, isLoading, fetchFilters, filters, annotationFetchAttr, resetList } = props;
    const theme = useTheme();

    const styles = {
        root: {
            width: '100%',
        },
        paper: {
            width: '100%',
            position: 'relative',
            boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2)',
            marginBottom: '4px',
        },
        tableContainer: {
            maxHeight: `calc(100vh - 442px + ${PAGE_HEADER_HEIGHT}px)`,
            overflowY: 'auto',
        },
        cell: {
            borderBottom: 'none',
        },
        editMode: {
            backgroundColor: theme.palette.common.simplegray,
        },
        center: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
    };

    const classes = styles;
    const [filterRows, setFilterRows] = useState(rows);
    const [currentPage, setCurrentPage] = useState({});

    // Router
    const navigate = useNavigate();

    // Params for the table
    const validOrders = ['asc', 'desc'];
    const validOrderBy = headCells.map((cell) => cell.id);
    const validRowsPerPageOptions = [5, 10, 25];

    const validateOrder = (order) => (validOrders.includes(order) ? order : 'desc');
    const validateOrderBy = (orderBy) => (validOrderBy.includes(orderBy) ? orderBy : 'date_apply');
    const validateRowsPerPage = (rowsPerPage) => (validRowsPerPageOptions.includes(rowsPerPage) ? rowsPerPage : 5);
    const validatePage = (page, maxPage) => (page >= 0 && page <= maxPage ? page : 0);

    const [searchParams, setSearchParams] = useSearchParams();
    const [order, setOrder] = useState(validateOrder(searchParams.get('order')));
    const [orderBy, setOrderBy] = useState(validateOrderBy(searchParams.get('orderBy')));
    const [rowsPerPage, setRowsPerPage] = useState(validateRowsPerPage(parseInt(searchParams.get('rowsPerPage'), 10)));
    const [page, setPage] = useState(parseInt(searchParams.get('page') || 0, 10));
    const [attrValue, setAttrValue] = useState(searchParams.get('value') || '');
    const [attrName, setAttrName] = useState(searchParams.get('name') || '');
    const [availableAttrValues, setAvailableAttrValues] = useState([]);
    const [availableAttrNames, setAvailableAttrNames] = useState([]);
    const [searchedCombination, setSearchedCombination] = useState('');
    const [isProcessing, setIsProcessing] = useState(false);
    const [type, setType] = useState('');

    useEffect(() => {
        if (!filterRows.length) return;

        const maxPage = Math.ceil(filterRows.length / rowsPerPage) - 1;
        const newPage = validatePage(page, maxPage);

        if (newPage !== page) {
            setPage(newPage);
        }
    }, [filterRows.length, rowsPerPage, setPage, page]);

    useEffect(() => {
        const newParams = { ...Object.fromEntries(searchParams.entries()) };
        newParams.order = order;
        newParams.orderBy = orderBy;
        newParams.value = attrValue;
        newParams.name = attrName;
        newParams.page = page.toString();
        newParams.rowsPerPage = rowsPerPage.toString();
        setSearchParams(newParams, { replace: true });
    }, [order, orderBy, page, rowsPerPage, attrValue, attrName, searchParams, setSearchParams]);

    useEffect(() => {
        if (type) fetchFilters(type);
    }, [fetchFilters, type]);

    useEffect(() => {
        const keys = Object.keys(filters);
        if (keys && ((keys.length === 1 && keys[0] !== '0') || keys.length > 1)) {
            keys.sort();
            setAvailableAttrNames(keys);
            if (!attrName || !keys.includes(attrName)) {
                setAttrName(getDefaultAttr(keys));
            }
        }
    }, [filters, setAttrName]); // eslint-disable-line react-hooks/exhaustive-deps

    const getAttrCombination = (name, value) => {
        return `${name}:${value}`;
    };

    const resetIfNeeded = (name, value) => {
        if (searchedCombination !== getAttrCombination(name, value)) {
            resetList();
        }
    };
    const search = (name, value) => {
        if (!name || !value || !type) return;

        if (!isLoading) {
            resetIfNeeded(name, value);
            annotationFetchAttr(name, value, type);
            setSearchedCombination(getAttrCombination(name, value));
        }
    };

    useEffect(() => {
        if (!filters || !attrName) return;
        const values = filters[attrName] || [];
        if (values && values.length > 0) {
            const formattedValues = values.map((value) => {
                return value._id || 'N/A';
            });

            // Sort values
            formattedValues.sort();

            // Handle missing
            const missingIndex = formattedValues.indexOf(MISSING);
            if (missingIndex > -1) {
                formattedValues.push(formattedValues.splice(missingIndex, 1)[0]);
            }

            setAvailableAttrValues(formattedValues);
            if (!attrValue || !formattedValues.includes(attrValue)) {
                setAttrValue(formattedValues[0]);
                search(attrName, formattedValues[0]);
            }
        }
    }, [filters, attrName, setAttrValue]); // eslint-disable-line react-hooks/exhaustive-deps

    // Filter
    useEffect(() => {
        if (getAttrCombination(attrName, attrValue) !== searchedCombination) {
            search(attrName, attrValue);
            return;
        }

        setIsProcessing(true);
        setFilterRows(stableSort(rows, getSorting(order, orderBy)));
        setIsProcessing(false);
    }, [rows, orderBy, order]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setCurrentPage(filterRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage));
    }, [filterRows, page, rowsPerPage]);

    useEffect(() => {
        if (!attrName || !attrValue) return;
        search(attrName, attrValue);
    }, [annotationFetchAttr]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleSearch = (event, value) => {
        search(attrName, value);
    };
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const openAnnotation = (hash) => {
        const uri = getPanelUri(<AnnotationDetails />, { hash: hash });
        navigate(uri);
    };

    const handleAttrNameChange = (event, value) => {
        resetIfNeeded(value, attrValue);
        setAttrName(value);
    };

    const handleAttrValueChange = (event, value) => {
        resetIfNeeded(attrName, value);
        setAttrValue(value);
    };

    return (
        <Box sx={classes.root} data-testid={'tasks-table'}>
            <Paper sx={classes.paper}>
                <div>
                    <EnhancedTableToolbar
                        margin={'no-m'}
                        isLoading={isLoading}
                        title='Annotations Filter'
                        subtitle={capitalizeFirstLetter(type)}
                    />
                    <Box sx={styles.center}>
                        <AttributeSwitch
                            setType={setType}
                            isLoading={isProcessing}
                            setIsLoading={setIsProcessing}
                            reset={resetList}
                            absolute={false}
                        />
                    </Box>
                    <Box
                        sx={{
                            padding: '16px',
                        }}
                    >
                        <AttributeFilters
                            handleSearch={handleSearch}
                            handleAttrNameChange={handleAttrNameChange}
                            handleAttrValueChange={handleAttrValueChange}
                            availableAttrNames={availableAttrNames}
                            availableAttrValues={availableAttrValues}
                            attrName={attrName}
                            attrValue={attrValue}
                            type={type}
                        />
                    </Box>
                    <TableContainer sx={styles.tableContainer}>
                        <Table stickyHeader aria-label='sticky table'>
                            <EnhancedTableHead
                                classes={classes}
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                                rowCount={filterRows.length}
                                staff={false}
                                headCells={headCells}
                            />

                            {filterRows && filterRows.length === 0 ? (
                                <LoadingTable
                                    isLoading={isLoading || isProcessing}
                                    message={'No data matches the search criteria.'}
                                />
                            ) : (
                                <TableBody>
                                    {currentPage.map((row, index) => {
                                        return (
                                            <TableRow hover tabIndex={index} key={row?.video_hash}>
                                                <TableCell
                                                    align='center'
                                                    sx={{ ...classes.cell, cursor: 'pointer' }}
                                                    component='th'
                                                    scope='row'
                                                    onClick={() => openAnnotation(row?.video_hash)}
                                                >
                                                    <Tooltip
                                                        title={row?.video_hash.toLocaleUpperCase()}
                                                        placement='top-start'
                                                    >
                                                        <span>
                                                            {' '}
                                                            {row?.video_hash
                                                                .substring(0, 20)
                                                                .toLocaleUpperCase()} ...{' '}
                                                        </span>
                                                    </Tooltip>
                                                </TableCell>
                                                <TableCell align='left' sx={classes.cell}>
                                                    {row.user}
                                                </TableCell>

                                                <TableCell align='center' sx={classes.cell}>
                                                    {moment(row?.date_update).format('MMMM Do YYYY, hh:mm:ss')}
                                                </TableCell>
                                                <TableCell align='center' sx={classes.cell}>
                                                    {moment(row?.date_apply).format('MMMM Do YYYY, hh:mm:ss')}
                                                </TableCell>

                                                <TableCell align='center' sx={classes.cell}>
                                                    {row?.num_segments}
                                                </TableCell>

                                                <TableCell align='center' sx={classes.cell}>
                                                    {row?.num_frames}
                                                </TableCell>

                                                <TableCell align='center' sx={classes.cell}>
                                                    <AnnotationState state={row?.state} />
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={validRowsPerPageOptions}
                        component='div'
                        count={filterRows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        showFirstButton
                        showLastButton
                    />
                </div>
            </Paper>
        </Box>
    );
}

EnhancedTable.propTypes = {
    rows: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
    return {
        filters: state.stats?.stats || {},
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchFilters: (type) => {
            return dispatch(actions.api.stats.fetch.request({ type }));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(EnhancedTable);
