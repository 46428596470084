import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

function ConfirmButton(props) {
    const {
        onClick,
        children,
        title,
        content,
        optionYes,
        optionFalse,
        colorYes,
        colorFalse,
        variantYes,
        variantFalse,
        disabled,
    } = props;
    const [openDialog, setOpenDialog] = useState(false);

    const handleClick = () => {
        setOpenDialog(false);
        onClick();
    };

    return (
        <>
            <IconButton onClick={() => setOpenDialog(true)} disabled={disabled}>
                {children || <HelpOutlineIcon color='error' />}
            </IconButton>
            <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
                <DialogTitle
                    sx={{
                        textAlign: 'center',
                    }}
                >
                    {title || 'Are you sure?'}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>{content}</DialogContentText>
                </DialogContent>
                <DialogActions
                    sx={{
                        margin: 1,
                    }}
                >
                    <Button
                        variant={variantFalse || 'contained'}
                        onClick={() => setOpenDialog(false)}
                        color={colorFalse || 'primary'}
                        autoFocus
                    >
                        {optionFalse || 'No'}
                    </Button>
                    <Button variant={variantYes || 'contained'} onClick={handleClick} color={colorYes || 'error'}>
                        {optionYes || 'Yes'}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

ConfirmButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
};

export default ConfirmButton;
