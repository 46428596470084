import { call, put, takeLatest } from '@redux-saga/core/effects';

import { actions } from '../actions';
import { api } from '../services/api';
import { retrySaga } from './apiWrapper';

function* getStats(action) {
    try {
        const statsResponse = yield call(retrySaga, api.stats.stats, [action.payload]);
        yield put(
            actions.api.stats.fetch.success({
                stats: statsResponse.data,
            }),
        );
    } catch (e) {
        yield put(actions.api.stats.fetch.failure(e));
    }
}

export default function* watchStats() {
    yield takeLatest(actions.api.stats.fetch.request.toString(), getStats);
}
