import {
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow,
    Tooltip,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { actions } from '../../../../actions';
import ConfirmDeleteButton from '../../../components/Button/ConfirmDeleteButton';
import ConfirmRerunButton from '../../../components/Button/ConfirmRerunButton';
import EnhancedTableHead from '../../../components/Table/EnhancedTableHead';
import EnhancedTableToolbar from '../../../components/Table/EnhancedTableToolbar';
import LoadingTable from '../../../components/Table/LoadingTable';
import { getPanelUri } from '../../../panels';
import { PermissionManager } from '../../../utils/Permissions/PermissionManager';
import { getSorting, stableSort } from '../../../utils/Table/Search';
import { formatDataSize } from '../../../utils/Table/Size';
import DataSetsDetails from '../../Details';
import TaskState from './TaskState';

const headCells = [
    { id: 'id', numeric: false, disablePadding: true, label: 'ID' },
    { id: 'name', numeric: true, disablePadding: false, label: 'Snapshot Name' },
    { id: 'user.username', numeric: true, disablePadding: false, label: 'Author' },
    { id: 'datetime', numeric: true, disablePadding: false, label: 'Creation Date' },
    { id: 'size', numeric: true, disablePadding: false, label: 'Size' },
    { id: 'states', numeric: true, disablePadding: false, label: 'State', align: 'center' },
    { id: 'manage', numeric: true, disablePadding: false, label: 'Manage', align: 'center', staff: true },
];

const PAGE_HEADER_HEIGHT = 54;
function EnhancedTable(props) {
    const { rows, onDatasetRemove, dsReRun, search, isLoading, setSearch, rerunPerms, deletePerms } = props;

    const theme = useTheme();
    const styles = {
        root: {
            width: '100%',
        },
        paper: {
            width: '100%',
            position: 'relative',
            boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2)',
            marginBottom: '4px',
        },
        tableContainer: {
            maxHeight: `calc(100vh - 364px + ${PAGE_HEADER_HEIGHT}px)`,
            overflowY: 'auto',
        },
        rerunDialog: {
            '& .MuiDialog-paper': {
                backgroundColor: theme.palette.background.main,
            },
            margin: theme.spacing(1),
        },
        cell: {
            borderBottom: 'none',
        },
    };

    // Filter rows
    const classes = styles;
    const [filterRows, setFilterRows] = useState(rows);

    const navigate = useNavigate();

    // Parameters validation
    const validOrders = ['asc', 'desc'];
    const validOrderBy = headCells.map((cell) => cell.id);
    const validRowsPerPageOptions = [5, 10, 25];

    const validateOrder = (order) => (validOrders.includes(order) ? order : 'desc');
    const validateOrderBy = (orderBy) => (validOrderBy.includes(orderBy) ? orderBy : 'id');
    const validateRowsPerPage = (rowsPerPage) => (validRowsPerPageOptions.includes(rowsPerPage) ? rowsPerPage : 5);
    const validatePage = (page, maxPage) => (page >= 0 && page <= maxPage ? page : maxPage);

    // URL search parameters handling
    const [searchParams, setSearchParams] = useSearchParams();
    const [order, setOrder] = useState(validateOrder(searchParams.get('order')));
    const [orderBy, setOrderBy] = useState(validateOrderBy(searchParams.get('orderBy')));
    const [page, setPage] = useState(parseInt(searchParams.get('page') || 0, 10));
    const [rowsPerPage, setRowsPerPage] = useState(validateRowsPerPage(parseInt(searchParams.get('rowsPerPage'), 10)));

    useEffect(() => {
        const newParams = { ...Object.fromEntries(searchParams.entries()) };
        newParams.order = order;
        newParams.orderBy = orderBy;
        newParams.page = page.toString();
        newParams.rowsPerPage = rowsPerPage.toString();
        setSearchParams(newParams, { replace: true });
    }, [order, orderBy, page, rowsPerPage, setSearchParams, searchParams]);

    useEffect(() => {
        if (!filterRows.length) return;

        const maxPage = Math.ceil(filterRows.length / rowsPerPage) - 1;
        const newPage = validatePage(page, maxPage);

        if (newPage !== page) {
            setPage(newPage);
        }
    }, [filterRows.length, rowsPerPage, setPage, page]);

    const filterR = useCallback(
        (row) => {
            return (
                row.id.toString().toLowerCase().includes(search.toLowerCase()) ||
                row.name.toLowerCase().includes(search.toLowerCase()) ||
                row.user.username.toLowerCase().includes(search.toLowerCase()) ||
                formatDataSize(row.size).toLowerCase().includes(search.toLowerCase()) ||
                moment(row.datetime).format('MMMM Do YYYY, hh:mm:ss').toLowerCase().includes(search.toLowerCase())
            );
        },
        [search],
    );

    useEffect(() => {
        setFilterRows(rows.filter(filterR));
    }, [search, rows, filterR]);

    useEffect(() => {
        if (search) {
            setPage(0);
        }
    }, [search]);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleClickOnDeleteTask = (id) => {
        onDatasetRemove(id);
    };

    const openDetails = (id) => {
        navigate(getPanelUri(<DataSetsDetails />, { id }));
    };

    return (
        <Box sx={classes.root} data-testid={'tasks-table'}>
            <Paper sx={classes.paper}>
                <div>
                    <EnhancedTableToolbar isLoading={isLoading} title='Generated Snapshots' setSearch={setSearch} />
                    <TableContainer sx={styles.tableContainer}>
                        <Table stickyHeader aria-label='sticky table'>
                            <EnhancedTableHead
                                classes={classes}
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                                rowCount={filterRows.length}
                                staff={rerunPerms || deletePerms}
                                headCells={headCells}
                            />
                            {filterRows && filterRows.length === 0 ? (
                                <LoadingTable isLoading={isLoading} message={'No data matches the search criteria.'} />
                            ) : (
                                <TableBody>
                                    {stableSort(filterRows, getSorting(order, orderBy))
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row, index) => (
                                            <TableRow hover tabIndex={index} key={row?.id}>
                                                <TableCell component='th' scope='row' align={'left'} sx={classes.cell}>
                                                    {row?.id}
                                                </TableCell>
                                                <Tooltip title='Click to see the dataset details' followCursor>
                                                    <TableCell
                                                        align='left'
                                                        onClick={() => openDetails(row?.id)}
                                                        sx={{ ...classes.cell, cursor: 'pointer' }}
                                                        scope='row'
                                                    >
                                                        {row?.name}
                                                    </TableCell>
                                                </Tooltip>
                                                <TableCell align='left' sx={classes.cell}>
                                                    {row?.user?.username}
                                                </TableCell>
                                                <TableCell align='left' sx={classes.cell}>
                                                    {moment(row?.datetime).format('MMMM Do YYYY, hh:mm:ss')}
                                                </TableCell>
                                                <TableCell align='left' sx={classes.cell}>
                                                    {row?.size ? formatDataSize(row.size) : 'N/A'}
                                                </TableCell>
                                                <TableCell align='center' sx={classes.cell}>
                                                    <TaskState states={row?.states} />
                                                </TableCell>
                                                {(rerunPerms || deletePerms) && (
                                                    <TableCell align='center' sx={classes.cell}>
                                                        {rerunPerms && (
                                                            <ConfirmRerunButton
                                                                onClick={() => dsReRun(row)}
                                                                elementTitle={'dataset'}
                                                            />
                                                        )}
                                                        {deletePerms && (
                                                            <ConfirmDeleteButton
                                                                onClick={() => handleClickOnDeleteTask(row?.id)}
                                                                elementTitle={'dataset'}
                                                            />
                                                        )}
                                                    </TableCell>
                                                )}
                                            </TableRow>
                                        ))}
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={validRowsPerPageOptions}
                        component='div'
                        count={filterRows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        showFirstButton
                        showLastButton
                    />
                </div>
            </Paper>
        </Box>
    );
}

const mapStateToProps = (state) => {
    return {
        refreshDelayFetchAll: state.preference.refreshDelay.datasets.fetchAll,
        search: state.dataset.search,
        rerunPerms: state.auth?.user?.perms?.[PermissionManager.rerunDatasetPerm] || state.auth?.user?.staff || false,
        deletePerms: state.auth?.user?.perms?.[PermissionManager.deleteDatasetPerm] || state.auth?.user?.staff || false,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setSearch: (text) =>
            dispatch(
                actions.dataset.search({
                    search: text,
                }),
            ),
    };
};

EnhancedTable.propTypes = {
    rows: PropTypes.array.isRequired,
    onDatasetRemove: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(EnhancedTable);
