import { all } from 'redux-saga/effects';

import watchNotifier from './Notifier';
import watchProcessedData from './Processed/processedData';
import watchAuth from './auth';
import watchData from './data';
import watchDataset from './dataset';
import watchHealth from './health';
import watchPreference from './preference';
import watchQBuilder from './qbuilder';
import watchStats from './stats';

export default function* rootSaga() {
    yield all([
        watchAuth(),
        watchData(),
        watchNotifier(),
        watchPreference(),
        watchHealth(),
        watchQBuilder(),
        watchStats(),
        watchDataset(),
        watchProcessedData(),
    ]);
}
