import { Switch } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';

import { actions } from '../../actions';

function LightSwitch(props) {
    const { lightOn, toggleLight } = props;

    return (
        <Switch
            checked={lightOn}
            onChange={toggleLight}
            color='light'
            data-testid='LightSwitch'
            sx={{
                '& .MuiSwitch-track': {
                    backgroundColor: 'common.black',
                },
                '& .MuiSwitch-thumb': {
                    backgroundColor: 'common.white',
                },
            }}
        />
    );
}

const mapStateToProps = (state) => {
    return {
        lightOn: state.preference.lightOn,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        toggleLight: () => dispatch(actions.preference.toggleLight()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LightSwitch);
