import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { actions } from '../../../actions';
import { api } from '../../../services';
import EnhancedTable from './components/EnhancedTable';

function AnnotationFilterPanel(props) {
    const { isLoading, annotationList, annotationFetchAttr, resetEndpoint } = props;
    const [annotationListLocal, setAnnotationListLocal] = useState([]);

    useEffect(() => {
        setAnnotationListLocal(annotationList);
    }, [annotationList]);

    const resetList = () => {
        resetEndpoint(api.endpoints.annAttributes);
    };
    useEffect(() => {
        resetEndpoint(api.endpoints.annAttributes);
    }, [resetEndpoint]);

    return (
        <EnhancedTable
            rows={annotationListLocal}
            isLoading={isLoading}
            annotationFetchAttr={annotationFetchAttr}
            resetList={resetList}
        />
    );
}

const mapStateToProps = (state) => {
    return {
        annotationList: state.data[api.endpoints.annAttributes]?.data || [],
        isLoading:
            state.data[api.endpoints.annAttributes]?.isFetching ||
            state.data[api.endpoints.annAttributes]?.isUpdating ||
            false,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        annotationFetchAttr: (name, value, type) =>
            dispatch(
                actions.api.data.fetch.request({
                    endpoint: api.endpoints.annAttributes,
                    name: name,
                    value: value,
                    type: type,
                }),
            ),
        resetEndpoint: (endpoint) => dispatch(actions.api.data.reset({ endpoint })),
    };
};
AnnotationFilterPanel.propTypes = {};

export default connect(mapStateToProps, mapDispatchToProps)(AnnotationFilterPanel);
