import Button from '@mui/material/Button';
import React, { useState } from 'react';

const operators = ['OR', 'AND'];

export const OperatorSelector = (props) => {
    const { onChange, defaultValue } = props;
    const valueIdx = operators.findIndex((v) => v === defaultValue);
    const [selectedIdx, setSelectedIdx] = useState(valueIdx);

    const handleClick = () => {
        const newIdx = (selectedIdx + 1) % operators.length;
        setSelectedIdx(newIdx);
        onChange(operators[newIdx]);
    };

    return (
        <Button onClick={handleClick} data-testid={'operator-selector'}>
            {operators[selectedIdx]}
        </Button>
    );
};
