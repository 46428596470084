import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { actions } from '../../../../../actions';
import { api } from '../../../../../services';
import ProjectDetailsPanel from '../../../ProjectDetails';
import { getPanelUri } from '../../../index';
import ProjectForm from './ProjectForm';

const ProjectEditionPanel = (props) => {
    const {
        updateProject,
        projectUpdated,
        resetProject,
        toastSuccess,
        fetchProject,
        project,
        isProcessing,
        projectId,
    } = props;
    const navigate = useNavigate();
    const { id } = useParams();
    const [config, setConfig] = useState(undefined);
    const [submitted, setSubmitted] = useState(false);

    useEffect(() => {
        setSubmitted(false);
        resetProject();
        fetchProject(id);
    }, [resetProject, fetchProject, id, setSubmitted]);

    useEffect(() => {
        console.log(project);
        if (project) {
            setConfig({
                projectName: project.name,
                projectQuery: project.project_query,
                developmentQuery: project.development_query,
                tables: project.config,
            });
        }
    }, [project]);

    useEffect(() => {
        if (projectUpdated && submitted && projectId !== undefined) {
            setSubmitted(false);
            resetProject();
            navigate(getPanelUri(<ProjectDetailsPanel />, { id: projectId }));

            toastSuccess(`Project ${projectId} updated successfully`);
        }
    }, [projectUpdated, resetProject, navigate, toastSuccess, submitted, projectId]);

    const handleSubmit = (name, project_query, development_query, config) => {
        updateProject(name, project_query, development_query, config, id);
        setSubmitted(true);
    };
    return <ProjectForm submitProject={handleSubmit} editMode={true} config={config} isLoading={isProcessing} />;
};

const mapStateToProps = (state) => {
    return {
        projectUpdated: state.data[api.endpoints.dataProject]?.success || false,
        project: state.data[api.endpoints.dataProject]?.data,
        projectId: state.data[api.endpoints.dataProject]?.id,
        isProcessing: state.data[api.endpoints.dataProject]?.isUpdating || false,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateProject: (name, project_query, development_query, config, id) =>
            dispatch(
                actions.api.data.put.request({
                    endpoint: api.endpoints.dataProject,
                    id: id,
                    data: {
                        name: name,
                        project_query: project_query,
                        development_query: development_query,
                        config: config,
                    },
                }),
            ),
        fetchProject: (id) => dispatch(actions.api.data.fetch.request({ endpoint: api.endpoints.dataProject, id })),
        resetProject: () => dispatch(actions.api.data.reset({ endpoint: api.endpoints.dataProject })),
        toastSuccess: (message) => dispatch(actions.notification.add.success(message)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectEditionPanel);
