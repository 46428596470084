import { all, call, put, takeLatest } from 'redux-saga/effects';

import { actions } from '../actions';
import { api } from '../services';
import { retrySaga } from './apiWrapper';

const authSaveUser = (user) => {
    localStorage.setItem('user', JSON.stringify(user));
};

const authRemoveUser = () => {
    localStorage.removeItem('user');
};

const getSavedUser = () => {
    const user = localStorage.getItem('user');
    if (user) {
        return JSON.parse(user);
    }
    return null;
};

function isUserUpdated(prevUser, { staff, perms }) {
    if (prevUser.staff !== staff) {
        return true;
    }
    for (const [key, value] of Object.entries(prevUser.perms)) {
        if (value !== perms[key]) {
            return true;
        }
    }

    for (const [key, value] of Object.entries(perms)) {
        if (prevUser.perms[key] !== value) {
            return true;
        }
    }

    return false;
}

function* authRequest(action) {
    const { username, password } = action.payload;
    try {
        const { data } = yield call(retrySaga, api.auth.fetch, [username, password]);
        const { token, user_id, staff, perms } = data;
        const user = { username, token, user_id, staff, perms };
        yield call(retrySaga, authSaveUser, [user]);
        yield put(actions.api.auth.fetch.success(user));
    } catch (e) {
        yield put(actions.api.auth.fetch.failure(e));
    }
}

function* authCancel() {
    yield call(retrySaga, authRemoveUser);
}

function* validate() {
    try {
        yield call(retrySaga, api.auth.validate);
    } catch (e) {
        yield put(actions.api.auth.validate.failure(e));
    }
}

function* refresh() {
    try {
        const { data } = yield call(retrySaga, api.auth.refresh);
        const { staff, perms } = data;

        const prevUser = getSavedUser();
        if (!prevUser) {
            yield put(actions.api.auth.refresh.failure('No user found'));
            return;
        }

        if (isUserUpdated(prevUser, { staff, perms })) {
            const newUser = { ...prevUser, staff, perms };
            yield call(retrySaga, authSaveUser, [newUser]);
            yield put(actions.api.auth.refresh.success(newUser));
            console.log('User permissions updated.');
        }
    } catch (e) {
        yield put(actions.api.auth.refresh.failure(e));
    }
}

export default function* watchAuth() {
    yield all([
        yield takeLatest(actions.api.auth.fetch.request.toString(), authRequest),
        yield takeLatest(actions.api.auth.fetch.cancel.toString(), authCancel),
        yield takeLatest(actions.api.auth.validate.request.toString(), validate),
        yield takeLatest(actions.api.auth.refresh.request.toString(), refresh),
    ]);
}
