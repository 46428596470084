import { useTheme } from '@emotion/react';
import CheckIcon from '@mui/icons-material/Check';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Button, ListItemIcon, ListItemText, Menu, MenuItem, Stack, Typography } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

export const StatsTableDropdown = ({ stats, attr, setAttr, loading, type }) => {
    const theme = useTheme();
    const dropdownRef = useRef(null);
    const [menuOpen, setMenuOpen] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const anchorRef = React.useRef(null);

    const handleToggle = () => {
        if (!loading) {
            setMenuOpen((prevOpen) => !prevOpen);
        }
    };

    const handleClose = () => {
        setMenuOpen(false);
        setSelectedIndex(-1);
    };

    const handleMenuItemClick = useCallback(
        (value, index) => {
            setAttr(value);
            setSelectedIndex(index);
            handleClose();
        },
        [setAttr],
    );

    const sortedAttributes = useMemo(() => {
        return loading
            ? []
            : Object.keys(stats.stats).sort((a, b) => a.localeCompare(b, undefined, { sensitivity: 'base' }));
    }, [loading, stats.stats]);

    useEffect(() => {
        const handleKeydown = (event) => {
            if (menuOpen && !loading) {
                if (event.key.length === 1) {
                    let key = event.key.toLowerCase();
                    let targetIndex = sortedAttributes.length - 1;

                    for (let i = 0; i < sortedAttributes.length; i++) {
                        if (
                            sortedAttributes[i].toLowerCase().startsWith(key) ||
                            sortedAttributes[i].toLowerCase().charCodeAt(0) > key.charCodeAt(0)
                        ) {
                            targetIndex = i;
                            break;
                        }
                    }
                    setSelectedIndex(targetIndex);
                    const targetItem = dropdownRef.current.querySelectorAll('li')[targetIndex];
                    if (targetItem) {
                        targetItem.scrollIntoView({ behavior: 'smooth', block: 'start' });
                    }
                } else if (event.key === 'ArrowDown') {
                    setSelectedIndex((prevIndex) => {
                        const nextIndex = (prevIndex + 1) % sortedAttributes.length;
                        const targetItem = dropdownRef.current.querySelectorAll('li')[nextIndex];
                        if (targetItem) {
                            targetItem.scrollIntoView({ behavior: 'smooth', block: 'start' });
                        }
                        return nextIndex;
                    });
                } else if (event.key === 'ArrowUp') {
                    setSelectedIndex((prevIndex) => {
                        const nextIndex = (prevIndex - 1 + sortedAttributes.length) % sortedAttributes.length;
                        const targetItem = dropdownRef.current.querySelectorAll('li')[nextIndex];
                        if (targetItem) {
                            targetItem.scrollIntoView({ behavior: 'smooth', block: 'start' });
                        }
                        return nextIndex;
                    });
                } else if (event.key === 'Enter' && selectedIndex !== -1) {
                    handleMenuItemClick(sortedAttributes[selectedIndex], selectedIndex);
                }
            }
        };

        window.addEventListener('keydown', handleKeydown);
        return () => {
            window.removeEventListener('keydown', handleKeydown);
        };
    }, [menuOpen, selectedIndex, sortedAttributes, handleMenuItemClick, loading]);

    useEffect(() => {
        if (!menuOpen || loading) return;
        if (selectedIndex !== -1) return;

        const targetIndex = sortedAttributes.findIndex((at) => at === attr);
        setSelectedIndex(targetIndex);

        setTimeout(() => {
            if (dropdownRef.current) {
                const targetItem = dropdownRef.current.querySelectorAll('li')[targetIndex];
                if (targetItem) {
                    targetItem.scrollIntoView({ behavior: 'smooth', block: 'start' });
                }
            }
        }, 0);
    }, [menuOpen, attr, sortedAttributes, selectedIndex, loading]);

    const styles = {
        button: {
            textTransform: 'none',
            fontWeight: 'bold',
            color: theme.palette.text.primary,
            minWidth: '200px',
            '&:hover': {
                backgroundColor: 'transparent',
            },
        },
        typography: {
            fontSize: '24px',
            fontWeight: 'bold',
        },
        menuList: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            minWidth: '200px',
            paddingTop: 6,
            maxHeight: '40vh',
            overflowY: 'auto',
        },
        dropdown: {
            width: 'auto',
            backgroundColor: theme.palette.background.main,
            overflowX: 'hidden',
            overflowY: 'auto',
        },
    };

    return (
        <Stack direction='row' alignItems='center' spacing={1} ref={anchorRef} data-testid='diagram-zone'>
            <Button onClick={handleToggle} sx={styles.button} endIcon={<ExpandMoreIcon />} disabled={loading}>
                <Typography variant='body1' sx={styles.typography}>
                    {loading ? `Loading ${type}...` : attr}
                </Typography>
            </Button>
            <Menu
                anchorEl={anchorRef.current}
                open={menuOpen}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'select-attribute',
                    sx: styles.menuList,
                    onMouseLeave: handleClose,
                }}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                PaperProps={{
                    style: {
                        background: 'transparent',
                        boxShadow: 'none',
                    },
                }}
            >
                <Box sx={styles.dropdown} ref={dropdownRef} data-testid='diagram-table-dropdown'>
                    {loading ? (
                        <MenuItem disabled>
                            <ListItemText primary='Loading...' />
                        </MenuItem>
                    ) : (
                        sortedAttributes.map((at, index) => (
                            <MenuItem
                                key={at}
                                selected={index === selectedIndex}
                                onClick={() => handleMenuItemClick(at, index)}
                            >
                                <ListItemIcon>{at === attr ? <CheckIcon color='primary' /> : null}</ListItemIcon>
                                <ListItemText primary={at} />
                            </MenuItem>
                        ))
                    )}
                </Box>
            </Menu>
        </Stack>
    );
};
