import { Box, List, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';

// import {a11yDark, a11yLight} from "react-syntax-highlighter/dist/cjs/styles/hljs";
import { actions } from '../../../../actions';
import { denormalizeTree } from '../../../../reducers/qbuilder';
// import {styles} from "./styles";
import ConnectedNode from './FilterTree';

function QueryBuilder(props) {
    const { id, filterType, nodes, setError } = props;

    const theme = useTheme();
    const styles = {
        root: {
            width: '100%',
            backgroundColor: theme.palette.background.paper,
        },
    };

    const classes = styles;

    const [root, setRoot] = useState(null);

    useEffect(() => {
        const foundNode = nodes.find((node) => node.id === id);

        // If the node is not found, then set the error flag to disable the submit button
        if (foundNode !== undefined) {
            setRoot(foundNode);
        } else {
            setError(true);
        }
    }, [id, nodes, setRoot, setError]);

    return (
        root !== null && (
            <Box display='flex' flexDirection='column' data-testid='query-builder-element'>
                <List sx={classes.root}>
                    <ConnectedNode level={0} id={id} key={id} filterType={filterType} />
                </List>
                {process.env.NODE_ENV !== 'production' && (
                    <SyntaxHighlighter data-testid={'debug-json'} language='json' style={theme.material}>
                        {JSON.stringify(denormalizeTree(id, nodes), null, 4)}
                    </SyntaxHighlighter>
                )}
            </Box>
        )
    );
}

QueryBuilder.propTypes = {
    filterType: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    setError: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
    return {
        nodes: state.qbuilder.nodes,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setError: (err) => dispatch(actions.qbuilder.error(err)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(QueryBuilder);
