export const validateBoolean = (value, defaultValue = true) => {
    if (value === 'true' || value === 'false') {
        return value === 'true';
    }
    return defaultValue;
};

const return_default_list = (default_value) => {
    if (default_value) {
        return default_value;
    }
    return [];
};

export const validateBase64List = (value, default_value = null) => {
    if (!value) {
        return return_default_list(default_value);
    }

    try {
        const decoded = atob(value);
        const elt = JSON.parse(decoded);
        if (Array.isArray(elt)) {
            return elt;
        }
        console.log('Decoded base64 list is not an array:', elt);
    } catch (e) {
        console.log('Error parsing base64 list', e);
    }
    return return_default_list(default_value);
};

export const validateBase64Dict = (value, default_value = null) => {
    if (!value) {
        return default_value;
    }

    try {
        const decoded = atob(value);
        const elt = JSON.parse(decoded);
        if (typeof elt === 'object') {
            return elt;
        }
        console.log('Decoded base64 dict is not an object:', elt);
    } catch (e) {
        console.log('Error parsing base64 dict', e);
    }
    return default_value;
};
