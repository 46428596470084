import { Replay } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

import ConfirmButton from './ConfirmButton';

function ConfirmRerunButton(props) {
    const { onClick, elementTitle, disabled } = props;

    return (
        <ConfirmButton
            onClick={onClick}
            title='Are you sure?'
            content={`If you rerun this ${elementTitle}, it will be processed again.`}
            optionYes='Rerun'
            optionFalse='Cancel'
            variantYes='contained'
            variantFalse='outlined'
            colorYes='primary'
            colorFalse='secondary'
            disabled={disabled}
        >
            <Tooltip title={`Rerun this ${elementTitle}`}>
                <Replay />
            </Tooltip>
        </ConfirmButton>
    );
}

ConfirmRerunButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    elementTitle: PropTypes.string.isRequired,
};

export default ConfirmRerunButton;
