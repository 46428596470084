import { useTheme } from '@emotion/react';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SaveIcon from '@mui/icons-material/Save';
import { Box, Fab, IconButton, Link, List, ListItem, Paper, TextField, Tooltip, Typography } from '@mui/material';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Markdown from 'react-markdown';
import { connect } from 'react-redux';
import remarkGfm from 'remark-gfm';
import remarkParse from 'remark-parse';
import remarkSlug from 'remark-slug';
import remarkStringify from 'remark-stringify';
import { unified } from 'unified';
import { visit } from 'unist-util-visit';

import { actions } from '../../../actions';
import { api } from '../../../services';
import ConfirmButton from '../../components/Button/ConfirmButton';
import EnhancedTableToolbar from '../../components/Table/EnhancedTableToolbar';
import { PermissionManager } from '../../utils/Permissions/PermissionManager';

const PAGE_HEADER_HEIGHT = 54;
const DEFAULT_BODY_HEIGHT = 240;
function Information(props) {
    const { fetchInformation, information, saveInformation, updatePerms } = props;
    const theme = useTheme();
    const [showScroll, setShowScroll] = useState(false);
    const [localContent, setLocalContent] = useState('');
    const [editContent, setEditContent] = useState('');
    const [headings, setHeadings] = useState([]);
    const [isEditing, setIsEditing] = useState(false);
    const bodyRef = useRef(null);

    const styles = useMemo(
        () => ({
            root: {
                width: '80%',
                margin: '0 auto',
            },
            title: {
                fontWeight: 'bold',
                paddingTop: theme.spacing(3),
                paddingBottom: theme.spacing(2),
                textAlign: 'center',
            },
            paper: {
                width: '100%',
                position: 'relative',
                boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2)',
                marginBottom: '4px',
            },
            body: {
                padding: '0 2vw 4px 2vw',
                height: `calc(100vh - ${DEFAULT_BODY_HEIGHT - PAGE_HEADER_HEIGHT}px)`,
                overflowY: 'auto',
                '& > *': {
                    margin: '4px 0',
                    padding: '0',
                    whiteSpace: 'normal',
                },
                '& ul': {
                    paddingLeft: '20px',
                },
                '& a': {
                    color: theme.palette.primary.main,
                    textDecoration: 'none',
                    '&:hover': {
                        textDecoration: 'underline',
                    },
                },
                overflowX: 'hidden',
                whiteSpace: 'pre-wrap',
            },
            scrollFab: {
                position: 'fixed',
                bottom: theme.spacing(1),
                right: theme.spacing(1),
            },
            tocItem: {
                padding: '2px',
                margin: 0,
                listStyleType: 'none',
            },
            tocLink: {
                textDecoration: 'none',
                '&:hover': {
                    textDecoration: 'underline',
                    color: theme.palette.secondary.main,
                },
            },
            toc: {},
            tocContainer: {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
            },
            tocTitle: {
                fontWeight: 'bold',
            },
            separator: {
                border: `1px solid ${theme.palette.divider}`,
            },
            editButtons: {
                position: 'fixed',
                bottom: theme.spacing(1),
                left: '50%',
                transform: 'translateX(-50%)',
                display: 'flex',
                gap: theme.spacing(1),
                zIndex: 1000,
            },
            textField: {
                width: '100%',
                marginBottom: theme.spacing(4),
            },
            editButton: {
                position: 'absolute',
                top: theme.spacing(1),
                right: theme.spacing(1),
            },
        }),
        [theme],
    );

    const checkScrollTop = useCallback(() => {
        if (!bodyRef.current) {
            return;
        }
        setShowScroll(bodyRef.current.scrollTop > 100);
    }, []);

    const scrollTop = () => {
        if (bodyRef.current) {
            bodyRef.current.scrollTo({ top: 0, behavior: 'smooth' });
        }
    };

    useEffect(() => {
        if (!localContent) {
            return;
        }
        unified()
            .use(remarkParse)
            .use(remarkSlug)
            .use(remarkGfm)
            .use(() => (tree) => {
                const headings = [];
                visit(tree, 'heading', (node) => {
                    if (node.depth >= 1 && node.depth <= 3) {
                        let headerValue = node.children[0].value;
                        if (node.children[0].type === 'link') {
                            node.children[0].children.forEach((child) => {
                                if (child.type === 'text') {
                                    headerValue = child.value;
                                }
                            });
                        }
                        headings.push({
                            value: headerValue,
                            slug: node.data.id,
                            depth: node.depth,
                        });
                    }
                });
                setHeadings(headings);
            })
            .use(remarkStringify)
            .processSync(localContent);
    }, [localContent]);

    useEffect(() => {
        if (information.content) setLocalContent(information.content);
    }, [information.content]);

    useEffect(() => {
        const scrollableElement = bodyRef.current;
        if (scrollableElement) {
            scrollableElement.addEventListener('scroll', checkScrollTop);
        }
        return () => {
            if (scrollableElement) {
                scrollableElement.removeEventListener('scroll', checkScrollTop);
            }
        };
    }, [showScroll, checkScrollTop]);

    useEffect(() => {
        fetchInformation();
    }, [fetchInformation]);

    const createTOCEntry = useCallback(
        (headings) => {
            const tocEntries = [];
            const levelCounters = [0, 0, 0];

            headings.forEach((heading) => {
                levelCounters[heading.depth - 1]++;

                for (let i = heading.depth; i < levelCounters.length; i++) {
                    levelCounters[i] = 0;
                }

                const prefix = levelCounters.slice(0, heading.depth).join('.') + '. ';

                tocEntries.push(
                    <ListItem key={heading.slug} sx={{ ...styles.tocItem, ml: (heading.depth - 1) * 2 }}>
                        <Link href={`#${heading.slug}`} color='inherit' sx={styles.tocLink}>
                            {prefix + heading.value}
                        </Link>
                    </ListItem>,
                );
            });

            return tocEntries;
        },
        [styles],
    );

    const TableOfContents = useMemo(() => {
        if (headings.length === 0) {
            return null;
        }

        return (
            <Box sx={styles.tocContainer}>
                <Typography variant='h6' sx={styles.tocTitle}>
                    Table of Contents
                </Typography>
                <List>{createTOCEntry(headings)}</List>
            </Box>
        );
    }, [headings, styles, createTOCEntry]);

    const handleEdit = () => {
        setIsEditing(true);
        setEditContent(localContent);
    };

    const handleSave = () => {
        setIsEditing(false);
        saveInformation(editContent);
    };

    const handleCancel = () => {
        setIsEditing(false);
        setEditContent('');
    };

    return (
        <Paper sx={styles.paper}>
            <EnhancedTableToolbar isLoading={false} title='Information' margin={'no-m'} />
            {!isEditing && updatePerms ? (
                <IconButton color='primary' onClick={handleEdit} sx={styles.editButton}>
                    <EditIcon />
                </IconButton>
            ) : null}
            <Box sx={styles.body} ref={bodyRef}>
                {isEditing && updatePerms ? (
                    <>
                        <TextField
                            sx={styles.textField}
                            multiline
                            value={editContent}
                            onChange={(e) => setEditContent(e.target.value)}
                        />
                        <Box sx={styles.editButtons}>
                            {(editContent !== localContent && (
                                <>
                                    <ConfirmButton
                                        onClick={handleSave}
                                        title='Save changes?'
                                        content={`Are you sure you want to save the changes?`}
                                        colorYes='primary'
                                        colorFalse='error'
                                        optionYes='Save'
                                        optionFalse='No'
                                    >
                                        <Tooltip title='Save'>
                                            <SaveIcon color={'primary'} />
                                        </Tooltip>
                                    </ConfirmButton>
                                    <ConfirmButton
                                        onClick={handleCancel}
                                        title='Cancel changes?'
                                        content={`Are you sure you want to cancel the changes?`}
                                        colorYes='primary'
                                        colorFalse='error'
                                    >
                                        <Tooltip title='Cancel'>
                                            <CancelIcon color={'error'} />
                                        </Tooltip>
                                    </ConfirmButton>
                                </>
                            )) || (
                                <Tooltip title='Cancel'>
                                    <IconButton color='secondary' onClick={handleCancel}>
                                        <CancelIcon color={'error'} />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </Box>
                    </>
                ) : (
                    <>
                        {TableOfContents}
                        <hr style={styles.separator} />
                        <Markdown remarkPlugins={[remarkSlug, remarkGfm]} children={localContent} />
                    </>
                )}

                <Typography
                    variant='body2'
                    color='textSecondary'
                    sx={{ position: 'absolute', left: 8, top: 4, fontSize: 12 }}
                >
                    {moment(information?.created_at).format('MMMM Do YYYY, hh:mm:ss')} by {information?.user}
                </Typography>
            </Box>
            {showScroll && (
                <Tooltip title='Scroll back to top' placement='left'>
                    <Fab color='secondary' size='small' sx={styles.scrollFab} onClick={scrollTop}>
                        <KeyboardArrowUpIcon />
                    </Fab>
                </Tooltip>
            )}
        </Paper>
    );
}

const mapStateToProps = (state) => {
    return {
        information: state.data[api.endpoints.information]?.data || {},
        updatePerms:
            state.auth?.user?.perms?.[PermissionManager.updateInformationPerm] || state.auth?.user?.staff || false,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchInformation: () =>
            dispatch(
                actions.api.data.fetch.request({
                    endpoint: api.endpoints.information,
                }),
            ),
        saveInformation: (content) =>
            dispatch(
                actions.api.data.post.request({
                    endpoint: api.endpoints.information,
                    data: { content: content },
                }),
            ),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Information);
