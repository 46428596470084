import { Buffer } from 'buffer';
import unpickle from 'unpickle';

import { api } from '../../../services';

export const processDsTasks = (state, action, data) => {
    const celeryMapping = (state[api.endpoints.celery] || {}).taskMapping || [];
    const dsCompleteCountMapping = (state[api.endpoints.dsCount] || {}).countMapping || [];

    const dstl = structuredClone(data);

    dstl.forEach((d) => {
        d['states'] = {};
        const dc = { ...celeryMapping[d.task_id_compress] };

        dc['state'] = dc['status'];
        d['states']['COMPRESS'] = dc;

        let dbq = { ...celeryMapping[d.task_id_db_query] };

        if (!dbq.status) {
            d['states'] = undefined;
            return;
        }

        try {
            if (dbq?.result) {
                dbq['result'] = unpickle(Buffer.from(dbq.result, 'base64'));
            }
        } catch {
            dbq['result'] = null;
        }
        dbq['state'] = dbq['status'];
        d['states']['DB_QUERY'] = dbq;

        const dsb = { ...celeryMapping[d.task_id_ds_build] };
        try {
            if (dsb?.result) {
                dsb['result'] = unpickle(Buffer.from(dsb.result, 'base64'));
            }
        } catch {
            dsb['result'] = null;
        }
        dsb['state'] = dsb['status'];
        dsb['result'] = dsb?.['result']?.['url'];

        dsb.progress = 0;
        if (dsCompleteCountMapping[d.id]) dsb.progress = (dsCompleteCountMapping?.[d.id] || 0) / d.matched;
        dsb.total = d.matched;

        d['states']['DS_BUILD'] = dsb;
    });

    return {
        data: dstl,
    };
};
