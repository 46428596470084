import React from 'react';
import { connect } from 'react-redux';

import { actions } from '../../../../actions';
import { api } from '../../../../services';
import { ChartUrlStateHandler } from './components/ChartUrlStateHandler';
import './styles/style.css';

const SegmentAttributesStatsAnnotations = (props) => {
    return <ChartUrlStateHandler {...props} segmentMode />;
};

const mapStateToProps = (state) => ({
    stats: state.data[api.endpoints.segmentStatistics]?.data || {},
});

const mapDispatchToProps = (dispatch) => ({
    statsFetch: () => dispatch(actions.api.data.fetch.request({ endpoint: api.endpoints.segmentStatistics })),
});

export default connect(mapStateToProps, mapDispatchToProps)(SegmentAttributesStatsAnnotations);
