import React from 'react';
import { connect } from 'react-redux';

import { actions } from '../../../../actions';
import { api } from '../../../../services';
import { ChartUrlStateHandler } from './components/ChartUrlStateHandler';
import './styles/style.css';

const LabelAttributesStatsAnnotations = (props) => {
    return <ChartUrlStateHandler {...props} />;
};

const mapStateToProps = (state) => ({
    stats: state.data[api.endpoints.attrStatistics]?.data || {},
});

const mapDispatchToProps = (dispatch) => ({
    statsFetch: () => dispatch(actions.api.data.fetch.request({ endpoint: api.endpoints.attrStatistics })),
});

export default connect(mapStateToProps, mapDispatchToProps)(LabelAttributesStatsAnnotations);
