import { AVAILABLE_TASKS, STATE_FAILURE, STATE_SUCCESS } from '../../panels/DataSets/components/TaskState';

export const isTaskPending = (task) => {
    let nbSuccess = 0;
    const states = Object.values(AVAILABLE_TASKS).map((taskName) => task?.states?.[taskName]?.state);

    for (const state of states) {
        if (state === STATE_FAILURE) {
            return false;
        }
        nbSuccess += state === STATE_SUCCESS;
    }

    return nbSuccess < states.length;
};
