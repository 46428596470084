import { put as putEffect } from '@redux-saga/core/effects';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';

import { actions } from '../actions';
import { api } from '../services';
import { retrySaga } from './apiWrapper';

const getEndpointState = (state, endpoint) => state.data[endpoint];

function* setBusy(endpoint, force) {
    const endpointState = yield select(getEndpointState, endpoint);

    if (endpointState?.isBusy) {
        if (force) {
            console.log('Request for `' + endpoint + '` is already pending. Forcing the new request.');
        } else {
            console.log('Request for `' + endpoint + '` is already pending. Aborting.');
            return false;
        }
    }

    yield putEffect(actions.api.data.busy({ endpoint }));

    return true;
}

function* dataFetch(action) {
    const {
        endpoint,
        id,
        datetime,
        path,
        page,
        hash,
        size,
        name,
        value,
        active,
        q,
        force,
        type,
        status,
        project_id,
        warning_code,
        user,
        start_date,
        end_date,
        refresh,
    } = action.payload;

    if (!(yield call(setBusy, endpoint, force))) {
        return;
    }

    try {
        const { data } = yield call(retrySaga, api.data.fetch, [
            endpoint,
            id,
            datetime,
            path,
            page,
            hash,
            size,
            name,
            value,
            active,
            q,
            type,
            status,
            project_id,
            warning_code,
            user,
            start_date,
            end_date,
            refresh,
        ]);
        yield put(actions.api.data.fetch.success({ endpoint, data }));
    } catch (e) {
        yield put(actions.api.data.fetch.failure({ endpoint, id, error: e }));
    }
}

function* dataUpdate(action) {
    const { endpoint, id, datetime, force } = action.payload;
    if (!(yield call(setBusy, endpoint, force))) {
        return;
    }
    try {
        const { data } = yield call(retrySaga, api.data.fetch, [endpoint, id, datetime]);
        yield put(actions.api.data.update.success({ endpoint, data: Array.isArray(data) ? data : [data] }));
    } catch (e) {
        yield put(actions.api.data.update.failure({ endpoint, id, error: e }));
        if (e.response?.status === 401) {
            yield put(actions.api.auth.validate.failure(e));
        }
    }
}

function* remove(action) {
    const { endpoint, id } = action.payload;
    try {
        yield call(retrySaga, api.data.remove, [endpoint, id]);
        yield put(actions.api.data.remove.success({ endpoint, id }));
    } catch (e) {
        yield put(actions.api.data.remove.failure({ endpoint, id, error: e }));
    }
}

function* dataPut(action) {
    const { endpoint, id, data, params, noid } = action.payload;
    try {
        const response = yield call(retrySaga, api.data.put, [endpoint, id, data, noid, params]);
        yield put(actions.api.data.put.success({ endpoint, id, data, params, response }));
    } catch (e) {
        yield put(actions.api.data.put.failure({ endpoint, id, data, params, error: e }));
    }
}

function* dataPost(action) {
    const { endpoint, data, params } = action.payload;
    try {
        const response = yield call(retrySaga, api.data.post, [endpoint, data, params]);
        yield put(actions.api.data.post.success({ endpoint, data: response.data, params }));
    } catch (e) {
        yield put(actions.api.data.post.failure({ endpoint, data, params, error: e }));
    }
}

// function *trackEndpoint(action) {
//     const {endpoint, freq} = action.payload;
//     const {update: updatePartial, fetch: updateComplete} = actions.api.data;

//     let nbPartialUpdate = 0;

//     while (true) {
//         const endpointTracker = yield select(state => ({
//             dataEndpoint: state.data[endpoint],
//             nbPartialUpdateBeforeCompleteUpdate: state.preference.dataTracker.nbPartialUpdateBeforeCompleteUpdate,
//             partialUpdateInterval: state.preference.dataTracker.partialUpdateInterval,
//         }));
//         console.log(endpointTracker)

//         // check if endpoint has been removed from tracking
//         if (endpointTracker.dataEndpoint === undefined)
//             break;

//         if ((nbPartialUpdate % endpointTracker.nbPartialUpdateBeforeCompleteUpdate) === 0)
//             yield put(updateComplete.request({endpoint}));
//         else
//             yield put(updatePartial.request({endpoint, datetime: endpointTracker.dataEndpoint.lastUpdate}));

//         yield delay(freq || endpointTracker.partialUpdateInterval);

//         nbPartialUpdate++;
//     }
// }

function* trackEndpoint(action) {
    const { endpoint } = action.payload;
    const { fetch: updateComplete } = actions.api.data;
    yield put(updateComplete.request({ endpoint }));
}

export default function* watchData() {
    yield all([
        yield takeEvery(actions.api.data.fetch.request.toString(), dataFetch),
        yield takeEvery(actions.api.data.update.request.toString(), dataUpdate),
        yield takeEvery(actions.api.data.remove.request.toString(), remove),
        yield takeEvery(actions.api.data.put.request.toString(), dataPut),
        yield takeEvery(actions.api.data.tracker.add.toString(), trackEndpoint),
        yield takeEvery(actions.api.data.post.request.toString(), dataPost),
    ]);
}
