import AccountTreeIcon from '@mui/icons-material/AccountTree';
import AssessmentIcon from '@mui/icons-material/Assessment';
import BubbleChartIcon from '@mui/icons-material/BubbleChart';
import FolderIcon from '@mui/icons-material/Folder';
import PolylineIcon from '@mui/icons-material/Polyline';
import VideocamIcon from '@mui/icons-material/Videocam';
import {
    Box,
    ButtonGroup,
    CircularProgress,
    Divider,
    IconButton,
    Paper,
    Stack,
    ToggleButton,
    ToggleButtonGroup,
    Tooltip as TooltipMui,
    Typography,
} from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { Area, AreaChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { actions } from '../../../../actions';
import { api } from '../../../../services';
import { timeRanges, validateSelectedTimeRange } from '../utils/DateRangeFilters';

const StatsAnnotations = (props) => {
    const theme = useTheme();

    const styles = {
        paper: {
            width: '100%',
            position: 'relative',
            boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2)',
            minWidth: '600px',
            marginBottom: '4px',
        },
        progress: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '400px',
        },
        barChartWrapper: {
            position: 'relative',
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
        },
        filterButton: {
            marginLeft: '10px',
            marginBottom: '10px',
        },
        chartBox: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-end',
        },
        chart: {
            flex: '1',
        },
        listItems: {
            marginRight: '10px',
        },
        tooltip: {
            backgroundColor: theme.palette.common.verylightgray,
            borderRadius: '4px',
            color: theme.palette.common.black,
            fontSize: '14px',
            fontWeight: 'bold',
        },
        chartMargin: { top: 5, right: 30, left: 50, bottom: 5 },
        center: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
        },
        typography: {
            fontSize: '24px',
            fontWeight: 'bold',
        },
    };

    const { stats, statsFetch } = props;
    const [searchParams, setSearchParams] = useSearchParams();

    const annotationStatsSettings = {
        video: <VideocamIcon />,
        total: <AssessmentIcon />,
        polyp: <BubbleChartIcon />,
        caecum: <AccountTreeIcon />,
        polygon: <PolylineIcon />,
        annotation_upload_form: <FolderIcon />,
    };

    const validAnnotationStats = Object.keys(annotationStatsSettings);

    const validateAnnotationStats = (order) => (validAnnotationStats.includes(order) ? order : 'video');
    const validateAutoMinY = (value) => value !== 'false';

    const [data, setData] = useState([]);
    const [minDate, setMinDate] = useState(new Date(0));

    const [selectedTimeRange, setSelectedTimeRange] = useState(
        validateSelectedTimeRange(searchParams.get('time_range')),
    );

    const [useAutoMinY, setUseAutoMinY] = useState(validateAutoMinY(searchParams.get('auto_min_y')));

    const [annotationStats, setAnnotationStats] = useState(
        validateAnnotationStats(searchParams.get('annotation_stats')),
    );

    useEffect(() => {
        const newParams = { ...Object.fromEntries(searchParams.entries()) };
        newParams.annotation_stats = annotationStats;
        newParams.auto_min_y = useAutoMinY.toString();
        newParams.time_range = selectedTimeRange;
        setSearchParams(newParams, { replace: true });
    }, [annotationStats, setSearchParams, searchParams, selectedTimeRange, useAutoMinY]);

    const maxValue = Math.max(...data.map((item) => item.count));
    const minValue = Math.min(...data.map((item) => item.count));
    const offset = (maxValue - minValue) * 0.05;

    useEffect(() => {
        statsFetch(annotationStats);
    }, [statsFetch, annotationStats]);

    useEffect(() => {
        if (!stats || !stats.length) {
            return;
        }

        setMinDate(new Date(stats[0]._id));
        const startDate = timeRanges[selectedTimeRange]();
        const filteredData = stats
            .filter((item) => {
                const itemDate = new Date(item._id);
                return itemDate >= startDate;
            })
            .map((item) => ({
                ...item,
                count: Number(item.count),
            }));
        setData(filteredData);
    }, [stats, selectedTimeRange]);

    const classes = styles;

    const handleFilterChange = (newFilter) => {
        setAnnotationStats(newFilter);
    };

    if (!data) {
        return (
            <Paper sx={classes.paper}>
                <Box sx={classes.progress}>
                    <CircularProgress data-testid='graph-progress' />
                </Box>
            </Paper>
        );
    }

    const nameToChange = {
        total: 'frame',
        video: 'annotation',
    };

    const getMatchingName = (name) => {
        const lowerCaseName = name.toLowerCase();
        if (nameToChange[lowerCaseName]) {
            return nameToChange[lowerCaseName];
        }
        return name;
    };
    const getLegendName = (name) => {
        return `Number of ${getMatchingName(name)}s`;
    };

    const generateDateTicks = (data, intervalDays) => {
        if (data.length === 0) {
            return [];
        }
        const startDate = new Date(data[0]._id);
        const endDate = new Date(data[data.length - 1]._id);
        const dateTicks = [];

        for (let date = new Date(startDate); date <= endDate; date.setDate(date.getDate() + intervalDays)) {
            dateTicks.push(new Date(date).getTime());
        }

        return dateTicks;
    };

    const getFormattedName = (name) => {
        name = getMatchingName(name);
        return name.replace(/_/g, ' ').replace(/\b\w/g, (l) => l.toUpperCase());
    };
    const handleTimeRange = (event, newTimeRange) => {
        if (newTimeRange !== null) {
            setSelectedTimeRange(newTimeRange);
        }
    };
    return (
        <Paper sx={styles.paper}>
            <Box sx={styles.barChartWrapper}>
                <Stack direction='row' alignItems='center' justifyContent='center' spacing={1}>
                    <Box sx={styles.filterButton}>
                        <Typography variant='body1' sx={styles.typography}>
                            Annotation Metrics
                        </Typography>
                    </Box>
                </Stack>
                <Box sx={styles.center}>
                    <ButtonGroup color='primary' aria-label='outlined primary button group'>
                        {Object.keys(annotationStatsSettings).map((order) => (
                            <TooltipMui title={getFormattedName(order)} key={order}>
                                <IconButton
                                    onClick={() => handleFilterChange(order)}
                                    color={annotationStats === order ? 'primary' : 'default'}
                                >
                                    {annotationStatsSettings[order]}
                                </IconButton>
                            </TooltipMui>
                        ))}
                    </ButtonGroup>
                </Box>
                <Box sx={styles.chartBox}>
                    <Box sx={styles.chart}>
                        <ResponsiveContainer width='94%' aspect={2.8}>
                            <AreaChart data={data} margin={styles.chartMargin}>
                                <CartesianGrid strokeDasharray='3 3' stroke={theme.palette.common.lightgray} />
                                <XAxis
                                    stroke={theme.palette.common.black}
                                    dataKey='_id'
                                    scale='time'
                                    type='number'
                                    domain={['dataMin', 'dataMax']}
                                    tickFormatter={(unixTime) => new Date(unixTime).toLocaleDateString()}
                                    ticks={generateDateTicks(data, 3)}
                                />
                                <YAxis
                                    domain={[
                                        useAutoMinY ? Math.max(0, Math.floor(minValue - offset - 1)) : 0,
                                        Math.max(1, Math.ceil(maxValue + offset + 1)),
                                    ]}
                                />

                                <Tooltip
                                    contentStyle={styles.tooltip}
                                    wrapperStyle={{ outline: 'none' }}
                                    cursor={{ fill: theme.palette.common.mediumlightgray }}
                                    animationEasing='ease-in-out'
                                    animationDuration={200}
                                    position={{ y: 50 }}
                                    formatter={(value, name, props) => {
                                        return [value, getLegendName(getFormattedName(annotationStats))];
                                    }}
                                    labelFormatter={(label) => {
                                        return new Date(label).toLocaleDateString();
                                    }}
                                />
                                <Legend
                                    wrapperStyle={{
                                        color: theme.palette.common.black,
                                        fontSize: '14px',
                                        paddingTop: '8px',
                                    }}
                                    payload={[
                                        {
                                            value: getLegendName(getFormattedName(annotationStats)),
                                            type: 'line',
                                            id: 'count',
                                            color: theme.palette.primary.main,
                                        },
                                    ]}
                                />
                                <Area
                                    type='monotone'
                                    dataKey='count'
                                    stroke={theme.palette.primary.main}
                                    activeDot={{ r: 8 }}
                                    isAnimationActive={true}
                                />
                            </AreaChart>
                        </ResponsiveContainer>
                    </Box>
                </Box>
                <Divider sx={{ width: '100%', my: 2 }} />
                <Box sx={styles.center}>
                    <Stack direction='row' spacing={4} alignItems='center'>
                        <ToggleButton
                            value='check'
                            size='small'
                            selected={useAutoMinY}
                            onChange={() => {
                                setUseAutoMinY(!useAutoMinY);
                            }}
                            sx={{ border: 'none' }}
                        >
                            Y-axis Auto
                        </ToggleButton>
                        <ToggleButtonGroup
                            value={selectedTimeRange}
                            exclusive
                            onChange={handleTimeRange}
                            aria-label='time range'
                            size='small'
                            sx={{
                                '.MuiToggleButton-root': {
                                    border: 'none',
                                    '&:not(:first-of-type)': {
                                        borderLeft: '1px solid',
                                        borderColor: 'divider',
                                    },
                                },
                            }}
                        >
                            {Object.keys(timeRanges).map((range) => {
                                const date = timeRanges[range]();
                                if (date < minDate && range !== 'All Time') {
                                    return null;
                                }
                                return (
                                    <ToggleButton key={range} value={range} aria-label={range.toLowerCase()}>
                                        {range}
                                    </ToggleButton>
                                );
                            })}
                        </ToggleButtonGroup>
                    </Stack>
                </Box>
            </Box>
        </Paper>
    );
};

const mapStateToProps = (state) => {
    return {
        stats: state.data[api.endpoints.annStatistics]?.data || {},
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        statsFetch: (type) => {
            return dispatch(
                actions.api.data.fetch.request({
                    endpoint: api.endpoints.annStatistics,
                    type,
                }),
            );
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(StatsAnnotations);
