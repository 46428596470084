export function formatDataSize(bytes) {
    if (bytes >= 1e9) {
        // 1 GB in bytes
        return (bytes / 1e9).toFixed(2) + ' GB';
    } else if (bytes >= 1e6) {
        // 1 MB in bytes
        return (bytes / 1e6).toFixed(2) + ' MB';
    } else if (bytes >= 1e3) {
        // 1 KB in bytes
        return (bytes / 1e3).toFixed(2) + ' KB';
    } else {
        return bytes + ' bytes'; // Less than 1 KB
    }
}
