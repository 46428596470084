import { useTheme } from '@emotion/react';
import { Box } from '@mui/material';
import React from 'react';
import { Tooltip } from 'recharts';

import { getToolTipStyles } from '../styles/StyleComponent';
import { OTHER_ROW, OTHER_ROW_LABEL, getFormattedName } from '../utils';

export const ChartTooltipContent = (props) => {
    const { active, payload, label, selectedKeys, hoveredIndex, selectedLabel, selectedName, filter, chartType } =
        props;
    const theme = useTheme();
    const styles = getToolTipStyles(theme);

    if (active && payload && payload.length) {
        const hoveredItem = payload.find((item) => item.dataKey === selectedKeys[hoveredIndex]);

        let items = payload;
        if (hoveredItem) {
            items = [hoveredItem];
        } else if (chartType === 'area') {
            return null;
        }

        // Sort items by value
        items.sort((a, b) => b.value - a.value);

        const columns = Math.ceil(items.length / 15);
        const gridStyle = {
            display: 'grid',
            gridTemplateColumns: `repeat(${columns}, 1fr)`,
            gap: '10px',
        };

        return (
            <Box className='custom-tooltip' sx={styles.tooltip}>
                <p className='label' style={styles.tooltipTitle}>
                    Number of {getFormattedName(filter)} ({new Date(label).toLocaleDateString()})
                </p>
                <span
                    style={{
                        fontStyle: 'italic',
                        fontSize: '12px',
                        fontWeight: 'normal',
                    }}
                >
                    {selectedLabel ? <>{selectedLabel}</> : ''}
                    {selectedLabel && selectedName ? <> & </> : ''}
                    {selectedName ? <>{selectedName}</> : ''}
                </span>
                <Box sx={gridStyle}>
                    {items.map((item) => (
                        <p
                            key={item.dataKey}
                            style={{
                                ...styles.toolTipBody,
                                color: item.color,
                            }}
                        >
                            {item.dataKey === OTHER_ROW ? OTHER_ROW_LABEL : item.dataKey}:{' '}
                            {item.value?.toLocaleString('en-GB') || 0}
                        </p>
                    ))}
                </Box>
            </Box>
        );
    }
    return null;
};

export const getChartTooltip = (props) => {
    return (
        <Tooltip
            content={<ChartTooltipContent {...props} />}
            wrapperStyle={{ outline: 'none' }}
            cursor={{ fill: 'rgba(255, 255, 255, 0.5)' }}
            animationEasing='ease-in-out'
            animationDuration={200}
        />
    );
};
