import { api } from '../../../services';
import { generateHashFromData } from '../../../services/utils/annotation';

export const processAnnList = (state, action, init_data) => {
    const groupedData = {};

    const data = init_data?.results || [];
    const existingData = state[api.endpoints.annList]?.data;

    const dataHash = generateHashFromData(data);
    const existingHash = generateHashFromData(existingData);

    if (existingData && dataHash === existingHash && state[api.endpoints.annList]?.everything === true) {
        return state[api.endpoints.annList];
    }

    if (init_data.active) {
        const current_state = state[api.endpoints.annList] || {};

        if (current_state?.everything === true) {
            return current_state;
        }

        return {
            data: data,
            mergedData: data,
            everything: false,
            key: dataHash,
        };
    }

    // Group entries by video_hash
    data.forEach((entry) => {
        if (!groupedData[entry.video_hash]) {
            groupedData[entry.video_hash] = {
                video_hash: entry.video_hash,
                versions: [],
                nb_versions: 0,
                active_version: 0,
                active_version_index: 0,
                is_deleted: true,
                is_processing: false,
            };
        }
        groupedData[entry.video_hash].versions.push({ ...entry }); // Create a shallow copy of the entry
        groupedData[entry.video_hash].nb_versions++;
    });

    // Convert the groupedData object to an array and sort the versions for each hash by updating date
    const mergedData = Object.values(groupedData).map((item) => {
        item.versions.sort((a, b) => new Date(a.date_update) - new Date(b.date_update));
        return item;
    });

    const setDetails = (item, version, index) => {
        item.active_version = version.version;
        item.active_version_index = index;
        item.user = version.user;
        // item.id = version.id;
        item.date_creation = version.date_creation;
        item.date_update = version.date_update;
        item.date_apply = version.date_apply;
        item.state = version.state;
    };

    // Add the version number to each version
    mergedData.forEach((item) => {
        item.versions.forEach((version, index) => {
            version.version = index + 1;
            if (version.active) {
                setDetails(item, version, index);
                item.is_deleted = false;
            }

            if (version.is_processing) {
                item.is_processing = true;
            }
        });
        if (!item.active_version) {
            setDetails(item, item.versions[item.versions.length - 1], item.versions.length - 1);
        }
    });

    return {
        data: data,
        mergedData: mergedData,
        everything: true,
        key: dataHash,
    };
};
