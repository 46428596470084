import { useSnackbar } from 'notistack';
import { useEffect, useRef } from 'react';
import { connect } from 'react-redux';

import { actions } from '../../actions';

/**
 * @return {null}
 */
function Notifier(props) {
    const { enqueueSnackbar } = useSnackbar();
    const { notification } = props;

    const displayed = useRef(new Set());

    const removeSnackbar = (id) => {
        displayed.current.delete(id);
    };

    const addSnackbar = (id) => {
        displayed.current.add(id);
    };

    useEffect(() => {
        notification.forEach(({ key, message, options }) => {
            if (displayed.current.has(key)) return;
            enqueueSnackbar(message, {
                key,
                ...options,
                onExited: (event, key) => {
                    props.removeNotification(key);
                    removeSnackbar(key);
                },
            });
            addSnackbar(key);
        });
    });

    return null;
}

const mapStateToProps = (state) => {
    return {
        notification: state.notification,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        removeNotification: (key) => dispatch(actions.notification.remove(key)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifier);
