import { useTheme } from '@emotion/react';
import { useMediaQuery } from '@mui/material';
import React from 'react';
import { PolarAngleAxis, PolarGrid, PolarRadiusAxis, Radar, RadarChart, ResponsiveContainer } from 'recharts';

import { PROJECT_ENVIRONMENTS } from '../panels/ProjectCreation/constant';
import '../styles/graph.css';

function ProjectGraph(props) {
    const { graph, graphMaxLength, graphFontSize } = props;
    const theme = useTheme();

    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));
    const isLargeScreen = useMediaQuery(theme.breakpoints.between('md', 'lg'));
    const isVeryLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

    const getFontSize = () => {
        if (graphMaxLength) return graphFontSize;

        if (isSmallScreen) return '1.9vw';
        if (isMediumScreen) return '1.5vw';
        if (isLargeScreen) return '1.1vw';
        if (isVeryLargeScreen) return '0.7vw';
        return '0.8vw';
    };

    const getMaxLabelLength = () => {
        if (graphMaxLength) return graphMaxLength;

        if (isSmallScreen) return 15;
        if (isMediumScreen) return 15;
        if (isLargeScreen) return 15;
        if (isVeryLargeScreen) return 15;
        return 18;
    };

    const renderCustomizedTick = (props) => {
        const { x, y, payload, cx, cy } = props;
        const element = payload?.value;

        if (!element) {
            return null;
        }
        let lines = [];
        const maxLineLength = getMaxLabelLength();
        const inLineElement = ['(', '-'];

        if (element.length < maxLineLength) {
            lines = [element];
        } else {
            const words = element.split(' ');
            let line = '';

            words.forEach((word, index) => {
                if (line.length + word.length < maxLineLength) {
                    if (inLineElement.some((el) => word.includes(el))) {
                        lines.push(line.trim());
                        line = '';
                    }
                    line += `${word} `;
                } else {
                    lines.push(line.trim());
                    line = `${word} `;
                }
            });

            if (line.trim()) {
                lines.push(line.trim());
            }

            if (lines.length === 2) {
                const maxLength = Math.max(lines[0].trim().length, lines[1].trim().length);
                lines = lines.map((line) => {
                    const spacesNeeded = maxLength - line.trim().length;
                    const leftPadding = '\u00A0'.repeat(Math.floor(spacesNeeded / 2) * 1.5);
                    const rightPadding = '\u00A0'.repeat(Math.ceil(spacesNeeded / 2) * 1.5);
                    return `${leftPadding}${line.trim()}${rightPadding}`;
                });
            }
        }

        const dx = x - cx;
        const dy = y - cy;
        const angle = Math.round(Math.atan2(dy, dx) * (180 / Math.PI));

        let adjustedX = x;
        let adjustedY = y;
        let anchor = 'middle';

        const labelOffsetX = 3;
        const labelOffsetY = 10;

        const absAngle = Math.abs(angle);
        const tmpAngle = absAngle > 90 ? 180 - absAngle : absAngle;
        const tempCoef = Math.abs(tmpAngle) / 90;
        const oppositeCoef = 1 - tempCoef;

        if (angle < 90 && angle > -90) {
            adjustedX += labelOffsetX * oppositeCoef;
            anchor = 'start';
        }

        if (angle > 90 || angle < -90) {
            adjustedX -= labelOffsetX * oppositeCoef;
            anchor = 'end';
        }

        if (angle < 180 && angle > 0) {
            adjustedY += labelOffsetY * tempCoef;
            if (tempCoef > 0.8) {
                adjustedY += 15;
            }
        }

        if (angle < 0 && angle > -180) {
            adjustedY -= labelOffsetY * tempCoef;
            if (tempCoef > 0.8) {
                adjustedY -= 20;
            }
        }

        return (
            <text
                x={adjustedX}
                y={adjustedY}
                fill={theme.palette.common.black}
                textAnchor={anchor}
                fontSize={getFontSize()}
            >
                {lines.map((line, index) => (
                    <tspan key={index} x={adjustedX} dy={index === 0 ? 0 : '1.1em'}>
                        {line}
                    </tspan>
                ))}
            </text>
        );
    };

    return (
        <ResponsiveContainer width='100%' aspect={1.3}>
            <RadarChart data={graph} outerRadius='60%'>
                <PolarGrid gridType='circle' stroke={theme.palette.common.black} opacity={0.2} />
                <PolarAngleAxis
                    dataKey='subject'
                    stroke={theme.palette.common.black}
                    tick={renderCustomizedTick}
                    axisLineType={'circle'}
                    tickLine={false}
                    axisLine={false}
                />
                <PolarRadiusAxis
                    angle={90}
                    domain={[0, 3]}
                    tickCount={3}
                    ticks={[1, 2, 3]}
                    stroke={theme.palette.common.black}
                    tick={{
                        fontSize: 15,
                        textAnchor: 'middle',
                        dx: 10,
                        dy: 20,
                        zIndex: 10,
                    }}
                    axisLine={false}
                />
                {PROJECT_ENVIRONMENTS.map((env, envIndex) => (
                    <Radar
                        key={env}
                        name={env}
                        dataKey={env}
                        stroke={theme.palette.project.colors[envIndex]}
                        fillOpacity={0}
                        strokeOpacity={1}
                        strokeWidth={3}
                    />
                ))}
            </RadarChart>
        </ResponsiveContainer>
    );
}

export default ProjectGraph;
