import { useTheme } from '@emotion/react';
import { Clear } from '@mui/icons-material';
import AppsIcon from '@mui/icons-material/Apps';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import CancelIcon from '@mui/icons-material/Cancel';
import SucceededIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CircleIcon from '@mui/icons-material/Circle';
import CommentIcon from '@mui/icons-material/Comment';
import LoopIcon from '@mui/icons-material/Loop';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import PersonIcon from '@mui/icons-material/Person';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import PendingIcon from '@mui/icons-material/Schedule';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import WarningIcon from '@mui/icons-material/Warning';
import {
    Box,
    Button,
    ButtonGroup,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Paper,
    Tooltip,
    Typography,
} from '@mui/material';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FixedSizeList as List } from 'react-window';

import { actions } from '../../../../actions';
import { api } from '../../../../services';
import { useInterval } from '../../../../services/helpers';
import EnhancedTableToolbar from '../../../components/Table/EnhancedTableToolbar';
import { PermissionManager } from '../../../utils/Permissions/PermissionManager';
import AnnotationDetails from '../../Annotation';
import { getPanelUri } from '../../index';
import '../styles/animations.css';
import { getBaseStyles } from '../styles/styles';
import {
    DELAY_REFRESH_EVENTS_AFTER_TOOLTIP_CLOSE,
    NB_EVENTS,
    NOTIFICATION_ANIMATION_TIMEOUT,
    PAPER_MARGIN,
    REFRESH_RATE,
    ROW_HEIGHT,
    WARNING_TARGET,
    allStatusIcons,
    eventCodeSelection,
    eventStatus,
    eventStatusButton,
    eventTypes,
} from '../utils/constants';
import {
    formatTimestampDate,
    formatTimestampTime,
    getFormattedName,
    getStatusFormat,
    getWarningFormat,
    isEqual,
} from '../utils/functions';
import DateRangePicker from './DateRangePicker';
import UserSelect from './UserSelector';

const PAGE_HEADER_HEIGHT = 50;
function EventBase(props) {
    const {
        annotationStatus,
        fetchAnnotationStatus,
        annotationIsFetching,
        setReviewed,
        reviewedAll,
        apiCheckBackendHealth,
        reviewPerms,
        warningCodes,
        annotationUsers,
        currentPage,
        setCurrentPage,
        selectedWarningCode,
        setSelectedWarningCode,
        selectedUser,
        setSelectedUser,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        type,
        setType,
        selectedStatus,
        setSelectedStatus,
        search,
        setSearch,
        handleClear,
    } = props;

    const theme = useTheme();
    const navigate = useNavigate();

    const [stopAnimation, setStopAnimation] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedNotification, setSelectedNotification] = useState(null);
    const [openReviewAllDialog, setOpenReviewAllDialog] = useState(false);
    const [isTooltipOpen, setIsTooltipOpen] = useState(false);
    const [totalWarningTypeValue, setTotalWarningTypeValue] = useState(0);
    const [selectedFilter, setSelectedFilter] = useState(null);
    const [localEvents, setLocalEvents] = useState([]);
    const [hoveredEventIndex, setHoveredEventIndex] = useState(null);
    const [totalPages, setTotalPages] = useState(1);
    const [listHeight, setListHeight] = useState(window.innerHeight - PAPER_MARGIN - 40 + PAGE_HEADER_HEIGHT);

    const listRef = useRef(null);
    const initComponentMount = useRef(false);
    const timeout = useRef(null);
    const needStateRefresh = useRef(false);

    // Styles
    const styles = useMemo(() => getBaseStyles(theme), [theme]);

    // Memo

    const matchingStatusStyle = useMemo(
        () => ({
            [eventStatus.SUCCESS]: styles.succeeded,
            [eventStatus.PENDING]: styles.pending,
            [eventStatus.STARTED]: styles.processing,
            [eventStatus.FAILURE]: styles.error,
            [eventStatus.WARNING]: styles.warning,
        }),
        [styles],
    );

    const matchingStatusIcons = useMemo(
        () => ({
            [eventStatus.SUCCESS]: <SucceededIcon />,
            [eventStatus.PENDING]: <PendingIcon />,
            [eventStatus.STARTED]: <LoopIcon sx={styles.loop} />,
            [eventStatus.FAILURE]: <CancelIcon />,
            [eventStatus.WARNING]: <WarningIcon />,
        }),
        [styles.loop],
    );

    const matchingStatusIconsButton = useMemo(() => {
        if (type === eventTypes.COGNIDOX_UPLOAD_FORM) {
            return {
                ...allStatusIcons,
                [eventStatusButton.AUTOFIXED]: undefined,
                [eventStatusButton.WARNING]: undefined,
            };
        }
        return allStatusIcons;
    }, [type]);

    const matchingEventIcons = useMemo(
        () => ({
            all: <AppsIcon />,
            annotation: <CommentIcon />,
            cognidox_upload_form: <UploadFileIcon />,
        }),
        [],
    );

    // Callbacks
    const getStatusIcon = useCallback(
        (event) => {
            if (event.status_message.includes(WARNING_TARGET)) return <CheckCircleOutlineIcon />;
            return matchingStatusIcons[event?.status] || null;
        },
        [matchingStatusIcons],
    );

    const getEventIcon = useCallback(
        (event) => {
            return matchingEventIcons[event.type] || null;
        },
        [matchingEventIcons],
    );

    const isWarningCodeSelected = useCallback(
        (code) => {
            const codeValue = warningCodes[code] || 0;
            return selectedWarningCode === 0 || (selectedWarningCode & codeValue) === codeValue;
        },
        [warningCodes, selectedWarningCode],
    );

    const getWarningCodeColor = useCallback(
        (code) => {
            const codeIndex = Object.keys(warningCodes).indexOf(code);

            if (codeIndex === -1) {
                return '#FFFFFF';
            }

            return theme.graph.colors[(codeIndex + 1) % theme.graph.colors.length];
        },
        [warningCodes, theme.graph.colors],
    );

    const setWarningCode = useCallback(
        (code) => {
            const codeValue = warningCodes[code] || 0;

            if (code === 0) {
                setSelectedWarningCode(0);
            } else {
                setSelectedWarningCode((prev) => {
                    const result = prev ^ codeValue;
                    if (result === totalWarningTypeValue) return 0;
                    return result;
                });
            }
        },
        [warningCodes, totalWarningTypeValue, setSelectedWarningCode],
    );

    const openAnnotation = useCallback(
        (event) => {
            if (!event.active) return;
            const uri = getPanelUri(<AnnotationDetails />, { hash: event.message });
            navigate(uri);
        },
        [navigate],
    );

    const getStyleStatus = useCallback(
        (event) => {
            if (event.status_message.includes(WARNING_TARGET)) return styles.autoFixed;
            return matchingStatusStyle[event?.status] || {};
        },
        [styles.autoFixed, matchingStatusStyle],
    );

    const processOnClickAction = useCallback(
        (event) => {
            if (event?.type === eventTypes.ANNOTATION) {
                openAnnotation(event);
            } else if (event?.type === eventTypes.COGNIDOX_UPLOAD_FORM) {
                window.open(event.url, '_blank');
            }
        },
        [openAnnotation],
    );

    const handleSelectedUser = useCallback(
        (user) => {
            setSelectedUser(user || '');
        },
        [setSelectedUser],
    );

    // Use effects
    useEffect(() => {
        const handleResize = () => {
            setListHeight(window.innerHeight - PAPER_MARGIN - (selectedFilter ? 40 : 0) + PAGE_HEADER_HEIGHT);
        };
        handleResize();

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, [selectedFilter]);

    useEffect(() => {
        if (!initComponentMount.current && localEvents.length > 0) {
            initComponentMount.current = true;

            const targetIndex =
                currentPage !== totalPages ? Math.max(0, localEvents.length - NB_EVENTS) : localEvents.length - 1;

            if (listRef.current && currentPage > 1) {
                listRef.current.scrollToItem(targetIndex, 'start');
            }
        }
    }, [localEvents, currentPage, totalPages]);

    useEffect(() => {
        if (
            !isEqual(annotationStatus?.search, search) ||
            !isEqual(annotationStatus?.type, type) ||
            !isEqual(annotationStatus?.status, selectedStatus) ||
            !isEqual(annotationStatus?.warning_code, selectedWarningCode) ||
            !isEqual(annotationStatus?.user, selectedUser) ||
            !isEqual(annotationStatus?.start_date, startDate) ||
            !isEqual(annotationStatus?.end_date, endDate)
        ) {
            if (annotationIsFetching) return;

            fetchAnnotationStatus(
                1,
                NB_EVENTS * currentPage,
                type,
                search,
                selectedStatus,
                selectedWarningCode,
                selectedUser,
                startDate,
                endDate,
            );
            return;
        }

        const dlPage = annotationStatus?.current_page || 1;
        const totalPage = annotationStatus?.total_pages || 1;

        if (dlPage === 1) {
            setLocalEvents(annotationStatus?.results || []);
            const coef = Math.ceil((annotationStatus?.results?.length || 0) / NB_EVENTS);
            setTotalPages(totalPage * coef);
            setCurrentPage(dlPage * coef);
            return;
        }

        setLocalEvents((prevEvents) => [...prevEvents, ...(annotationStatus?.results || [])]);
        setTotalPages(annotationStatus?.total_pages);
        setCurrentPage(annotationStatus?.current_page);
    }, [annotationStatus]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        // Check how much pages of event can be displayed in this window height
        // To handle very big screens
        const windowHeight = listHeight;
        const onePageHeight = ROW_HEIGHT * NB_EVENTS;
        const totalPages = Math.ceil(windowHeight / onePageHeight);
        const page = currentPage < totalPages ? totalPages : currentPage;

        fetchAnnotationStatus(
            1,
            NB_EVENTS * page,
            type,
            search,
            selectedStatus,
            selectedWarningCode,
            selectedUser,
            startDate,
            endDate,
        );
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setCurrentPage(1);
        setLocalEvents([]);
        fetchAnnotationStatus(
            1,
            NB_EVENTS,
            type,
            search,
            selectedStatus,
            selectedWarningCode,
            selectedUser,
            startDate,
            endDate,
        );
    }, [
        type,
        fetchAnnotationStatus,
        setCurrentPage,
        setLocalEvents,
        search,
        selectedStatus,
        selectedWarningCode,
        selectedUser,
        startDate,
        endDate,
    ]);

    useEffect(() => {
        if (warningCodes) {
            let total = 0;
            Object.keys(warningCodes).forEach((key) => {
                total += warningCodes[key];
            });
            setTotalWarningTypeValue(total);
        }
    }, [warningCodes]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setStopAnimation(true);
        }, NOTIFICATION_ANIMATION_TIMEOUT);
        return () => clearTimeout(timer);
    }, []);

    // Intervals
    useInterval(() => {
        if (isTooltipOpen) {
            console.log('Do not fetch latest events while tooltip is open');
            needStateRefresh.current = true;
            return;
        }

        refreshEvents();
    }, REFRESH_RATE);

    useEffect(() => {
        if (!matchingStatusIconsButton[selectedStatus.toUpperCase()]) {
            setSelectedStatus(eventStatusButton.ALL.toLowerCase());
        }
    }, [type, selectedStatus, matchingStatusIconsButton, setSelectedStatus]);

    // Utils functions
    const refreshEvents = () => {
        if (!annotationIsFetching) {
            const current_nb_events = localEvents?.length || NB_EVENTS;
            const nb_events = Math.ceil(current_nb_events / NB_EVENTS) * NB_EVENTS;
            fetchAnnotationStatus(
                1,
                nb_events,
                type,
                search,
                selectedStatus,
                selectedWarningCode,
                selectedUser,
                startDate,
                endDate,
            );
        }
    };

    const handleTooltipClose = () => {
        setIsTooltipOpen(false);

        if (timeout.current) {
            clearTimeout(timeout.current);
            timeout.current = null;
        }

        if (needStateRefresh.current) {
            timeout.current = setTimeout(() => {
                needStateRefresh.current = false;
                console.log('Fetch latest events after tooltip closed');
                refreshEvents();
            }, DELAY_REFRESH_EVENTS_AFTER_TOOLTIP_CLOSE);
        }
    };

    const handleTooltipOpen = () => {
        setIsTooltipOpen(true);
        if (timeout.current) {
            clearTimeout(timeout.current);
            timeout.current = null;
        }
    };

    const handleScroll = ({ scrollDirection, scrollOffset, scrollUpdateWasRequested }) => {
        const totalHeight = localEvents.length * ROW_HEIGHT;
        const nearBottom = scrollOffset + listHeight > totalHeight - 500;
        if (scrollDirection === 'forward' && nearBottom && !annotationIsFetching && currentPage < totalPages) {
            fetchAnnotationStatus(
                currentPage + 1,
                NB_EVENTS,
                type,
                search,
                selectedStatus,
                selectedWarningCode,
                selectedUser,
                startDate,
                endDate,
            );
            setCurrentPage((prevPage) => prevPage + 1);
        }
    };

    const markAsReviewed = (eventId) => {
        setReviewed(eventId);

        setTimeout(() => {
            refreshEvents();
            apiCheckBackendHealth();
        }, 100);
    };

    const markAllAsReviewed = () => {
        reviewedAll(type, search, selectedStatus, selectedWarningCode, selectedUser, startDate, endDate);
        setTimeout(() => {
            refreshEvents();
            apiCheckBackendHealth();
        }, 100);
    };

    const handleOpenDialog = (event) => {
        setSelectedNotification(event);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setSelectedNotification(null);
        setOpenDialog(false);
    };

    const handleConfirmReview = () => {
        if (selectedNotification) {
            markAsReviewed(selectedNotification.id);
            handleCloseDialog();
        }
    };

    const handleOpenReviewAllDialog = () => {
        setOpenReviewAllDialog(true);
    };

    const handleCloseReviewAllDialog = () => {
        setOpenReviewAllDialog(false);
    };

    const handleConfirmReviewAll = () => {
        markAllAsReviewed();
        handleCloseReviewAllDialog();
    };

    const EventStatusMessage = ({ event }) => {
        const statusMessage = event?.status_message || '';
        const length = statusMessage.split('\n').length;

        return (
            <>
                {length > 1 ? (
                    <Tooltip
                        title={
                            <div onClick={(e) => e.stopPropagation()}>
                                <pre style={styles.status_hover}>{statusMessage.split('\n').slice(1).join('\n')}</pre>
                            </div>
                        }
                        placement={'top'}
                        arrow={true}
                        onOpen={handleTooltipOpen}
                        onClose={handleTooltipClose}
                    >
                        <Typography sx={styles.status_message}>{statusMessage.split('\n')[0]}</Typography>
                    </Tooltip>
                ) : (
                    <Typography sx={styles.status_message}>{statusMessage}</Typography>
                )}
            </>
        );
    };

    const getWarningBubbles = (event) => {
        const warningCodesList = Object.keys(warningCodes);
        return warningCodesList
            .filter((code) => (event.warning_code & warningCodes[code]) === warningCodes[code])
            .map((code, index) => (
                <Box
                    key={index}
                    sx={{
                        width: '14px',
                        height: '14px',
                        backgroundColor: getWarningCodeColor(code),
                        borderRadius: '50%',
                        position: 'absolute',
                        top: `-4px`,
                        right: `${index * -8}px`,
                        zIndex: index,
                        border: `1px solid ${theme.palette.background.paper}`,
                    }}
                />
            ));
    };

    const getStatusTitle = (event) => {
        if (event.status_message.includes(WARNING_TARGET)) {
            return 'Auto Fixed';
        }

        if (event.status === eventStatus.WARNING) {
            const warningCodesList = Object.keys(warningCodes);
            const selectedWarningCodes = warningCodesList.filter(
                (code) => (event.warning_code & warningCodes[code]) === warningCodes[code],
            );

            return (
                <div onClick={(e) => e.stopPropagation()}>
                    <pre style={{ ...styles.status_hover, fontSize: '12px', margin: 0, padding: 0 }}>
                        {'Warnings:\n - ' + selectedWarningCodes.map((code) => getWarningFormat(code)).join('\n - ')}
                    </pre>
                </div>
            );
        }

        return getStatusFormat(event.status);
    };

    const Row = ({ index, style }) => {
        const adjustedStyle = {
            ...style,
            height: `${parseFloat(style.height) - 5}px`,
            marginBottom: '5px',
        };
        const event = localEvents[index];

        return (
            <Paper
                key={index}
                elevation={3}
                style={{
                    ...style,
                    ...styles.eventContainer,
                    ...getStyleStatus(event),
                    ...(index === hoveredEventIndex && styles.eventContainerHover),
                    ...(!event.active && styles.inactive),
                    ...adjustedStyle,
                }}
                onMouseEnter={() => setHoveredEventIndex(index)}
                onMouseLeave={() => setHoveredEventIndex(null)}
                onClick={() => processOnClickAction(event)}
                data-testid='eventRow'
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        cursor: event.active ? 'pointer' : 'default',
                    }}
                >
                    <Tooltip title={getStatusTitle(event)} arrow={true} placement={'top'}>
                        <Box sx={styles.icon}>
                            <Box sx={styles.icon}>{getStatusIcon(event)}</Box>
                            {eventCodeSelection.includes(event?.status?.toLowerCase()) && getWarningBubbles(event)}
                        </Box>
                    </Tooltip>
                    <Typography sx={styles.timestamp}>
                        {formatTimestampDate(event.timestamp)}
                        <br />
                        {formatTimestampTime(event.timestamp)}
                    </Typography>

                    <Box sx={styles.icon}>
                        <Tooltip
                            arrow={true}
                            placement={'top'}
                            onOpen={handleTooltipOpen}
                            onClose={handleTooltipClose}
                            title={<div onClick={(e) => e.stopPropagation()}>{getFormattedName(event.type)}</div>}
                            onClick={(e) => e.stopPropagation()}
                        >
                            {getEventIcon(event)}
                        </Tooltip>
                    </Box>
                    <Tooltip
                        arrow={true}
                        placement={'top'}
                        onOpen={handleTooltipOpen}
                        onClose={handleTooltipClose}
                        title={<div onClick={(e) => e.stopPropagation()}>{getFormattedName(event.type)}</div>}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <Typography sx={styles.id}>{event?.id}</Typography>
                    </Tooltip>

                    <Typography sx={styles.user}>{event?.user?.replace(/-/g, ' ')}</Typography>

                    <EventStatusMessage event={event} />
                    <Tooltip
                        arrow={true}
                        placement={'top'}
                        onOpen={handleTooltipOpen}
                        onClose={handleTooltipClose}
                        title={
                            <div onClick={(e) => e.stopPropagation()} style={{ maxWidth: '400px' }}>
                                {event?.message}
                            </div>
                        }
                        onClick={(e) => e.stopPropagation()}
                    >
                        <Typography sx={styles.message}>{event?.message}</Typography>
                    </Tooltip>
                    {event.notif &&
                        (reviewPerms ? (
                            <Tooltip
                                arrow={true}
                                onOpen={handleTooltipOpen}
                                onClose={handleTooltipClose}
                                title='Mark as reviewed'
                                placement='top'
                                onClick={(e) => e.stopPropagation()}
                            >
                                <IconButton
                                    sx={{
                                        position: 'absolute',
                                        right: '8px',
                                        top: '50%',
                                        transform: 'translateY(-50%)',
                                        animation: stopAnimation ? 'none' : 'ring 1s infinite',
                                        ...getStyleStatus(event),
                                    }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleOpenDialog(event);
                                    }}
                                >
                                    <NotificationsActiveIcon />
                                </IconButton>
                            </Tooltip>
                        ) : (
                            <NotificationsActiveIcon
                                sx={{
                                    position: 'absolute',
                                    right: '8px',
                                    top: '50%',
                                    transform: 'translateY(-50%)',
                                    animation: stopAnimation ? 'none' : 'ring 1s infinite',
                                    ...getStyleStatus(event),
                                }}
                                onClick={(e) => e.stopPropagation()}
                            />
                        ))}
                </Box>
            </Paper>
        );
    };

    const availableFilters = useMemo(
        () => [
            {
                name: `Event Type (${getFormattedName(type)})`,
                id: 'type',
                getIcon: () => matchingEventIcons[type],
                filters: (
                    <ButtonGroup color='primary' aria-label='outlined primary button group'>
                        {Object.keys(matchingEventIcons).map((order) => (
                            <Tooltip title={getFormattedName(order)} key={order}>
                                <IconButton
                                    onClick={() => setType(order)}
                                    color={type === order ? 'primary' : 'default'}
                                    data-testid={`button-type-${order}`}
                                >
                                    {matchingEventIcons[order]}
                                </IconButton>
                            </Tooltip>
                        ))}
                    </ButtonGroup>
                ),
            },
            {
                name: `Status (${getStatusFormat(selectedStatus)})`,
                id: 'status',
                getIcon: () => matchingStatusIconsButton[selectedStatus.toUpperCase()] || <CircleIcon />,
                filters: (
                    <>
                        <ButtonGroup color='primary' aria-label='outlined primary button group'>
                            {Object.keys(matchingStatusIconsButton)
                                .filter((e) => matchingStatusIconsButton[e] !== undefined)
                                .map((event_status) => (
                                    <Tooltip arrow={true} title={getStatusFormat(event_status)} key={event_status}>
                                        <IconButton
                                            onClick={() => setSelectedStatus(event_status.toLowerCase())}
                                            color={
                                                selectedStatus === event_status.toLowerCase() ? 'primary' : 'default'
                                            }
                                            data-testid={`button-status-${event_status}`}
                                        >
                                            {matchingStatusIconsButton[event_status]}
                                        </IconButton>
                                    </Tooltip>
                                ))}
                        </ButtonGroup>
                        {eventCodeSelection.includes(selectedStatus.toLowerCase()) && (
                            <>
                                <Box sx={styles.separator} />
                                <ButtonGroup color='primary' aria-label='outlined primary button group'>
                                    {Object.keys(warningCodes).map((code, index) => (
                                        <Tooltip arrow={true} title={getWarningFormat(code)} key={code}>
                                            <IconButton
                                                sx={{
                                                    color: getWarningCodeColor(code),
                                                    opacity: isWarningCodeSelected(code) ? 1 : 0.2,
                                                }}
                                                onClick={() => setWarningCode(code)}
                                                data-testid={`button-warning-${code}`}
                                            >
                                                <CircleIcon />
                                            </IconButton>
                                        </Tooltip>
                                    ))}
                                </ButtonGroup>
                            </>
                        )}
                    </>
                ),
            },
            {
                name: `User (${selectedUser || 'All'})`,
                id: 'user',
                getIcon: () => (selectedUser ? <PersonIcon /> : <PersonOutlineIcon />),
                filters: (
                    <UserSelect
                        users={annotationUsers}
                        selectedUser={selectedUser}
                        setSelectedUser={handleSelectedUser}
                    />
                ),
            },
            {
                name: `Date Range (${
                    startDate && endDate
                        ? `${startDate} to ${endDate}`
                        : startDate
                        ? `after ${startDate}`
                        : endDate
                        ? `until ${endDate}`
                        : 'All'
                })`,
                id: 'date',
                getIcon: () => (startDate || endDate ? <CalendarMonthIcon /> : <CalendarTodayIcon />),
                filters: (
                    <DateRangePicker
                        startDate={startDate}
                        setStartDate={setStartDate}
                        endDate={endDate}
                        setEndDate={setEndDate}
                    />
                ),
            },
        ],
        [
            type,
            matchingEventIcons,
            selectedStatus,
            matchingStatusIconsButton,
            styles.separator,
            warningCodes,
            selectedUser,
            annotationUsers,
            getWarningCodeColor,
            isWarningCodeSelected,
            setWarningCode,
            startDate,
            endDate,
            setStartDate,
            setEndDate,
            setType,
            setSelectedStatus,
            handleSelectedUser,
        ],
    );
    return (
        <Paper sx={styles.paper}>
            <EnhancedTableToolbar
                isLoading={annotationIsFetching}
                title='Events'
                setSearch={setSearch}
                margin={'no-m'}
            />
            <Box sx={styles.center}>
                {availableFilters?.map((filter) => (
                    <Box key={filter.id}>
                        <Tooltip title={filter.name} arrow={true} placement={'top'}>
                            <IconButton
                                onClick={() => setSelectedFilter((f) => (f === filter.id ? null : filter.id))}
                                color={selectedFilter === filter.id ? 'primary' : 'default'}
                                data-testid={`button-${filter.id}`}
                            >
                                {filter.getIcon()}
                            </IconButton>
                        </Tooltip>
                    </Box>
                ))}
                <Box>
                    <Tooltip title={'Clear'} arrow={true} placement={'top'}>
                        <IconButton onClick={() => handleClear()} data-testid='clearButton'>
                            <Clear />
                        </IconButton>
                    </Tooltip>
                </Box>
            </Box>
            <Box
                className={`table-transition`}
                sx={{
                    ...styles.center,
                    height: `${selectedFilter ? '40px' : '0px'}`,
                }}
            >
                {selectedFilter && availableFilters?.find((filter) => filter.id === selectedFilter)?.filters}
            </Box>

            <Box
                onMouseLeave={() => setHoveredEventIndex(null)}
                className='table-transition'
                sx={{
                    padding: `16px ${localEvents.length * ROW_HEIGHT > listHeight ? '0px' : '16px'} 16px 16px`,
                    width: '100%',
                    height: `calc(100vh - ${!selectedFilter ? 324 : 324 + 40}px + ${PAGE_HEADER_HEIGHT}px)`,
                    marginBottom: '8px',
                    position: 'relative',
                }}
                data-testid='eventList'
            >
                {annotationStatus?.nb_notif > 0 && reviewPerms && (
                    <Box sx={styles.reviewAll} onClick={handleOpenReviewAllDialog} data-testid='reviewAllButton'>
                        Mark {annotationStatus?.nb_notif}{' '}
                        {annotationStatus?.nb_notif > 1 ? 'notifications' : 'notification'} as reviewed
                    </Box>
                )}
                {Row && Row.length !== 0 ? (
                    <List
                        className='table-transition'
                        height={listHeight}
                        itemCount={localEvents.length}
                        itemSize={ROW_HEIGHT}
                        width='100%'
                        onScroll={handleScroll}
                        ref={listRef}
                    >
                        {Row}
                    </List>
                ) : (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            margin: theme.spacing(3),
                        }}
                    >
                        <CircularProgress
                            sx={{
                                marginBottom: theme.spacing(3),
                            }}
                            size={48}
                        />
                    </Box>
                )}
            </Box>
            <Typography sx={styles.nbResult}>{annotationStatus?.total_results} results</Typography>
            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby='confirm-dialog-title'
                aria-describedby='confirm-dialog-description'
            >
                <DialogTitle
                    sx={{
                        textAlign: 'center',
                    }}
                >
                    Confirm Review
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>Mark this notification as reviewed for everyone?</DialogContentText>
                </DialogContent>
                <DialogActions
                    sx={{
                        margin: 1,
                    }}
                >
                    <Button variant={'contained'} onClick={handleCloseDialog} color={'error'}>
                        Cancel
                    </Button>
                    <Button variant={'contained'} onClick={handleConfirmReview} color={'primary'} autoFocus>
                        Reviewed
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openReviewAllDialog}
                onClose={handleCloseReviewAllDialog}
                aria-labelledby='review-all-dialog-title'
                aria-describedby='review-all-dialog-description'
            >
                <DialogTitle
                    sx={{
                        textAlign: 'center',
                    }}
                >
                    Confirm Review All
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Mark {annotationStatus?.nb_notif}{' '}
                        {annotationStatus?.nb_notif > 1 ? 'notifications' : 'notification'} as reviewed for everyone?
                    </DialogContentText>
                </DialogContent>
                <DialogActions
                    sx={{
                        margin: 1,
                    }}
                >
                    <Button variant={'contained'} onClick={handleCloseReviewAllDialog} color={'error'}>
                        Cancel
                    </Button>
                    <Button variant={'contained'} onClick={handleConfirmReviewAll} color={'primary'} autoFocus>
                        Reviewed All
                    </Button>
                </DialogActions>
            </Dialog>
        </Paper>
    );
}

const mapStateToProps = (state) => {
    return {
        annotationStatus: (state.data[api.endpoints.annStatus] || {}).data || [],
        annotationIsFetching:
            state.data[api.endpoints.annStatus]?.isFetching || state.data[api.endpoints.annStatus]?.isUpdating || false,
        reviewPerms:
            state.auth?.user?.perms?.[PermissionManager.reviewAnnotationPerm] || state.auth?.user?.staff || false,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchAnnotationStatus: (page, size, type, q, status, warning_code, user, start_date, end_date) =>
            dispatch(
                actions.api.data.fetch.request({
                    endpoint: api.endpoints.annStatus,
                    page,
                    size,
                    type,
                    q,
                    status,
                    warning_code,
                    user,
                    start_date,
                    end_date,
                }),
            ),
        setReviewed: (annId) =>
            dispatch(
                actions.api.data.put.request({
                    endpoint: api.endpoints.annReview,
                    id: annId,
                }),
            ),
        reviewedAll: (type, q, status, warning_code, user, start_date, end_date) =>
            dispatch(
                actions.api.data.put.request({
                    endpoint: api.endpoints.annReview,
                    noid: true,
                    params: { type, q, status, warning_code, user, start_date, end_date },
                }),
            ),

        apiCheckBackendHealth: () => dispatch(actions.api.health.request()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EventBase);
