import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';

import { actions } from '../../actions';
import BodyBuilderGrid from './BodyBuilderGrid';
import ValidationButton from './ValidationButton';

function LNone(props) {
    const { onChange, nodeId, setValidation, disableValidation } = props;

    const [updated, setUpdated] = useState(true);
    const [value] = useState('');

    const handleValidationClick = () => {
        if (!disableValidation) setValidation({ nodeId, validate: true });
        setUpdated(false);
        onChange([value]);
    };

    return (
        <BodyBuilderGrid>
            <Grid item>
                <ValidationButton onClick={handleValidationClick} updated={updated} disabled={value === null} />
            </Grid>
        </BodyBuilderGrid>
    );
}

LNone.propTypes = {
    onChange: PropTypes.func.isRequired,
    nodeId: PropTypes.string.isRequired,
};

const mapDispatchToProps = (dispatch) => {
    return {
        setValidation: (...args) => dispatch(actions.qbuilder.validation.set(...args)),
    };
};

export default connect(null, mapDispatchToProps)(LNone);
