import { useTheme } from '@emotion/react';
import { Add as AddIcon, Delete as DeleteIcon, Remove as RemoveIcon } from '@mui/icons-material';
import {
    Box,
    Button,
    Checkbox,
    Grid,
    IconButton,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Tooltip as TooltipMui,
    Typography,
} from '@mui/material';
import React, { useCallback } from 'react';

import { DEFAULT_TABLE_VALUE, VALUE_TYPES, isHardcodedValue } from '../../constant';

export const TableCreationForm = ({ tables, setTables, handleNext, handleBack }) => {
    const theme = useTheme();

    const styles = {
        table: {
            minWidth: 650,
            marginTop: theme.spacing(3),
        },
        tableCell: {
            textAlign: 'left',
            verticalAlign: 'top',
            padding: theme.spacing(1),
        },
        tableCellHeader: {
            textAlign: 'left',
            verticalAlign: 'top',
            padding: theme.spacing(1),
            width: '33%',
        },
        tableCellCenter: {
            textAlign: 'center',
            verticalAlign: 'top',
            padding: theme.spacing(1),
        },
        buttonContainer: {
            margin: theme.spacing(2),
            display: 'flex',
            justifyContent: 'flex-end',
        },
        center: {
            width: '100%',
            textAlign: 'center',
        },
        section: {
            backgroundColor: theme.palette.common.sectionGray,
            margin: `4px 0 20px 0`,
            borderRadius: '5px',
            padding: theme.spacing(3),
        },
        sectionTitle: {
            marginBottom: theme.spacing(2),
        },
        name: {
            marginBottom: theme.spacing(3),
        },
        textfield: {
            marginTop: '8px',
            width: '100%',
        },
    };

    const handleTableNameChange = (index, e) => {
        const updatedTables = [...tables];
        updatedTables[index].title = e.target.value;
        setTables(updatedTables);
    };

    const handleDeleteTable = (index) => {
        setTables(tables.filter((_, i) => i !== index));
    };

    const handleSubjectChange = (tableIndex, subjectIndex, value, subIndex = undefined) => {
        const updatedTables = [...tables];
        if (subIndex !== undefined)
            updatedTables[tableIndex].data[subjectIndex].subcategories[subIndex].subject = value;
        else updatedTables[tableIndex].data[subjectIndex].subject = value;
        setTables(updatedTables);
    };

    const handleAddSubcategory = (tableIndex, subjectIndex) => {
        const updatedTables = [...tables];
        if (!updatedTables[tableIndex].data[subjectIndex].subcategories) {
            updatedTables[tableIndex].data[subjectIndex].subcategories = [];
        }
        if (updatedTables[tableIndex].data[subjectIndex].query) {
            delete updatedTables[tableIndex].data[subjectIndex].query;
        }
        updatedTables[tableIndex].data[subjectIndex].subcategories.push({ subject: '', query: [] });
        setTables(updatedTables);
    };

    const handleRemoveSubcategory = (tableIndex, subjectIndex) => {
        const updatedTables = [...tables];
        const subcategories = updatedTables[tableIndex].data[subjectIndex].subcategories;
        if (subcategories && subcategories.length > 0) {
            subcategories.pop();
            if (subcategories.length === 0) {
                delete updatedTables[tableIndex].data[subjectIndex].subcategories;
                updatedTables[tableIndex].data[subjectIndex].query = [];
            }
            setTables(updatedTables);
        }
    };

    const handleQueryTypeChange = useCallback(
        (tableIndex, subjectIndex, levelIndex, value) => {
            const updatedTables = [...tables];

            if (!updatedTables[tableIndex].data[subjectIndex]) {
                updatedTables[tableIndex].data[subjectIndex] = { ...DEFAULT_TABLE_VALUE[0].data[0] };
            }

            const isHardcoded = isHardcodedValue(value) ? true : undefined;
            if (levelIndex !== null) {
                updatedTables[tableIndex].data[subjectIndex].subcategories[levelIndex].hardcoded = isHardcoded;
            } else {
                updatedTables[tableIndex].data[subjectIndex].hardcoded = isHardcoded;
            }

            setTables(updatedTables);
        },
        [tables, setTables],
    );

    const handleUniqueChange = (tableIndex, subjectIndex, levelIndex, checked) => {
        const updatedTables = [...tables];
        const value = checked ? [''] : undefined;
        if (levelIndex !== null) {
            updatedTables[tableIndex].data[subjectIndex].subcategories[levelIndex].unique = value;
        } else {
            updatedTables[tableIndex].data[subjectIndex].unique = value;
        }
        setTables(updatedTables);
    };

    const handleHideChange = (tableIndex, subjectIndex, checked) => {
        const updatedTables = [...tables];
        updatedTables[tableIndex].data[subjectIndex].hide = checked;
        setTables(updatedTables);
    };

    const handleAddTable = () => {
        setTables([...tables, { title: 'New Table', data: [{ subject: '', query: [] }] }]);
    };

    const handleAddRow = (tableIndex) => {
        const updatedTables = [...tables];
        updatedTables[tableIndex].data.push({
            subject: '',
            query: [],
        });
        setTables(updatedTables);
    };

    const handleDeleteRow = (tableIndex, subjectIndex) => {
        const updatedTables = [...tables];
        updatedTables[tableIndex].data = updatedTables[tableIndex].data.filter((_, index) => index !== subjectIndex);
        setTables(updatedTables);
    };

    return (
        <Box>
            <Box sx={styles.center}>
                <Typography variant='h5' sx={styles.sectionTitle}>
                    Tables
                </Typography>
            </Box>
            {tables.map((table, tableIndex) => (
                <Box key={tableIndex} sx={styles.section}>
                    <Grid container justifyContent={'center'}>
                        <Grid item xs={12} sm={12} md={6} lg={4}>
                            <Grid container spacing={3} justifyContent={'center'} direction={'column'}>
                                <Grid item xs={12} sx={styles.name}>
                                    <TextField
                                        label='Table Title'
                                        variant='outlined'
                                        fullWidth
                                        value={table.title}
                                        onChange={(e) => handleTableNameChange(tableIndex, e)}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={1}>
                            {tables.length === 1 ? (
                                <IconButton disabled={true} sx={{ margin: theme.spacing(1) }}>
                                    <DeleteIcon color='disabled' />
                                </IconButton>
                            ) : (
                                <TooltipMui title='Delete Table'>
                                    <IconButton
                                        onClick={() => handleDeleteTable(tableIndex)}
                                        sx={{ margin: theme.spacing(1) }}
                                    >
                                        <DeleteIcon color='error' />
                                    </IconButton>
                                </TooltipMui>
                            )}
                        </Grid>
                    </Grid>
                    <TableContainer sx={{ marginTop: theme.spacing(3) }}>
                        <Table sx={styles.table}>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={styles.tableCellCenter}>Subject</TableCell>
                                    <TableCell sx={styles.tableCellCenter}>
                                        <Grid container spacing={2} alignItems='center'>
                                            <Grid item xs={10}>
                                                Value Type
                                            </Grid>
                                            <Grid item xs={1} sx={{ textAlign: 'center' }}>
                                                Unique
                                            </Grid>
                                        </Grid>
                                    </TableCell>
                                    <TableCell sx={styles.tableCellCenter}>Hide</TableCell>
                                    <TableCell sx={styles.tableCellCenter}>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {table.data.map((subject, subjectIndex) => (
                                    <TableRow key={subjectIndex}>
                                        <TableCell sx={styles.tableCellHeader}>
                                            <TextField
                                                sx={styles.textfield}
                                                value={subject.subject}
                                                onChange={(e) =>
                                                    handleSubjectChange(tableIndex, subjectIndex, e.target.value)
                                                }
                                                variant='outlined'
                                                fullWidth
                                            />
                                        </TableCell>
                                        <TableCell sx={styles.tableCell}>
                                            {subject.subcategories &&
                                                subject.subcategories.map((subcategory, subIndex) => {
                                                    return (
                                                        <Box key={subIndex}>
                                                            <Grid container spacing={2} alignItems='center'>
                                                                <Grid item xs={5}>
                                                                    <TextField
                                                                        sx={styles.textfield}
                                                                        value={subcategory.subject}
                                                                        onChange={(e) =>
                                                                            handleSubjectChange(
                                                                                tableIndex,
                                                                                subjectIndex,
                                                                                e.target.value,
                                                                                subIndex,
                                                                            )
                                                                        }
                                                                        variant='outlined'
                                                                        fullWidth
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={5}>
                                                                    <TextField
                                                                        select
                                                                        sx={{
                                                                            width: '100%',
                                                                            marginTop: '8px',
                                                                        }}
                                                                        label='Value Type'
                                                                        value={
                                                                            subject?.subcategories[subIndex]?.hardcoded
                                                                                ? VALUE_TYPES[1].value
                                                                                : VALUE_TYPES[0].value
                                                                        }
                                                                        onChange={(e) =>
                                                                            handleQueryTypeChange(
                                                                                tableIndex,
                                                                                subjectIndex,
                                                                                subIndex,
                                                                                e.target.value,
                                                                            )
                                                                        }
                                                                    >
                                                                        {VALUE_TYPES.map((option) => (
                                                                            <MenuItem
                                                                                key={option.value}
                                                                                value={option.value}
                                                                            >
                                                                                <Box
                                                                                    sx={{
                                                                                        display: 'flex',
                                                                                        alignItems: 'center',
                                                                                    }}
                                                                                >
                                                                                    {option.icon}
                                                                                    <span style={{ marginLeft: 8 }}>
                                                                                        {option.label}
                                                                                    </span>
                                                                                </Box>
                                                                            </MenuItem>
                                                                        ))}
                                                                    </TextField>
                                                                </Grid>
                                                                <Grid item xs={1} sx={{ textAlign: 'center' }}>
                                                                    {!subcategory.hardcoded && (
                                                                        <Checkbox
                                                                            sx={{ marginTop: '8px' }}
                                                                            checked={subcategory.unique !== undefined}
                                                                            onChange={(e) =>
                                                                                handleUniqueChange(
                                                                                    tableIndex,
                                                                                    subjectIndex,
                                                                                    subIndex,
                                                                                    e.target.checked,
                                                                                )
                                                                            }
                                                                        />
                                                                    )}
                                                                </Grid>
                                                            </Grid>
                                                        </Box>
                                                    );
                                                })}
                                            {!subject.subcategories && (
                                                <Box>
                                                    <Grid container spacing={2} alignItems='center'>
                                                        <Grid item xs={10}>
                                                            <TextField
                                                                select
                                                                label='Value Type'
                                                                sx={{ width: '100%', marginTop: '8px' }}
                                                                value={
                                                                    subject?.hardcoded
                                                                        ? VALUE_TYPES[1].value
                                                                        : VALUE_TYPES[0].value
                                                                }
                                                                onChange={(e) =>
                                                                    handleQueryTypeChange(
                                                                        tableIndex,
                                                                        subjectIndex,
                                                                        null,
                                                                        e.target.value,
                                                                    )
                                                                }
                                                            >
                                                                {VALUE_TYPES.map((option) => (
                                                                    <MenuItem key={option.value} value={option.value}>
                                                                        <Box
                                                                            sx={{
                                                                                display: 'flex',
                                                                                alignItems: 'center',
                                                                            }}
                                                                        >
                                                                            {option.icon}
                                                                            <span style={{ marginLeft: 8 }}>
                                                                                {option.label}
                                                                            </span>
                                                                        </Box>
                                                                    </MenuItem>
                                                                ))}
                                                            </TextField>
                                                        </Grid>
                                                        <Grid item xs={1} sx={{ textAlign: 'center' }}>
                                                            {!subject.hardcoded && (
                                                                <Checkbox
                                                                    sx={{ marginTop: '8px' }}
                                                                    checked={subject.unique !== undefined}
                                                                    onChange={(e) =>
                                                                        handleUniqueChange(
                                                                            tableIndex,
                                                                            subjectIndex,
                                                                            null,
                                                                            e.target.checked,
                                                                        )
                                                                    }
                                                                />
                                                            )}
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            )}
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                {subject.subcategories && subject.subcategories.length > 0 && (
                                                    <TooltipMui title='Remove Last Subcategory'>
                                                        <IconButton
                                                            onClick={() =>
                                                                handleRemoveSubcategory(tableIndex, subjectIndex)
                                                            }
                                                        >
                                                            <RemoveIcon color='secondary' />
                                                        </IconButton>
                                                    </TooltipMui>
                                                )}
                                                <TooltipMui title='Add Subcategory'>
                                                    <IconButton
                                                        onClick={() => handleAddSubcategory(tableIndex, subjectIndex)}
                                                    >
                                                        <AddIcon color='primary' />
                                                    </IconButton>
                                                </TooltipMui>
                                            </Box>
                                        </TableCell>
                                        <TableCell sx={styles.tableCellCenter}>
                                            <Checkbox
                                                sx={{ marginTop: '8px' }}
                                                checked={subject.hide || false}
                                                onChange={(e) =>
                                                    handleHideChange(tableIndex, subjectIndex, e.target.checked)
                                                }
                                            />
                                        </TableCell>
                                        <TableCell sx={styles.tableCellCenter}>
                                            {table.data.length === 1 ? (
                                                <IconButton disabled={true} sx={{ margin: theme.spacing(1) }}>
                                                    <DeleteIcon color='disabled' />
                                                </IconButton>
                                            ) : (
                                                <TooltipMui title='Delete Row'>
                                                    <IconButton
                                                        onClick={() => handleDeleteRow(tableIndex, subjectIndex)}
                                                        sx={{ margin: theme.spacing(1) }}
                                                    >
                                                        <DeleteIcon color='error' />
                                                    </IconButton>
                                                </TooltipMui>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                                <TableRow>
                                    <TableCell colSpan={5} sx={{ textAlign: 'center' }}>
                                        <TooltipMui title='Add Row'>
                                            <IconButton onClick={() => handleAddRow(tableIndex)}>
                                                <AddIcon color='primary' />
                                            </IconButton>
                                        </TooltipMui>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            ))}

            <Box sx={styles.buttonContainer}>
                <TooltipMui title='Add Table'>
                    <IconButton onClick={handleAddTable}>
                        <AddIcon color='primary' />
                    </IconButton>
                </TooltipMui>
            </Box>
            <Box sx={styles.buttonContainer}>
                <Button onClick={handleBack}>Back</Button>
                <Button onClick={handleNext}>Next</Button>
            </Box>
        </Box>
    );
};
