import React from 'react';
import { XAxis, YAxis } from 'recharts';

const generateDateTicks = (data, intervalDays) => {
    if (data.length === 0 || !data[0]?.date) {
        return [];
    }
    const startDate = new Date(data[0].date);
    const endDate = new Date(data[data.length - 1].date);
    const dateTicks = [];

    for (let date = new Date(startDate); date <= endDate; date.setDate(date.getDate() + intervalDays)) {
        dateTicks.push(new Date(date).getTime());
    }

    return dateTicks;
};

const ChartAxes = function ({ theme, data, YAuto, selectedKeys }) {
    const allData = data?.data;
    if (!allData?.length) {
        return null;
    }

    const allValues = allData?.flatMap((item) =>
        selectedKeys.map((key) => item[key]).filter((value) => typeof value === 'number'),
    );

    const maxValue = allValues?.length ? Math.max(...allValues) : 0;
    const minValue = allValues?.length ? Math.min(...allValues) : 0;
    const offset = (maxValue - minValue) * 0.05;

    return (
        <>
            <XAxis
                stroke={theme.palette.common.black}
                dataKey='database_axis_date_KEY'
                scale='time'
                type='number'
                domain={['dataMin', 'dataMax']}
                tickFormatter={(unixTime) => new Date(unixTime).toLocaleDateString()}
                ticks={generateDateTicks(data, 3)}
            />
            <YAxis
                domain={[
                    YAuto ? Math.max(0, Math.floor(minValue - offset - 1)) : 0,
                    Math.max(1, Math.ceil(maxValue + offset + 1)),
                ]}
            />
        </>
    );
};

export default ChartAxes;
