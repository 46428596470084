import axios from 'axios';

const root = 'api/qbuilder';

function getDocFrame() {
    return axios.get(`${root}/doc/frame/`);
}

function getDocSegment() {
    return axios.get(`${root}/doc/segment/`);
}

function checkDataset({ name }) {
    return axios.post(`${root}/check/`, { name: name });
}

function buildDataset({
    specs,
    querySelectedSegments,
    querySelectedFrames = undefined,
    queryExcludedFrames = undefined,
    version = undefined,
}) {
    return axios.post(`${root}/build/`, {
        specs: {
            name: specs.name,
            include: specs.dataToInclude,
            encoding_frames: specs.frameEncoding,
            encoding_masks: specs.maskEncoding,
        },
        query: {
            selected_segments: querySelectedSegments,
            selected_frames: querySelectedFrames,
            excluded_frames: queryExcludedFrames,
            version: version,
        },
    });
}

function rerun(endpoint, row) {
    if (!row) throw new Error('Can rerun without row id');
    return axios.post(endpoint, row);
}

export const qbuilder = {
    doc: {
        frame: getDocFrame,
        segment: getDocSegment,
    },
    build: buildDataset,
    check: checkDataset,
    rerun: rerun,
};
