import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, CircularProgress, Divider, IconButton, Paper, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AreaChart, CartesianGrid, LineChart, ResponsiveContainer } from 'recharts';

import { timeRanges } from '../../utils/DateRangeFilters';
import { getStyles } from '../styles/StyleComponent';
import { AUTO_SELECT_LIMIT, DISPLAY_TIMEOUT_MS, OTHER_ROW, OTHER_ROW_LABEL, isArea } from '../utils';
import ChartAttributeSelection from './ChartAttributeSelection';
import ChartAxes from './ChartAxes';
import ChartConfig from './ChartConfig';
import { renderChartElement } from './ChartElement';
import { getChartLegend } from './ChartLegend';
import { getChartTooltip } from './ChartToolTip';
import AttributeStatsFilterControls from './FilterControls';
import { MoreAttributePanel } from './MoreAttributePanel';

export const AttributesChart = (props) => {
    const {
        stats,
        statsFetch,
        chartType,
        setChartType,
        isStacked,
        setIsStacked,
        selectedKeys,
        setSelectedKeys,
        selectedLabel,
        setSelectedLabel,
        selectedName,
        setSelectedName,
        filter,
        setFilter,
        selectedTimeRange,
        setSelectedTimeRange,
        autoSelect,
        setAutoSelect,
        otherRow,
        setOtherRow,
        segmentMode,
        YAuto,
        setYAuto,
    } = props;

    const theme = useTheme();
    const styles = getStyles(theme);
    const otherColor = theme.graph.otherColor;
    const colors = theme.graph.colors;

    // States
    const [chartData, setChartData] = useState([]);
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const [minDate, setMinDate] = useState(new Date(0));
    const [hoveredLegendKey, setHoveredLegendKey] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [displayedKeys, setDisplayedKeys] = useState([]);
    const [delayedTimeout, setDelayedTimeout] = useState(null);
    const intervalRef = useRef();

    // Memo
    const allElements = useMemo(() => {
        if (
            !chartData ||
            !chartData.allKeys ||
            chartData.allKeys.length === 0 ||
            !chartData.data ||
            chartData.data.length === 0
        ) {
            return [];
        }

        const lastElement = chartData.data[chartData.data.length - 1];
        let elementsMap = {};

        Object.values(chartData.allKeys).forEach((item) => {
            elementsMap[item] = lastElement[item] || 0;
        });

        return Object.entries(elementsMap)
            .map(([key, value]) => ({ key, value }))
            .sort((a, b) => {
                if (b.value === a.value) return a.key.localeCompare(b.key);
                return b.value - a.value;
            });
    }, [chartData]);

    // Callbacks
    const getData = useCallback(
        (selectedLabel, selectedName) => {
            if (!stats?.values) {
                if (!intervalRef.current && isLoading) {
                    intervalRef.current = setInterval(() => {
                        console.log('Retrying label attribute loading...');
                        statsFetch();
                    }, 10000);
                }
                return [];
            }

            if (intervalRef.current) {
                clearInterval(intervalRef.current);
                intervalRef.current = null;
            }

            if (segmentMode) {
                if (selectedName) {
                    if (!(selectedName in stats?.values)) {
                        console.log(stats?.names);
                        setSelectedName(null);
                        return [];
                    }
                    return stats?.values[selectedName] || [];
                }
                return stats?.names || [];
            }

            if (selectedName) {
                if (!(selectedLabel in stats?.values)) {
                    setSelectedLabel(null);
                    setSelectedName(null);
                    return [];
                }
                if (!(selectedName in stats?.values[selectedLabel])) {
                    setSelectedName(null);
                    return [];
                }
                return stats?.values[selectedLabel][selectedName] || [];
            }
            if (selectedLabel) {
                if (!(selectedLabel in stats?.values)) {
                    setSelectedLabel(null);
                    return [];
                }
                return stats?.names[selectedLabel] || [];
            }
            return stats?.labels || [];
        },
        [stats, setSelectedLabel, setSelectedName, statsFetch, isLoading, segmentMode],
    );

    useEffect(() => {
        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, []);

    const calculateOtherValue = useCallback(
        (selectedKeys) => {
            let otherValue = 0;

            const selectedKeysWithoutOther = selectedKeys.filter((key) => key !== OTHER_ROW);
            for (const element of allElements) {
                if (element.key === OTHER_ROW) continue;

                if (!selectedKeysWithoutOther.includes(element.key)) {
                    otherValue += element.value;
                }
            }
            return otherValue;
        },
        [allElements],
    );

    const sortSelectedKeys = useCallback(
        (selectedKeys) => {
            const otherValue = calculateOtherValue(selectedKeys);

            function getValue(key) {
                if (key === OTHER_ROW) {
                    return otherValue;
                }
                return allElements.find((el) => el.key === key)?.value || 0;
            }

            selectedKeys.sort((a, b) => {
                return getValue(b) - getValue(a);
            });
        },
        [calculateOtherValue, allElements],
    );

    const handleElementSelection = useCallback(
        (key) => {
            setSelectedKeys((prev) => {
                const currentIndex = prev.indexOf(key);
                const newSelectedKeys = [...prev];

                if (currentIndex === -1) {
                    newSelectedKeys.push(key);
                } else {
                    newSelectedKeys.splice(currentIndex, 1);
                }
                sortSelectedKeys(newSelectedKeys);
                return newSelectedKeys;
            });
            setAutoSelect(false);
        },
        [setSelectedKeys, setAutoSelect, sortSelectedKeys],
    );

    const updateSelectedKeys = useCallback(() => {
        if (!allElements || allElements.length === 0) return;

        let newSelectedKeys = selectedKeys.filter((key) => allElements.find((el) => el.key === key));
        const selectedKeysWithoutOther = newSelectedKeys.filter((key) => key !== OTHER_ROW);

        if (selectedKeysWithoutOther.length === 0 && autoSelect) {
            newSelectedKeys = allElements
                .filter((el) => el.key !== OTHER_ROW)
                .slice(0, AUTO_SELECT_LIMIT)
                .map((el) => el.key);
        }

        if (otherRow && !newSelectedKeys.includes(OTHER_ROW)) {
            newSelectedKeys.push(OTHER_ROW);
        }

        if (!otherRow && newSelectedKeys.includes(OTHER_ROW)) {
            newSelectedKeys.splice(newSelectedKeys.indexOf(OTHER_ROW), 1);
        }

        sortSelectedKeys(newSelectedKeys);

        const needUpdate =
            newSelectedKeys.length !== selectedKeys.length ||
            newSelectedKeys.some((key, index) => key !== selectedKeys[index]);

        if (needUpdate) {
            setSelectedKeys(newSelectedKeys);
        }
    }, [selectedKeys, allElements, autoSelect, otherRow, sortSelectedKeys, setSelectedKeys]);

    // Use effect
    useEffect(() => {
        setIsLoading(true);
        statsFetch();
    }, [statsFetch]);

    useEffect(() => {
        if (delayedTimeout) {
            clearTimeout(delayedTimeout);
        }
        setDelayedTimeout(
            setTimeout(() => {
                setDisplayedKeys(selectedKeys);
            }, DISPLAY_TIMEOUT_MS),
        );
    }, [selectedKeys]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        updateSelectedKeys();
    }, [updateSelectedKeys]);

    useEffect(() => {
        if (stats) {
            const statsByDate = getData(selectedLabel, selectedName);

            const elements = [];
            const keys = new Set();

            let minDate = null;
            for (const key in statsByDate) {
                const date = new Date(key).getTime();

                if (!minDate || date < minDate) {
                    minDate = date;
                }

                const selectedTimeRangeDate = timeRanges[selectedTimeRange]();
                if (date < selectedTimeRangeDate) {
                    continue;
                }

                const elementsByDate = statsByDate[key];

                const newElement = { database_axis_date_KEY: date };

                let otherValue = 0;
                for (const element of elementsByDate) {
                    newElement[element.key] = element[filter];
                    keys.add(element.key);
                    if (!selectedKeys.includes(element.key)) {
                        otherValue += element[filter];
                    }
                }
                newElement[OTHER_ROW] = otherValue;
                elements.push(newElement);
            }

            elements.sort((a, b) => a.date - b.date);

            setMinDate(minDate);
            setChartData({
                data: elements,
                allKeys: [...Array.from(keys), OTHER_ROW],
            });
            setIsLoading(elements.length === 0);
        }
    }, [stats, selectedLabel, selectedName, getData, filter, selectedTimeRange, selectedKeys]);

    // Handlers
    const handleBackClick = () => {
        if (!selectedLabel && !selectedName) {
            console.warn('Back button clicked when no label or name is selected.');
            return;
        }

        setAutoSelect(true);
        if (selectedName) setSelectedName(null);
        else setSelectedLabel(null);
        setDisplayedKeys([]);
    };

    const handleAreaClick = useCallback(
        (data, index) => {
            if (data === OTHER_ROW) {
                console.log('Clicked on `other` row, but no action taken.');
                return;
            }

            if (segmentMode) {
                if (!selectedName) {
                    setSelectedName(data);
                    setDisplayedKeys([]);
                }
                return;
            }

            if (!selectedLabel) {
                setAutoSelect(true);
                setSelectedLabel(data);
                setDisplayedKeys([]);
                return;
            }

            // Selecting a name
            if (!selectedName) {
                setAutoSelect(true);
                setSelectedName(data);
                setDisplayedKeys([]);
                return;
            }
            // Selecting a value
            console.log('Clicked on area, but no action taken.');
        },
        [selectedLabel, selectedName, setDisplayedKeys, setAutoSelect, setSelectedLabel, setSelectedName, segmentMode],
    );

    const handleLegendClick = (entry) => {
        const entryValue = entry.value.split(' (')[0];

        if (entryValue === OTHER_ROW_LABEL) {
            console.log('Clicked on `other` row, but no action taken.');
            return;
        }
        handleAreaClick(entryValue, entry.index);
    };

    const handleAutoSelect = () => {
        setAutoSelect(!autoSelect);
        setSelectedKeys(otherRow ? [OTHER_ROW] : []);
    };

    const ChartComponent = isArea(chartType) ? AreaChart : LineChart;

    return (
        <Paper sx={styles.paper}>
            {selectedLabel || selectedName ? (
                <Tooltip
                    title={'Back to previous selection'}
                    placement='right'
                    arrow='true'
                    enterDelay={500}
                    leaveDelay={200}
                >
                    <IconButton color='inherit' disableRipple onClick={handleBackClick} sx={styles.back}>
                        <ArrowBackIcon />
                    </IconButton>
                </Tooltip>
            ) : null}
            <Stack direction='row' alignItems='center' justifyContent='center' spacing={1} sx={styles.title}>
                <Typography variant='body1' sx={styles.typography}>
                    {segmentMode ? 'Segment Metrics' : 'Label Metrics'}
                </Typography>
            </Stack>
            <Box>
                {isLoading ? (
                    <Box sx={styles.loading}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <Box>
                        <Box sx={styles.barChartWrapper}>
                            <ChartAttributeSelection
                                selectedLabel={selectedLabel}
                                selectedName={selectedName}
                                segmentMode={segmentMode}
                            />
                            <Box sx={styles.center}>
                                <AttributeStatsFilterControls
                                    filter={filter}
                                    handleFilter={setFilter}
                                    segmentMode={segmentMode}
                                />
                            </Box>
                            <Box sx={styles.mainContainer}>
                                <Box sx={styles.chartContainer}>
                                    <Box sx={styles.chartBox}>
                                        <Box sx={styles.chart}>
                                            <ResponsiveContainer width='95%' height={600}>
                                                <ChartComponent data={chartData.data || []} margin={styles.chartMargin}>
                                                    <CartesianGrid strokeDasharray='3 3' />
                                                    {ChartAxes({ theme, data: chartData, YAuto, selectedKeys })}
                                                    {getChartTooltip({
                                                        selectedKeys: displayedKeys,
                                                        hoveredIndex,
                                                        selectedLabel,
                                                        selectedName,
                                                        filter,
                                                        chartType,
                                                    })}
                                                    {displayedKeys.map((key, index) =>
                                                        renderChartElement({
                                                            key,
                                                            index,
                                                            hoveredLegendKey,
                                                            hoveredIndex,
                                                            selectedName,
                                                            handleAreaClick,
                                                            isStacked,
                                                            otherColor,
                                                            colors,
                                                            chartType,
                                                            setHoveredIndex,
                                                        }),
                                                    )}
                                                    {getChartLegend({
                                                        selectedKeys: displayedKeys,
                                                        allElements,
                                                        handleLegendClick,
                                                        setHoveredLegendKey,
                                                        otherColor,
                                                        colors,
                                                    })}
                                                </ChartComponent>
                                            </ResponsiveContainer>
                                        </Box>
                                    </Box>
                                </Box>
                                <MoreAttributePanel
                                    selectedKeys={selectedKeys}
                                    allElements={allElements}
                                    handleElementSelection={handleElementSelection}
                                />
                            </Box>
                        </Box>
                        <Divider sx={styles.fullWidth} />
                        <ChartConfig
                            chartType={chartType}
                            setChartType={setChartType}
                            isStacked={isStacked}
                            setIsStacked={setIsStacked}
                            autoSelect={autoSelect}
                            handleAutoSelect={handleAutoSelect}
                            otherRow={otherRow}
                            setOtherRow={setOtherRow}
                            selectedTimeRange={selectedTimeRange}
                            setSelectedTimeRange={setSelectedTimeRange}
                            minDate={minDate}
                            YAuto={YAuto}
                            setYAuto={setYAuto}
                        />
                        <Divider sx={{ width: '100%' }} />
                    </Box>
                )}
            </Box>
        </Paper>
    );
};
