import PersonIcon from '@mui/icons-material/Person';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { Autocomplete, Box, TextField, Tooltip, Typography, styled } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
    width: 200,
    '& .MuiInputBase-root': {
        fontSize: '0.875rem',
        padding: '4px 8px',
        color: theme.palette.text.primary,
        '> input': {
            marginRight: '50px',
        },
    },
    '& .MuiAutocomplete-inputRoot': {
        paddingRight: '8px !important',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
    },
    '& .MuiAutocomplete-listbox': {
        width: 'auto',
        minWidth: 500,
    },
}));

const UserSelect = ({ users, selectedUser, setSelectedUser }) => {
    return (
        <StyledAutocomplete
            data-testid='userSelect'
            options={users}
            getOptionLabel={(option) => option.user || ''}
            isOptionEqualToValue={(option, value) => option.user === value.user}
            value={users.find((user) => user.user === selectedUser) || null}
            onChange={(event, newValue) => setSelectedUser(newValue ? newValue.user : '')}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant='outlined'
                    placeholder='User'
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: selectedUser ? <PersonIcon /> : <PersonOutlineIcon />,
                    }}
                />
            )}
            renderOption={(props, option) => (
                <Tooltip title={option.user.length > 15 ? option.user : null} placement='right' arrow key={option.user}>
                    <Box
                        component='li'
                        {...props}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            padding: '4px 8px',
                        }}
                    >
                        <PersonIcon sx={{ marginRight: 1 }} />
                        <Typography noWrap sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                            {option.user}
                        </Typography>
                    </Box>
                </Tooltip>
            )}
            clearOnEscape
        />
    );
};

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(UserSelect);
