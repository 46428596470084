import { useTheme } from '@emotion/react';
import { CheckCircle, Error, HourglassEmpty } from '@mui/icons-material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { Box, CircularProgress, Tooltip } from '@mui/material';
import { red, yellow } from '@mui/material/colors';
import React from 'react';

import { STATE_FAILURE, STATE_PENDING, STATE_STARTED, STATE_SUCCESS } from '../../DataSets/components/TaskState';

export function StateStatus({ state, name }) {
    let icon = <HourglassEmpty style={{ color: yellow[800], fontSize: '2rem' }} />;
    let customName = name || 'Loading...';
    const theme = useTheme();

    if (!state) {
        icon = <CircularProgress title={'Loading...'} color={'grey'} size={20} />;
    } else if (state.state === STATE_PENDING) {
        icon = <HourglassEmpty style={{ color: yellow[800], fontSize: '1.8rem' }} />;
    } else if (state.state === STATE_FAILURE) {
        icon = <Error style={{ color: red[800], fontSize: '1.8rem' }} />;
    } else if (state.state === STATE_SUCCESS) {
        icon = <CheckCircle style={{ color: theme.palette.common.black, fontSize: '1.8rem' }} />;
    } else if (state.state === STATE_STARTED) {
        icon = <PlayArrowIcon style={{ color: theme.palette.primary.main, fontSize: '1.8rem' }} />;
    }
    return (
        <Tooltip title={customName}>
            <Box style={{ margin: '0.5rem 0.5rem 0 0.5rem' }}>{icon}</Box>
        </Tooltip>
    );
}
