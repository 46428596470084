export const timeRanges = {
    'All Time': () => new Date(0),
    'Last Week': () => {
        let date = new Date();
        date.setDate(date.getDate() - 7);
        return date;
    },
    'Last Month': () => {
        let date = new Date();
        date.setMonth(date.getMonth() - 1);
        return date;
    },
    'Last 3 Months': () => {
        let date = new Date();
        date.setMonth(date.getMonth() - 3);
        return date;
    },
    'Last 6 Months': () => {
        let date = new Date();
        date.setMonth(date.getMonth() - 6);
        return date;
    },
    'Last Year': () => {
        let date = new Date();
        date.setFullYear(date.getFullYear() - 1);
        return date;
    },
    'Last 2 Year': () => {
        let date = new Date();
        date.setFullYear(date.getFullYear() - 2);
        return date;
    },
    'Last 5 Year': () => {
        let date = new Date();
        date.setFullYear(date.getFullYear() - 5);
        return date;
    },
    'Last 10 Year': () => {
        let date = new Date();
        date.setFullYear(date.getFullYear() - 10);
        return date;
    },
};

export const validateSelectedTimeRange = (value) => (Object.keys(timeRanges).includes(value) ? value : 'Last Month');
