import { createReducer } from '@reduxjs/toolkit';
import axios from 'axios';

import { actions } from '../actions';

function setAxiosToken(user) {
    axios.defaults.headers.common['Authorization'] = `Token ${user.token}`;
}

function unsetAxiosToken() {
    delete axios.defaults.headers.common['Authorization'];
}

const emptyState = {
    loggingIn: false,
    loggedIn: false,
    user: {
        staff: null,
        perms: {},
    },
    error: null,
};

const initialState = { ...emptyState };
const authenticatedUser = JSON.parse(localStorage.getItem('user'));
if (authenticatedUser) {
    initialState.loggedIn = true;
    initialState.user = authenticatedUser;
    setAxiosToken(authenticatedUser);
}

export const auth = createReducer(initialState, {
    [actions.api.auth.fetch.request]: (state, action) => ({ ...emptyState, loggingIn: true }),
    [actions.api.auth.fetch.success]: (state, action) => {
        setAxiosToken(action.payload);
        return { ...emptyState, loggedIn: true, user: action.payload };
    },
    [actions.api.auth.fetch.failure]: (state, action) => ({ ...emptyState, error: action.payload }),
    [actions.api.auth.fetch.cancel]: (state, actions) => {
        unsetAxiosToken();
        return emptyState;
    },

    [actions.api.auth.validate.request]: (state, action) => ({ ...state, loggingIn: true }),
    [actions.api.auth.validate.success]: (state, action) => ({ ...state, loggingIn: false }),
    [actions.api.auth.validate.failure]: (state, action) => {
        unsetAxiosToken();
        return emptyState;
    },

    [actions.api.auth.refresh.request]: (state, action) => ({ ...state }),
    [actions.api.auth.refresh.success]: (state, action) => {
        setAxiosToken(action.payload);
        return { ...emptyState, loggedIn: true, user: action.payload };
    },
    [actions.api.auth.refresh.failure]: (state, action) => ({ ...state }),
});
