export function generateHashFromData(data) {
    if (data === null || data === undefined) {
        return null;
    }
    const str = JSON.stringify(data);

    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        const char = str.charCodeAt(i);
        hash = (hash << 5) - hash + char;
        hash = hash & hash;
    }
    return hash;
}
