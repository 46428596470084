import moment from 'moment';

function desc(a, b, orderBy) {
    // orderBy can be composed of multiple fields joined by a dot
    // e.g. orderBy = "user.name"
    // In this case, we need to access the nested object
    const orderByFields = orderBy.split('.');
    let valueA = a;
    let valueB = b;
    for (let i = 0; i < orderByFields.length; i++) {
        valueA = valueA[orderByFields[i]];
        valueB = valueB[orderByFields[i]];
    }

    // Number
    if (!isNaN(valueA) && !isNaN(valueB)) {
        return valueB - valueA;
    }

    // Date
    if (
        moment(valueA, 'YYYY-MM-DDTHH:mm:ss.SSSSSS').isValid() &&
        moment(valueB, 'YYYY-MM-DDTHH:mm:ss.SSSSSS').isValid()
    ) {
        const dateA = moment(valueA, 'YYYY-MM-DDTHH:mm:ss.SSSSSS');
        const dateB = moment(valueB, 'YYYY-MM-DDTHH:mm:ss.SSSSSS');

        return dateB.diff(dateA);
    }

    // Number
    if (valueB < valueA) {
        return -1;
    }
    if (valueB > valueA) {
        return 1;
    }

    return 0;
}

export function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

export function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}
