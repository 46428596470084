import { Typography } from '@mui/material';
import React from 'react';
import { Legend } from 'recharts';

import { OTHER_ROW, OTHER_ROW_LABEL } from '../utils';

const renderLegend = ({ payload, onMouseEnter, onMouseLeave, onClick }) => {
    return (
        <ul
            style={{
                listStyleType: 'none',
                padding: 0,
                display: 'flex',
                justifyContent: 'center',
                flexWrap: 'wrap',
                margin: 0,
                minHeight: 30,
            }}
        >
            {payload.map((entry, index) => (
                <li
                    key={`item-${index}`}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        margin: '0 10px',
                        cursor: 'pointer',
                        color: entry.color,
                    }}
                    onMouseEnter={() => onMouseEnter(entry.value)}
                    onMouseLeave={() => onMouseLeave()}
                    onClick={() => onClick(entry)}
                >
                    <span
                        style={{
                            height: '14px',
                            width: '14px',
                            backgroundColor: entry.color,
                            borderRadius: '50%',
                            marginRight: 10,
                            display: 'inline-block',
                        }}
                    ></span>
                    <Typography variant='body2' style={{ fontSize: 14 }}>
                        {entry.value}
                    </Typography>
                    <Typography variant='body2' style={{ fontSize: 12, marginLeft: 1 }}>
                        {`(${entry.number?.toLocaleString('en-GB') || 0})`}
                    </Typography>
                </li>
            ))}
        </ul>
    );
};
export const getChartLegend = (props) => {
    const { selectedKeys, allElements, handleLegendClick, setHoveredLegendKey, otherColor, colors } = props;

    return (
        <Legend
            content={renderLegend}
            onClick={handleLegendClick}
            onMouseEnter={setHoveredLegendKey}
            onMouseLeave={setHoveredLegendKey}
            payload={selectedKeys.map((key, index) => {
                const currentValue = allElements.find((el) => el.key === key)?.value || 0;
                return {
                    value: key === OTHER_ROW ? OTHER_ROW_LABEL : `${key}`,
                    number: currentValue,
                    color: key === OTHER_ROW ? otherColor : colors[index % colors.length],
                };
            })}
        />
    );
};
