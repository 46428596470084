import { useTheme } from '@emotion/react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { actions } from '../../actions';
import BodyBuilderGrid from './BodyBuilderGrid';
import ValidationButton from './ValidationButton';

function _normalizeDate(date) {
    return moment(date).format('YYYY-MM-DDTHH:mm:ss');
}

function LDate(props) {
    const { onChange, defaultValue, nodeId, setValidation, disableValidation } = props;
    const theme = useTheme();

    const [updated, setUpdated] = useState(true);
    const [value, setValue] = useState(_normalizeDate(new Date()));

    useEffect(() => {
        if (Array.isArray(defaultValue) && defaultValue.length === 1) {
            setValue(_normalizeDate(defaultValue[0]));
        }
    }, [setValue, defaultValue]);

    const handleValidationClick = () => {
        if (!moment(value).isValid()) {
            setUpdated(true);
            return;
        }

        if (!disableValidation) setValidation({ nodeId, validate: true });
        setUpdated(false);
        onChange([value]);
    };

    const handleDateChange = (newDate) => {
        if (!disableValidation) setValidation({ nodeId, validate: false });
        setValue(_normalizeDate(newDate));
        setUpdated(true);
    };

    return (
        <BodyBuilderGrid>
            <Grid item>
                <DateTimePicker
                    value={value}
                    onChange={handleDateChange}
                    renderInput={(params) => <TextField {...params} />}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': (!value || !moment(value).isValid()) && {
                                borderColor: theme.palette.error.main,
                            },
                        },
                    }}
                />
            </Grid>
            <Grid item>
                <ValidationButton onClick={handleValidationClick} updated={updated} />
            </Grid>
        </BodyBuilderGrid>
    );
}

LDate.propTypes = {
    onChange: PropTypes.func.isRequired,
    nodeId: PropTypes.string.isRequired,
    defaultValue: PropTypes.array,
};

const mapDispatchToProps = (dispatch) => {
    return {
        setValidation: (...args) => dispatch(actions.qbuilder.validation.set(...args)),
    };
};

export default connect(null, mapDispatchToProps)(LDate);
