import { TableCell, TableHead, TableRow, TableSortLabel, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';

function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort, staff, headCells } = props;

    const theme = useTheme();
    const styles = {
        label: {
            fontWeight: 'bold',
            fontSize: '16px',
            color: theme.palette.primary.main,
        },
        cell: {
            borderBottom: '1px solid ' + theme.palette.primary.main,
            paddingRight: '0px',
            backgroundColor: theme.palette.common.paper,
        },
    };
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map(
                    (headCell) =>
                        ((headCell.staff && staff) || !headCell.staff) && (
                            <TableCell
                                key={headCell.id}
                                align={headCell.align ? headCell.align : 'left'}
                                sortDirection={orderBy === headCell.id ? order : false}
                                sx={styles.cell}
                            >
                                <TableSortLabel
                                    active={orderBy === headCell.id}
                                    direction={orderBy === headCell.id ? order : 'asc'}
                                    onClick={createSortHandler(headCell.id)}
                                >
                                    <Typography sx={styles.label}>{headCell.label}</Typography>
                                    {orderBy === headCell.id ? (
                                        <span className={classes.visuallyHidden}>
                                            {/* {order === 'desc' ? 'sorted descending' : 'sorted ascending'} */}
                                        </span>
                                    ) : null}
                                </TableSortLabel>
                            </TableCell>
                        ),
                )}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

export default EnhancedTableHead;
