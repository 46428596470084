import { createReducer } from '@reduxjs/toolkit';

import { actions } from '../actions';

const initialHealthState = {
    responseTime: null, // ms
    error: null,
    isFetching: false,
    annProcessing: false,
};
const initialDataHealthState = {
    responseTime: null, // ms
    error: null,
    isFetching: false,
};

const handleHealthSuccess = (state, action) => {
    return {
        ...state,
        ...action.payload,
        isFetching: false,
    };
};
export const health = createReducer(initialHealthState, {
    [actions.api.health.request]: (state, action) => ({ ...state, error: null, isFetching: true }),
    [actions.api.health.success]: (state, action) => handleHealthSuccess(state, action),
    [actions.api.health.failure]: (state, action) => ({
        ...state,
        error: actions.payload,
        responseTime: null,
        isFetching: false,
    }),
});

export const data_health = createReducer(initialDataHealthState, {
    [actions.api.data_health.request]: (state, action) => ({ ...state, error: null, isFetching: true }),
    [actions.api.data_health.success]: (state, action) => ({
        ...state,
        responseTime: action.payload,
        isFetching: false,
    }),
    [actions.api.data_health.failure]: (state, action) => ({
        ...state,
        error: actions.payload,
        responseTime: null,
        isFetching: false,
    }),
});
