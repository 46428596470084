import { TableBody, TableCell, TableRow } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

function LoadingTable(props) {
    const { isLoading, message } = props;
    const [showNoDataMessage, setShowNoDataMessage] = useState(false);

    useEffect(() => {
        let timeout;
        if (!isLoading) {
            timeout = setTimeout(() => {
                setShowNoDataMessage(true);
            }, 2000);
        } else {
            setShowNoDataMessage(false);
        }

        return () => clearTimeout(timeout);
    }, [isLoading]);

    return (
        <TableBody>
            <TableRow>
                {showNoDataMessage ? (
                    <TableCell colSpan={7} align='center'>
                        {message}
                    </TableCell>
                ) : (
                    <TableCell colSpan={7} align='center'>
                        Loading...
                    </TableCell>
                )}
            </TableRow>
        </TableBody>
    );
}

LoadingTable.propTypes = {
    isLoading: PropTypes.bool.isRequired,
};

export default LoadingTable;
