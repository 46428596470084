import { createAction } from '@reduxjs/toolkit';

export const qbuilder = {
    // Used to represent Query Builder Trees
    node: {
        add: createAction('qbuilder/node/add'),
        clear: createAction('qbuilder/node/clear'),
        edit: createAction('qbuilder/node/edit'),
        remove: createAction('qbuilder/node/remove'),
    },
    query: {
        version: createAction('qbuilder/query/version'),
    },
    // Used to represent the specs of the query
    specs: {
        edit: createAction('qbuilder/specs/edit'),
        clear: createAction('qbuilder/specs/clear'),
    },
    error: createAction('qbuilder/error'),
    validation: {
        set: createAction('qbuilder/validation'),
        update: createAction('qbuilder/validation/update'),
    },
    rerun: {
        request: createAction('qbuilder/rerun/request'),
        success: createAction('qbuilder/rerun/success'),
        failure: createAction('qbuilder/rerun/failure'),
    },
};
