import { all, put, takeEvery } from 'redux-saga/effects';

import { actions } from '../actions';

function* updateDataset(action) {
    const data = action.payload;
    yield put(actions.api.data.fetch.success(data));
}

export default function* watchDataset() {
    yield all([yield takeEvery(actions.dataset.update.toString(), updateDataset)]);
}
