import axios from 'axios';

const root = 'api/data/stats/';

function getStats(params) {
    return axios.get(root, {
        params: {
            ...params,
        },
    });
}

export const stats = {
    stats: getStats,
};
