import { useTheme } from '@emotion/react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box, Checkbox, FormControlLabel, IconButton, Tooltip, Typography } from '@mui/material';
import React, { useState } from 'react';

import { getMoreAttributeStyles } from '../styles/StyleComponent';
import { OTHER_ROW } from '../utils';

export const MoreAttributePanel = ({ selectedKeys, allElements, handleElementSelection }) => {
    const theme = useTheme();
    const styles = getMoreAttributeStyles(theme);
    const [showPanel, setShowPanel] = useState(false);

    return (
        <Box sx={{ ...styles.container, flex: showPanel ? '0 0 300px' : '0 0 0px' }}>
            <Box sx={styles.panelHeader}>
                <Box
                    sx={{
                        ...styles.panelHeader,
                        borderBottom: showPanel ? `1px solid ${theme.palette.divider}` : 'none',
                    }}
                >
                    {showPanel && (
                        <Typography variant='subtitle1' sx={styles.valueTitle}>
                            Available Values
                        </Typography>
                    )}
                </Box>
                <Tooltip
                    title={showPanel ? 'Hide available values' : 'Show available values'}
                    placement='top'
                    arrow={true}
                    enterDelay={500}
                    leaveDelay={200}
                >
                    <IconButton color='inherit' sx={styles.toggleButton} onClick={() => setShowPanel((prev) => !prev)}>
                        {showPanel ? <ArrowForwardIosIcon /> : <ArrowBackIosIcon sx={{ marginLeft: '8px' }} />}
                    </IconButton>
                </Tooltip>
            </Box>

            <Box sx={showPanel ? styles.panel : styles.panelHidden}>
                {showPanel && (
                    <>
                        {allElements
                            .filter((el) => el.key !== OTHER_ROW)
                            .map((el) => (
                                <FormControlLabel
                                    key={el.key}
                                    control={
                                        <Checkbox
                                            checked={selectedKeys.includes(el.key)}
                                            onChange={() => handleElementSelection(el.key)}
                                            sx={styles.checkbox}
                                        />
                                    }
                                    label={
                                        <Typography variant='body2' component='span' sx={styles.labelTypography}>
                                            {el.key}
                                            <Typography component='span' sx={styles.valueTypography}>
                                                {' (' + (el.value?.toLocaleString('en-GB') || 0) + ')'}
                                            </Typography>
                                        </Typography>
                                    }
                                />
                            ))}
                    </>
                )}
            </Box>
        </Box>
    );
};
