import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';

import { actions } from '../../../../actions';
import { api } from '../../../../services';
import { eventStatusButton, eventTypes } from '../utils/constants';
import EventBase from './EventBase';

function EventUrlHandler(props) {
    const { fetchAnnotationUsers, fetchWarningCodes, annotationUsers, warningCodes } = props;
    const [searchParams, setSearchParams] = useSearchParams();
    const location = useLocation();

    const validatePageParam = (page) => {
        const pageParam = parseInt(page, 10);
        return !isNaN(pageParam) && pageParam > 0 ? pageParam : 1;
    };

    const validateTypeParam = (typeParam) => {
        if (Object.values(eventTypes).indexOf(typeParam) === -1) {
            return 'all';
        }

        return typeParam.toLowerCase();
    };

    const validateStatusParam = (statusParam) => {
        if (Object.values(eventStatusButton).indexOf(statusParam?.toUpperCase()) === -1) {
            return 'all';
        }

        return statusParam?.toLowerCase() || 'all';
    };

    const validateInteger = (value) => {
        if (value === null) return 0;
        const intValue = parseInt(value, 10);
        return isNaN(intValue) ? 0 : intValue;
    };

    const validateDate = (date, default_value = '') => {
        if (date === 'null') return '';
        if (!date) return '';
        if (date === 'default') return default_value;

        const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
        if (!dateRegex.test(date)) return default_value;
        if (!moment(date).isValid()) return default_value;

        return date;
    };

    const [currentPage, setCurrentPage] = useState(validatePageParam(searchParams.get('page')));
    const [selectedWarningCode, setSelectedWarningCode] = useState(validateInteger(searchParams.get('warning_code')));
    const [selectedUser, setSelectedUser] = useState(searchParams.get('user') || null);
    const [startDate, setStartDate] = useState(
        validateDate(searchParams.get('start_date'), moment().subtract(1, 'week').format('YYYY-MM-DD')),
    );
    const [endDate, setEndDate] = useState(validateDate(searchParams.get('end_date')));
    const [type, setType] = useState(validateTypeParam(searchParams.get('type')));
    const [selectedStatus, setSelectedStatus] = useState(validateStatusParam(searchParams.get('status')));
    const [search, setSearch] = useState(searchParams.get('search') || '');

    useEffect(() => {
        fetchWarningCodes();
        fetchAnnotationUsers();
    }, [fetchWarningCodes, fetchAnnotationUsers]);

    useEffect(() => {
        // Handle navbar notification click
        if (location.state && location.state.status !== undefined) {
            setSelectedStatus(validateStatusParam(location.state.status));
        }
        if (location.state && location.state.type !== undefined) {
            setType(validateTypeParam(location.state.type));
        }
        if (location.state && location.state.page !== undefined) {
            setCurrentPage(validatePageParam(location.state.page));
        }
        if (location.state && location.state.start_date !== undefined) {
            setStartDate(validateDate(location.state.start_date));
        }
        if (location.state && location.state.end_date !== undefined) {
            setEndDate(validateDate(location.state.end_date));
        }
        if (location.state && location.state.warning_code !== undefined) {
            setSelectedWarningCode(validateInteger(location.state.warning_code));
        }
        if (location.state && location.state.user !== undefined) {
            setSelectedUser(location.state.user);
        }
        if (location.state && location.state.search !== undefined) {
            setSearch(location.state.search);
        }
    }, [location.state]);

    useEffect(() => {
        const newParams = new URLSearchParams(searchParams);
        if (currentPage) {
            newParams.set('page', currentPage.toString());
        } else {
            newParams.delete('page');
        }
        if (type) {
            newParams.set('type', type);
        } else {
            newParams.delete('type');
        }
        if (search) {
            newParams.set('search', search);
        } else {
            newParams.delete('search');
        }
        if (selectedStatus) {
            newParams.set('status', selectedStatus.toLowerCase());
        } else {
            newParams.delete('status');
        }
        if (selectedWarningCode) {
            newParams.set('warning_code', selectedWarningCode.toString());
        } else {
            newParams.delete('warning_code');
        }
        if (selectedUser && selectedUser !== '') {
            newParams.set('user', selectedUser);
        } else {
            newParams.delete('user');
        }
        if (startDate || startDate === '') {
            newParams.set('start_date', startDate);
        } else {
            newParams.set('start_date', '');
        }
        if (endDate) {
            newParams.set('end_date', endDate);
        } else {
            newParams.delete('end_date');
        }
        setSearchParams(newParams, { replace: true });
    }, [
        currentPage,
        setSearchParams,
        searchParams,
        type,
        search,
        selectedStatus,
        selectedWarningCode,
        selectedUser,
        startDate,
        endDate,
    ]);

    const handleClear = () => {
        setCurrentPage(validatePageParam(null));
        setType(validateTypeParam(null));
        setSearch('');
        setSelectedStatus(validateStatusParam(null));
        setSelectedWarningCode(validateInteger(null));
        setSelectedUser(null);
        setStartDate(null);
        setEndDate(null);
    };

    return (
        <EventBase
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            selectedWarningCode={selectedWarningCode}
            setSelectedWarningCode={setSelectedWarningCode}
            selectedUser={selectedUser}
            setSelectedUser={setSelectedUser}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            type={type}
            setType={setType}
            selectedStatus={selectedStatus}
            setSelectedStatus={setSelectedStatus}
            search={search}
            setSearch={setSearch}
            annotationUsers={annotationUsers}
            warningCodes={warningCodes}
            handleClear={handleClear}
        />
    );
}

const mapStateToProps = (state) => {
    return {
        annotationUsers: state.data[api.endpoints.annUsers]?.data || [],
        warningCodes: state.data[api.endpoints.annWarningCodes]?.data || [],
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchAnnotationUsers: () => dispatch(actions.api.data.fetch.request({ endpoint: api.endpoints.annUsers })),
        fetchWarningCodes: () =>
            dispatch(
                actions.api.data.fetch.request({
                    endpoint: api.endpoints.annWarningCodes,
                }),
            ),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EventUrlHandler);
