import { createAction } from '@reduxjs/toolkit';

export const notification = {
    add: {
        failure: createAction('notification/add/failure'),
        success: createAction('notification/add/success'),
        warning: createAction('notification/add/warning'),
    },
    remove: createAction('notification/delete'),
};
