import axios from 'axios';
import moment from 'moment';
import urljoin from 'url-join';

function formatUrlParams(url, params) {
    url.endsWith('/') || (url += '/');

    if (params) {
        url += '?';
        const maxLen = Object.keys(params).length - 1;
        Object.keys(params).forEach((key, i) => {
            if (params[key]) url += `${key}=${params[key]}${i < maxLen ? '&' : ''}`;
        });
    }
    return url;
}

function fetch(
    endpoint,
    id = null,
    datetime = null,
    path = null,
    page = null,
    hash = null,
    size = null,
    name = null,
    value = null,
    active = null,
    q = null,
    type = null,
    status = null,
    project_id = null,
    warning_code = null,
    user = null,
    start_date = null,
    end_date = null,
    refresh = null,
) {
    const params = {};
    if (datetime !== null) params.datetime_gt = moment.utc(datetime).format('YYYY-MM-DD HH:MM:ss');
    if (path !== null) params.path = path;
    if (page !== null) params.page = page;
    if (hash !== null) params.hash = hash;
    if (size !== null) params.size = size;
    if (name !== null) params.name = name;
    if (value !== null) params.value = value;
    if (active !== null) params.active = active;
    if (q !== null) params.q = q;
    if (type !== null) params.type = type;
    if (status !== null) params.status = status;
    if (project_id !== null) params.project_id = project_id;
    if (warning_code !== null) params.warning_code = warning_code;
    if (user !== null) params.user = user;
    if (start_date !== null) params.start_date = start_date;
    if (end_date !== null) params.end_date = end_date;
    if (refresh !== null) params.refresh = refresh;

    return axios.get(urljoin(endpoint, id ? id.toString() : '', '/'), {
        params,
    });
}

function remove(endpoint, id) {
    if (!id) throw new Error("Can't remove `undefined` ID");
    const url = urljoin(endpoint, id.toString());
    return axios.delete(url);
}

function put(endpoint, id, data, noid = false, params = {}) {
    if (!id && !noid) throw new Error("Can't update `undefined` ID");
    let url = noid ? endpoint : urljoin(endpoint, id.toString());
    url = formatUrlParams(url, params);
    return axios.put(url, data, params);
}

function post(endpoint, data, params) {
    const url = formatUrlParams(endpoint, params);
    return axios.post(url, data);
}

export const data = {
    fetch,
    remove,
    put,
    post,
};
