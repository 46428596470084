import { all, call, put, select, takeEvery, takeLatest } from '@redux-saga/core/effects';

import { actions } from '../actions';
import { denormalizeTree } from '../reducers/qbuilder';
import { api } from '../services';
import { retrySaga } from './apiWrapper';

function* registerDataEndpointsToTracker() {
    const info = yield select((state) => ({
        doc: state.qbuilder.doc,
        alreadyRegisteredEndpoints: new Set(Object.keys(state.data)),
    }));

    const endpointsToRegister = new Set();

    Object.values(info.doc).forEach((doc) => {
        Object.values(doc)
            .map((filterNodeDoc) => filterNodeDoc.endpoint)
            .filter((endpoint) => endpoint !== undefined && !info.alreadyRegisteredEndpoints.has(endpoint))
            .forEach((endpoint) => endpointsToRegister.add(endpoint));
    });

    for (const endpoint of endpointsToRegister) yield put(actions.api.data.tracker.add({ endpoint }));
}

function* getDoc() {
    try {
        const docFrameResponse = yield call(retrySaga, api.qbuilder.doc.frame);
        const docSegmentResponse = yield call(retrySaga, api.qbuilder.doc.segment);
        yield put(
            actions.api.qbuilder.doc.success({
                frame: docFrameResponse.data,
                segment: docSegmentResponse.data,
            }),
        );
    } catch (e) {
        yield put(actions.api.qbuilder.doc.failure(e));
    }
}

export function getQueryState(state) {
    return {
        specs: state.qbuilder.specs,
        querySelectedSegments: denormalizeTree('selected_segments', state.qbuilder.nodes),
        querySelectedFrames: denormalizeTree('selected_frames', state.qbuilder.nodes),
        queryExcludedFrames: denormalizeTree('excluded_frames', state.qbuilder.nodes),
        version: state.qbuilder.query.version,
    };
}

function* buildDataset(action) {
    try {
        const queryState = yield select(getQueryState);
        yield call(retrySaga, api.qbuilder.build, [queryState]);
        yield put(actions.api.qbuilder.build.success());
        yield put(actions.qbuilder.node.clear());
    } catch (e) {
        yield put(actions.api.qbuilder.build.failure(e));
    }
}

function getDBState(state) {
    return {
        name: state.qbuilder.specs.name,
    };
}

function* checkDataset(action) {
    try {
        const dbState = yield select(getDBState);
        yield call(retrySaga, api.qbuilder.check, [dbState]);
        yield put(actions.api.qbuilder.check.success(action.payload));
    } catch (e) {
        yield put(actions.api.qbuilder.check.failure(action.payload));
    }
}

function* dataRerun(action) {
    const { endpoint, row } = action.payload;
    try {
        yield call(retrySaga, api.qbuilder.rerun, [endpoint, row]);
        yield put(actions.api.qbuilder.rerun.success({ endpoint, row }));
    } catch (e) {
        yield put(actions.api.qbuilder.rerun.failure({ endpoint, row, error: e }));
    }
}

export default function* watchQBuilder() {
    yield all([
        yield takeLatest(actions.api.qbuilder.doc.request.toString(), getDoc),
        yield takeEvery(actions.api.qbuilder.doc.success.toString(), registerDataEndpointsToTracker),
        yield takeEvery(actions.api.qbuilder.build.request.toString(), buildDataset),
        yield takeEvery(actions.api.qbuilder.check.request.toString(), checkDataset),
        yield takeEvery(actions.qbuilder.rerun.request.toString(), dataRerun),
    ]);
}
