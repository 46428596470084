import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import LabelIcon from '@mui/icons-material/Label';
import PanToolAltIcon from '@mui/icons-material/PanToolAlt';
import { Box, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';

import { getAttributeSelectionStyles } from '../styles/StyleComponent';

const ChartAttributeSelection = (props) => {
    const { selectedLabel, selectedName, segmentMode } = props;
    const theme = useTheme();
    const styles = getAttributeSelectionStyles(theme);

    if (segmentMode) {
        return (
            <Box sx={styles.container}>
                <Grid container spacing={0} alignItems='center' justifyContent='center'>
                    <Grid item sm={10} md={10} xl={10} sx={styles.centerGrid}>
                        {(selectedName && (
                            <>
                                <LabelIcon sx={styles.icon} />
                                <Typography variant='body1'>{selectedName}</Typography>
                            </>
                        )) || (
                            <>
                                <PanToolAltIcon sx={styles.icon} />
                                <Typography variant='body1'>Click on a segment attribute to select it...</Typography>
                            </>
                        )}
                    </Grid>
                </Grid>
            </Box>
        );
    }

    return (
        <Box sx={styles.container}>
            <Grid container spacing={0} alignItems='center' justifyContent='center'>
                <Grid item sm={4} md={4} xl={4} sx={styles.centerGrid}>
                    {(selectedLabel && (
                        <>
                            <LabelIcon sx={styles.icon} />
                            <Typography variant='body1'>{selectedLabel}</Typography>
                        </>
                    )) || (
                        <>
                            <PanToolAltIcon sx={styles.icon} />
                            <Typography variant='body1'>Click on a label to select it...</Typography>
                        </>
                    )}
                </Grid>
                {selectedLabel && (
                    <Grid item sm={4} md={4} xl={4} sx={styles.centerGrid}>
                        {(selectedName && (
                            <>
                                <FormatAlignJustifyIcon sx={styles.icon} />
                                <Typography variant='body1'>{selectedName}</Typography>
                            </>
                        )) || (
                            <>
                                <PanToolAltIcon sx={styles.icon} />
                                <Typography variant='body1'>Click on an attribute to select it...</Typography>
                            </>
                        )}
                    </Grid>
                )}
            </Grid>
        </Box>
    );
};

export default ChartAttributeSelection;
