import * as icons from '@mui/icons-material';
import {
    CircularProgress,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Tooltip,
    Typography,
    useTheme,
} from '@mui/material';
import React, { useState } from 'react';

import { api } from '../../../../services/api';

export const AVAILABLE_TASKS = {
    DB_QUERY: 'DB_QUERY',
    DS_BUILD: 'DS_BUILD',
    COMPRESS: 'COMPRESS',
};

export const STATE_SUCCESS = 'SUCCESS';
export const STATE_FAILURE = 'FAILURE';
export const STATE_STARTED = 'STARTED';
export const STATE_PENDING = 'PENDING';

export function getWarningMessageForTaskState(states) {
    if (!states) return null;

    const stateDBQuery = states?.[AVAILABLE_TASKS.DB_QUERY];
    const nbFailures = stateDBQuery?.result?.failures?.length || 0;

    if (!nbFailures) return null;

    let message = `${nbFailures} Failure${nbFailures > 1 ? 's' : ''}`;
    for (const failure of stateDBQuery.result.failures) {
        message += `\n${failure}`;
    }
    return message;
}

export function getTitleForTaskState(states, warning = false) {
    if (!states) return 'Loading...';

    const stateDBQuery = states?.[AVAILABLE_TASKS.DB_QUERY];
    const stateQSBuild = states?.[AVAILABLE_TASKS.DS_BUILD];
    const stateCompress = states?.[AVAILABLE_TASKS.COMPRESS];

    let title = '';

    switch (stateDBQuery?.state) {
        case STATE_FAILURE:
            title = 'Failed. Click to have more information.';
            break;
        case STATE_PENDING:
            title = 'Step 1: Waiting for a worker to query the database...';
            break;
        case STATE_STARTED:
            if (stateQSBuild?.progress > 0 && stateQSBuild.total > 0) {
                title = `Step 2: ${Math.round(stateQSBuild.progress * stateQSBuild.total)}/${
                    stateQSBuild.total
                } (${Math.round(stateQSBuild.progress * 100)}%)`;
            } else {
                title = 'Step 2: Creating sub-tasks...';
            }
            break;
        case STATE_SUCCESS:
            switch (stateCompress.state) {
                case STATE_FAILURE:
                    title = 'Failed. Click to have more information.';
                    break;
                case STATE_PENDING:
                    title = 'Step 3: Waiting for a worker to compress the snapshot...';
                    break;
                case STATE_STARTED:
                    title = 'Step 3: The snapshot is being compressed...';
                    break;
                case STATE_SUCCESS:
                    switch (stateQSBuild.state) {
                        case STATE_FAILURE:
                            title = 'Failed. Click to have more information.';
                            break;
                        case STATE_SUCCESS:
                            title = 'Success. Click to download the snapshot.';
                            if (warning) {
                                const annPlural =
                                    stateDBQuery?.result?.failures.length > 1 ? 'annotations' : 'annotation';
                                title += `\nWARNING
                                ${stateDBQuery?.result?.failures.length} ${annPlural} failed to be processed.`;
                            }
                            break;
                        case STATE_PENDING:
                            title = 'Step 4: Waiting for a worker to upload the snapshot...';
                            break;
                        case STATE_STARTED:
                            title = 'Step 4: The snapshot is being uploaded...';
                            break;
                        default:
                            title = 'Unknown state for Building Snapshot';
                            break;
                    }
                    break;
                default:
                    title = 'Unknown state during compression';
                    break;
            }
            break;
        default:
            title = 'Unknown state for Querying DB';
            break;
    }

    return title;
}

function TaskStateUnknown(props) {
    const theme = useTheme();
    const { state, taskName } = props;
    return (
        <Tooltip data-testid={'task-state-unknown'} title={`Unknown state: "${state}" for task "${taskName}"`}>
            <IconButton>
                <icons.Warning style={{ color: theme.palette.warning.main }} />
            </IconButton>
        </Tooltip>
    );
}

function TaskStateStarted(props) {
    const { title, ...rest } = props;
    return (
        <Tooltip title={title} data-testid={'task-state-started'}>
            <CircularProgress {...rest} />
        </Tooltip>
    );
}

function TaskStatePendingWorker(props) {
    const { title } = props;
    const theme = useTheme();
    return (
        <Tooltip title={title} data-testid={'task-state-pending'}>
            <icons.Timer style={{ color: theme.palette.warning.main }} />
        </Tooltip>
    );
}

function TaskStateProgress(props) {
    const { title, progress } = props;
    const theme = useTheme();

    return (
        <Tooltip title={title} data-testid={'task-state-progress'}>
            <CircularProgress
                variant='determinate'
                value={progress * 100}
                style={{ color: theme.palette.secondary.main }}
            />
        </Tooltip>
    );
}

function handleDownloadDataSet(path) {
    api.data.fetch('api/ds-tasks/snapshot', null, null, path).then((resp) => {
        window.open(resp.data, '_blank');
    });
}

function MultilineTooltip({ title }) {
    const lines = title.split('\n').map((line, index) => (
        <Typography key={index} variant='body3' align='center' style={{ display: 'block' }}>
            {line}
        </Typography>
    ));
    return <React.Fragment>{lines}</React.Fragment>;
}

function TaskStateSuccess(props) {
    const { warning, title, result } = props;

    const theme = useTheme();

    const color = warning ? theme.palette.warning.main : theme.palette.primary.main;

    return (
        <Tooltip title={<MultilineTooltip title={title} />} data-testid={'task-state-success'}>
            <IconButton onClick={() => handleDownloadDataSet(result)}>
                <icons.Download style={{ color: color }} />
            </IconButton>
        </Tooltip>
    );
}

function TaskStateFailure(props) {
    const { error, taskName } = props;
    const [openDialog, setOpenDialog] = useState(false);

    return (
        <div data-testid={'task-state-failure'}>
            <Tooltip title='Failed. Click to have more information.'>
                <IconButton onClick={() => setOpenDialog(true)}>
                    <icons.Error color='error' />
                </IconButton>
            </Tooltip>
            <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
                <DialogTitle>{`Failed to perform "${taskName}". Error details`}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{error}</DialogContentText>
                </DialogContent>
            </Dialog>
        </div>
    );
}

function TaskState(props) {
    const { states } = props;

    if (!states) {
        return <CircularProgress title={'Loading...'} color={'grey'} size={20} />;
    }

    const stateDBQuery = states?.[AVAILABLE_TASKS.DB_QUERY];
    const stateQSBuild = states?.[AVAILABLE_TASKS.DS_BUILD];
    const stateCompress = states?.[AVAILABLE_TASKS.COMPRESS];

    const warning = stateDBQuery?.result?.failures && stateDBQuery?.result?.failures.length > 0;
    const title = getTitleForTaskState(states, warning);

    switch (stateDBQuery?.state) {
        case STATE_FAILURE:
            return <TaskStateFailure error={stateDBQuery?.error} taskName={'Querying DB'} />;

        case STATE_PENDING:
            return <TaskStatePendingWorker title={title} />;

        case STATE_STARTED:
            if (stateQSBuild?.progress > 0) {
                return <TaskStateProgress progress={stateQSBuild?.progress} title={title} />;
            }
            return <TaskStateStarted color='warning' title={title} />;

        case STATE_SUCCESS:
            switch (stateCompress.state) {
                case STATE_FAILURE:
                    return <TaskStateFailure error={stateCompress.error} taskName={'Compressing Snapshot'} />;

                case STATE_PENDING:
                    return <TaskStatePendingWorker title={title} />;
                case STATE_STARTED:
                    return <TaskStateStarted color='secondary' title={title} />;
                case STATE_SUCCESS:
                    switch (stateQSBuild.state) {
                        case STATE_FAILURE:
                            return <TaskStateFailure error={stateQSBuild.error} taskName={'Building Snapshot'} />;
                        case STATE_SUCCESS:
                            return <TaskStateSuccess result={stateQSBuild.result} title={title} warning={warning} />;
                        case STATE_PENDING:
                            return <TaskStatePendingWorker title={title} />;
                        case STATE_STARTED:
                            return <TaskStateStarted color='secondary' title={title} />;
                        default:
                            return <TaskStateUnknown taskName={AVAILABLE_TASKS.DS_BUILD} state={stateQSBuild?.state} />;
                    }
                default:
                    return;
            }

        default:
            return <TaskStateUnknown taskName={AVAILABLE_TASKS.DB_QUERY} state={stateDBQuery?.state} />;
    }
}

export default TaskState;
