import React from 'react';

import EventUrlHandler from './components/EventUrlHandler';
import './styles/animations.css';

function EventPanel() {
    return <EventUrlHandler />;
}

export default EventPanel;
