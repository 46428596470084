import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';

import { PROJECT_ENVIRONMENTS } from '../panels/ProjectCreation/constant';

export const CustomLegend = function (props) {
    const { padding } = props;

    const theme = useTheme();
    const styles = {
        legendItem: {
            display: 'flex',
            alignItems: 'center',
        },
        legendColorBox: {
            width: '12px',
            height: '12px',
            marginRight: theme.spacing(1),
            borderRadius: '50%',
        },
        legendText: {
            fontSize: '12px',
        },
    };

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                flexWrap: 'wrap',
                padding: padding,
                width: '100%',
            }}
        >
            {PROJECT_ENVIRONMENTS.map((env, index) => (
                <Box
                    key={env}
                    sx={{
                        ...styles.legendItem,
                        marginRight: index === PROJECT_ENVIRONMENTS.length - 1 ? 0 : theme.spacing(2),
                    }}
                >
                    <Box
                        sx={{
                            ...styles.legendColorBox,
                            backgroundColor: theme.palette.project.colors[index],
                        }}
                    />
                    <Typography variant='body2' component='span' sx={styles.legendText}>
                        {env}
                    </Typography>
                </Box>
            ))}
        </Box>
    );
};
