import { createReducer } from '@reduxjs/toolkit';
import { v1 as uuidv1 } from 'uuid';

import { actions } from '../actions';

const initialState = [];

const reduceAddNotification = (variant, state, action) => {
    return [
        ...state,
        {
            key: uuidv1(),
            message: action.payload,
            options: {
                variant,
            },
        },
    ];
};

const reduceRemoveNotification = (state, action) => {
    return state.filter((n) => n.key !== action.payload);
};

export const notification = createReducer(initialState, {
    [actions.notification.add.failure]: (state, action) => reduceAddNotification('error', state, action),
    [actions.notification.add.success]: (state, action) => reduceAddNotification('success', state, action),
    [actions.notification.add.warning]: (state, action) => reduceAddNotification('warning', state, action),
    [actions.notification.remove]: reduceRemoveNotification,
});
