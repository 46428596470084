import { Done } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

import ConfirmButton from './ConfirmButton';

function ConfirmRestoreButton(props) {
    const { onClick, elementTitle, disabled } = props;

    return (
        <ConfirmButton
            onClick={onClick}
            title='Are you sure?'
            content={`If you restore this ${elementTitle}, it will be accessible again.`}
            disabled={disabled}
        >
            <Tooltip title={`Restore this ${elementTitle}`}>
                <Done />
            </Tooltip>
        </ConfirmButton>
    );
}

ConfirmRestoreButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    elementTitle: PropTypes.string.isRequired,
};

export default ConfirmRestoreButton;
