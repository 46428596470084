import React from 'react';

import StatsAnnotations from '../../components/Metrics/Metrics/StatsAnnotations';

function HomePanel() {
    return (
        <div>
            <StatsAnnotations />
        </div>
    );
}

export default HomePanel;
