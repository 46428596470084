import { useTheme } from '@emotion/react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { actions } from '../../actions';
import BodyBuilderGrid from './BodyBuilderGrid';
import ValidationButton from './ValidationButton';

function LStr(props) {
    const { onChange, defaultValue, nodeId, setValidation, disableValidation } = props;
    const theme = useTheme();

    const [updated, setUpdated] = useState(true);
    const [value, setValue] = useState('');

    useEffect(() => {
        if (Array.isArray(defaultValue) && defaultValue.length === 1) setValue(defaultValue[0]);
    }, [setValue, defaultValue]);

    const handleValidationClick = () => {
        if (!disableValidation) setValidation({ nodeId, validate: true });
        setUpdated(false);
        onChange([value]);
    };

    return (
        <BodyBuilderGrid>
            <Grid item>
                <TextField
                    variant='outlined'
                    value={value}
                    label='Value'
                    onChange={(event) => {
                        if (!disableValidation) setValidation({ nodeId, validate: false });
                        setValue(event.target.value);
                        setUpdated(true);
                    }}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': !value?.trim() && {
                                borderColor: theme.palette.error.main,
                            },
                        },
                    }}
                />
            </Grid>
            <Grid item>
                <ValidationButton onClick={handleValidationClick} updated={updated} disabled={value === null} />
            </Grid>
        </BodyBuilderGrid>
    );
}

LStr.propTypes = {
    onChange: PropTypes.func.isRequired,
    nodeId: PropTypes.string.isRequired,
    defaultValue: PropTypes.array,
};

const mapDispatchToProps = (dispatch) => {
    return {
        setValidation: (...args) => dispatch(actions.qbuilder.validation.set(...args)),
    };
};

export default connect(null, mapDispatchToProps)(LStr);
