export const darkScrollbarStyles = {
    '*::-webkit-scrollbar': {
        width: '12px',
        height: '12px',
    },
    '*::-webkit-scrollbar-track': {
        background: '#1e1e1e',
        borderRadius: '6px',
    },
    '*::-webkit-scrollbar-thumb': {
        background: '#555',
        borderRadius: '6px',
        border: '3px solid #1e1e1e',
    },
    '*::-webkit-scrollbar-thumb:hover': {
        background: '#888',
    },
    '*::-webkit-scrollbar-corner': {
        background: '#1e1e1e',
    },
};
export const lightScrollbarStyles = {
    '*::-webkit-scrollbar': {
        width: '12px',
        height: '12px',
    },
    '*::-webkit-scrollbar-track': {
        background: '#f5f5f5',
        borderRadius: '6px',
    },
    '*::-webkit-scrollbar-thumb': {
        background: '#ccc',
        borderRadius: '6px',
        border: '3px solid #f5f5f5',
    },
    '*::-webkit-scrollbar-thumb:hover': {
        background: '#aaa',
    },
    '*::-webkit-scrollbar-corner': {
        background: '#f5f5f5',
    },
};
