import axios from 'axios';

function fetch(endpoint, id = null, path = null) {
    const params = {};
    if (path !== null) params.path = path;
    return axios.get(endpoint);
}

function del(endpoint, hash) {
    return axios.delete(endpoint + hash);
}

export const annotation = {
    fetch,
    del,
};
