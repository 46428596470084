import { all, put, takeEvery } from '@redux-saga/core/effects';

import { actions } from '../../actions';

function* notifBuildFailure(action) {
    const payload = action.payload;
    const response = payload.response;
    yield put(actions.notification.add.failure(`${payload} ${response ? JSON.stringify(response.data) : payload}`));
}

function* notifBuildSuccess() {
    yield put(actions.notification.add.success(`The snapshot has been successfully created`));
}

function* notitCheckFailure(action) {
    yield put(actions.notification.add.failure(`Name Taken: ` + action.payload));
}

function* notitCheckSuccess(action) {
    yield put(actions.notification.add.success(`Name Available: ` + action.payload));
}

function* NotifRerunSuccess(action) {
    const { row } = action.payload;
    const id = row?.id;
    yield put(actions.notification.add.success(`The snapshot ${id} has been successfully rerun`));
}

function* notifRerunFailure(action) {
    const { row, error } = action.payload;
    const id = row?.id;
    yield put(
        actions.notification.add.failure(`Unable to rerun the dataset ${id}:
    ${error?.response?.data?.detail || ''} (Code ${error?.response?.status})`),
    );
}

export default function* watchNotifQBuilder() {
    yield all([
        yield takeEvery(actions.api.qbuilder.build.failure.toString(), notifBuildFailure),
        yield takeEvery(actions.api.qbuilder.build.success.toString(), notifBuildSuccess),
        yield takeEvery(actions.api.qbuilder.check.failure.toString(), notitCheckFailure),
        yield takeEvery(actions.api.qbuilder.check.success.toString(), notitCheckSuccess),
        yield takeEvery(actions.api.qbuilder.rerun.success.toString(), NotifRerunSuccess),
        yield takeEvery(actions.api.qbuilder.rerun.failure.toString(), notifRerunFailure),
    ]);
}
